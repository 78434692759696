import axios from "axios";

export const getBusinessEntities = (searchTerm) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get("https://testapi-accounts.emaq.ba/api/tenantbusinessentities", {
          headers: {
            ClientId: "1CAA63AA-45ED-48AE-8572-F7E9BDDAA3EF",
            "Accept-Language": "en"
          },
          params: {
            SearchTerm: searchTerm,
            Page: searchTerm ? null : 1,
            PageSize: searchTerm ? null : 10
          }
        })
        .then((res) => {
          var response = res as any;
          if (!response.data.resultList) {
            resolve(null);
          } else {
            resolve(response.data.resultList.map(i => {
              return {
                value: i.id,
                label: i.name,
                address: {
                  address: i.address,
                  state: i.state,
                  region: i.region,
                  postalCode: i.postalCode,
                  country: i.country,
                  city: i.city
                }
              };
            }));
          }
        })
        .catch(() => {
          resolve(null);
        });
    } catch (error) { }
  });
};

export const getBusinessEntityTypes = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get("https://testapi-accounts.emaq.ba/api/businessentitytypes", {
          headers: {
            ClientId: "1CAA63AA-45ED-48AE-8572-F7E9BDDAA3EF",
            "Accept-Language": "en",
          },
        })
        .then((res) => {
          var response = res as any;
          resolve(response.data.resultList);
        })
        .catch(() => {
          resolve(null);
        });
    } catch (error) { }
  });
};

const OrganizationService = {
  getBusinessEntities,
  getBusinessEntityTypes,
};

export default OrganizationService;
