import { SMARTY_EMBEDDED_KEY } from "../config/CONSTANTS";
const SmartySDK = require("smartystreets-javascript-sdk");
const SmartyCore = SmartySDK.core;
const SmartyLookup = SmartySDK.usAutocompletePro.Lookup;
const SmartyValidation = SmartySDK.usStreet.Lookup;

const credentials = new SmartyCore.SharedCredentials(SMARTY_EMBEDDED_KEY);

let coreClientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses(["us-core-cloud"]);
let autocompleteClientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses(["us-autocomplete-pro-cloud"]);

let usStreetValidationClient = coreClientBuilder.buildUsStreetApiClient();
let usAutocompleteProClient = autocompleteClientBuilder.buildUsAutocompleteProClient();

export const usAddressLookup = (searchParam) => {
  return new Promise((resolve, reject) => {
    if (searchParam && searchParam.trim()) {
      try {
        let lookup = new SmartyLookup(searchParam);

        usAutocompleteProClient
          .send(lookup)
          .then((res) => {
            resolve(res.result);
          })
          .catch(() => { });
      } catch (error) { }
    }
  });
};

export const usAddressValidation = (street, city, state, zipCode) => {
  return new Promise((resolve, reject) => {
    if (street && street.trim() && city && city.trim() && state && state.trim() && zipCode && zipCode.trim()) {
      try {
        let lookup = new SmartyValidation();
        lookup.street = street;
        lookup.city = city;
        lookup.state = state;
        lookup.zipCode = zipCode;

        usStreetValidationClient
          .send(lookup)
          .then((res) => {
            if(!res || !res.lookups || !res.lookups.length || !res.lookups[0].result) {
              resolve(null);
              return;
            }

            resolve(res.lookups[0].result);
          })
          .catch(() => {
            resolve(null);
          });
      } catch (error) {
        resolve(null);
      }
    }
  });
};

const SmartyService = {
  usAddressLookup,
  usAddressValidation,
};

export default SmartyService;
