import Choices from "choices.js";

export default function initChoices() {
  const choices = document.querySelectorAll("[data-choices]");

  choices.forEach((el) => {
    const elementIcon = el.dataset.choicesIcon || "";
    const elementSize = el.dataset.choicesSize || "lg";
    const elementStyle = el.dataset.choicesStyle || "normal";
    const elementMarkDirty = el.dataset.choicesMarkDirty || false;
    const elementOrientation = el.dataset.choicesOrientation || "left";
    const elementSelectedIcon = el.dataset.choicesSelectedIcon || "";
    const elementPlaceholder = el.dataset.choicesPlaceholder || "";
    const elementOptions = el.dataset.choices ? JSON.parse(el.dataset.choices) : {};

    const defaultOptions = {
      allowHTML: true,
      shouldSort: false,
      removeItemButton: true,
      placeholderValue: elementPlaceholder,
      classNames: {
        containerInner: `choices__inner choices__size-${elementSize} choices__style-${elementStyle} choices__mark-dirty-${elementMarkDirty} choices__orientation-${elementOrientation}`,
      },
      searchFields: ["label", "value", "customProperties"],
      // https://choices-js.github.io/Choices/#choices-single-custom-templates
      callbackOnCreateTemplates(strToEl) {
        const itemSelectText = this.config.itemSelectText;
        return {
          item(option, data) {
            let html = ``;
            let label = String(data.label);

            const { classNames, removeItemButton } = option;

            html += `<div class="${String(classNames.item)} ${String(data.highlighted ? classNames.highlightedState : classNames.itemSelectable)}" data-item data-id="${String(
              data.id
            )}" data-value="${String(data.value)}" ${String(data.active ? 'aria-selected="true"' : "")} ${String(data.disabled ? 'aria-disabled="true"' : "")}>`;

            if (elementIcon) html += `<span class="text-muted ${elementIcon} me-2"></span>`;

            if (elementSelectedIcon) label = `<span class="d-block fs-5 fe fe-${elementSelectedIcon}"></span>`;

            html += `<span class="${String(data.placeholder ? classNames.placeholder : "")} text-center">${label}</span>`;

            if (removeItemButton && this._isSelectMultipleElement) html += `<button type="button" class="choices__button" data-button>Remove item</button>`;

            html += `</div>`;

            return strToEl(html);
          },
          choice({ classNames }, data) {
            let html = ``;

            html += `<div class="${String(classNames.item)} ${String(classNames.itemChoice)} ${String(data.disabled ? classNames.itemDisabled : classNames.itemSelectable)}" data-select-text="${String(
              itemSelectText
            )}" data-choice ${String(data.disabled ? 'data-choice-disabled aria-disabled="true"' : "data-choice-selectable")} data-id="${String(data.id)}" data-value="${String(data.value)}" ${String(
              data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
            )}>`;

            html += `<div class="${String(data.placeholder ? classNames.placeholder : "")}">${String(data.label)}</div>`;

            if (data.customProperties && data.customProperties.description) {
              html += `<sup class="text-muted">${String(data.customProperties.description)}</sup>`;
            }

            return strToEl(html);
          },
        };
      },
    };

    const options = {
      ...elementOptions,
      ...defaultOptions,
    };

    new Choices(el, options);
  });
}

export function initChoice(el) {
  const elementIcon = el.dataset.choicesIcon || "";
  const elementSize = el.dataset.choicesSize || "lg";
  const elementStyle = el.dataset.choicesStyle || "normal";
  const elementSelectedIcon = el.dataset.choicesSelectedIcon || "";
  const elementMarkDirty = el.dataset.choicesMarkDirty || false;
  const elementOrientation = el.dataset.choicesOrientation || "left";
  const elementPlaceholder = el.dataset.choicesPlaceholder || "";
  const elementOptions = el.dataset.choices ? JSON.parse(el.dataset.choices) : {};

  const defaultOptions = {
    allowHTML: true,
    shouldSort: false,
    removeItemButton: true,
    placeholderValue: elementPlaceholder,
    classNames: {
      containerInner: `choices__inner choices__size-${elementSize} choices__style-${elementStyle} choices__mark-dirty-${elementMarkDirty} choices__orientation-${elementOrientation}`,
    },
    searchFields: ["label", "value", "customProperties"],
    // https://choices-js.github.io/Choices/#choices-single-custom-templates
    callbackOnCreateTemplates(strToEl) {
      const itemSelectText = this.config.itemSelectText;
      return {
        item(option, data) {
          let html = ``;
          let label = String(data.label);

          const { classNames, removeItemButton } = option;

          html += `<div class="${String(classNames.item)} ${String(data.highlighted ? classNames.highlightedState : classNames.itemSelectable)}" data-item data-id="${String(
            data.id
          )}" data-value="${String(data.value)}" ${String(data.active ? 'aria-selected="true"' : "")} ${String(data.disabled ? 'aria-disabled="true"' : "")}>`;

          if (elementIcon) html += `<span class="${elementIcon} me-2"></span>`;

          if (elementSelectedIcon) label = `<span class="d-block fs-5 fe fe-${elementSelectedIcon}"></span>`;

          html += `<span class="${String(data.placeholder ? classNames.placeholder : "")}">${label}</span>`;

          if (removeItemButton && this._isSelectMultipleElement) html += `<button type="button" class="choices__button" data-button>Remove item</button>`;

          html += `</div>`;

          return strToEl(html);
        },
        choice({ classNames }, data) {
          let html = ``;

          html += `<div class="${String(classNames.item)} ${String(classNames.itemChoice)} ${String(
            data.disabled ? classNames.itemDisabled : classNames.itemSelectable
          )}" data-select-text="${String(itemSelectText)}" data-choice ${String(data.disabled ? 'data-choice-disabled aria-disabled="true"' : "data-choice-selectable")} data-id="${String(
            data.id
          )}" data-value="${String(data.value)}" ${String(data.groupId > 0 ? 'role="treeitem"' : 'role="option"')}>`;

          html += `<div class="${String(data.placeholder ? classNames.placeholder : "")}">${String(data.label)}</div>`;

          if (data.customProperties && data.customProperties.description) {
            html += `<sup class="text-muted">${String(data.customProperties.description)}</sup>`;
          }

          return strToEl(html);
        },
      };
    },
  };

  const options = {
    ...elementOptions,
    ...defaultOptions,
  };

  return new Choices(el, options);
}

export async function initOrganizationChoices() {
  const el = document.getElementById("organization");

  const elementIcon = "fe fe-cake";
  const elementSize = el.dataset.choicesSize || "lg";
  const elementStyle = el.dataset.choicesStyle || "normal";
  const elementPlaceholder = el.dataset.choicesPlaceholder || "";
  var elementOptions = el.dataset.choices ? JSON.parse(el.dataset.choices) : {};

  const defaultOptions = {
    allowHTML: true,
    shouldSort: false,
    removeItemButton: true,
    renderChoiceLimit: 5,
    placeholderValue: elementPlaceholder,
    classNames: {
      containerInner: `choices__inner choices__size-${elementSize} choices__style-${elementStyle}`,
    },
    searchChoices: false,
    noResultsText: "No results found",
    noChoicesText: "No organization to choose from",
    // https://choices-js.github.io/Choices/#choices-single-custom-templates
    callbackOnCreateTemplates(strToEl) {
      const itemSelectText = this.config.itemSelectText;
      return {
        item(option, data) {
          let html = ``;

          const { classNames, removeItemButton } = option;

          html += `<div class="${String(classNames.item)} ${String(data.highlighted ? classNames.highlightedState : classNames.itemSelectable)}" data-item data-id="${String(
            data.id
          )}" data-value="${String(data.name)}" ${String(data.active ? 'aria-selected="true"' : "")} ${String(data.disabled ? 'aria-disabled="true"' : "")}>`;

          if (elementIcon) html += `<span class="${elementIcon} me-2"></span>`;

          html += `<span class="${String(data.placeholder ? classNames.placeholder : "")}">${String(data.label)}</span>`;

          if (removeItemButton && this._isSelectMultipleElement) html += `<button type="button" class="choices__button" data-button>Remove item</button>`;

          html += `</div>`;

          return strToEl(html);
        },
        choice({ classNames }, data) {
          let html = ``;

          html += `<div class="${String(classNames.item)} ${String(classNames.itemChoice)} ${String(
            data.disabled ? classNames.itemDisabled : classNames.itemSelectable
          )}" data-select-text="${String(itemSelectText)}" data-choice ${String(data.disabled ? 'data-choice-disabled aria-disabled="true"' : "data-choice-selectable")} data-id="${String(
            data.id
          )}" data-value="${String(data.name)}" ${String(data.groupId > 0 ? 'role="treeitem"' : 'role="option"')}>`;

          html += `<div class="${String(data.placeholder ? classNames.placeholder : "")}">${String(data.label)}</div>`;

          if (data.customProperties && data.customProperties.description) {
            html += `<sup class="text-muted">${String(data.customProperties.description)}</sup>`;
          }

          return strToEl(html);
        },
      };
    },
  };

  const options = {
    ...elementOptions,
    ...defaultOptions,
  };

  var choices = new Choices(el, options);

  const response = await fetch("/data/organizations.json");
  const data = await response.json();

  if (data) {
    var initialChoices = data.slice(0, 5).map((d) => ({
      value: d.name,
      label: d.name,
      customProperties: {
        description: `${d.zip}, ${d.city}, ${d.county}, ${d.state}`,
      },
    }));

    choices.setChoices(
      [
        {
          value: "",
          label: "Start typing to find your organization",
          placeholder: true,
          selected: true,
        },
        ...initialChoices,
      ],
      "value",
      "label",
      true
    );
  }

  el.addEventListener("search", async function (e) {
    const searchTerm = e.detail.value ? e.detail.value.toLowerCase() : "";
    const response = await fetch("/data/organizations.json");
    const data = await response.json();

    if (data) {
      var newChoices = data
        .filter(
          (d) =>
            (d.name && d.name.toLowerCase().includes(searchTerm)) ||
            (d.city && d.city.toLowerCase().includes(searchTerm)) ||
            (d.county && d.county.toLowerCase().includes(searchTerm)) ||
            (d.state && d.state.toLowerCase().includes(searchTerm))
        )
        .slice(0, 5)
        .map((d) => ({
          value: d.name,
          label: d.name,
          customProperties: {
            description: `${d.zip}, ${d.city}, ${d.county}, ${d.state}`,
          },
        }));

      choices.setChoices(newChoices, "value", "label", true);
    }
  });
}
