import axios from "axios";
import http from "../common/api";
import { GET_ALL_USERS, GET_DEMO_USER } from "./CONSTANTS";
import IUser from "../types/User";
import jwtDecode from "jwt-decode";
import StoreService from "./StoreService";

export const getUsersFromDummyEndpoint = () => {
  return new Promise((resolve, reject) => {
    try {
      http
        .get<Array<IUser>>("/users?page=2")
        .then((res) => {
          resolve(res.data);
        })
        .catch(() => {});
    } catch (error) {}
  });
};

export const getAllUsers = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get<Array<IUser>>(GET_ALL_USERS())
        .then((res) => {
          resolve(res.data);
        })
        .catch(() => {});
    } catch (error) {}
  });
};

export const getDemoUser = () => {
  return new Promise((resolve) => {
    try {
      axios
        .get(GET_DEMO_USER())
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {});
    } catch (error) {}
  });
};

export const setCurrentUser = (accessToken) => {
  const decodedTokenData = jwtDecode(accessToken) as {};
  const userData = JSON.stringify({
    ...decodedTokenData,
    accessToken,
    isIndividualUser: false,
  });
  localStorage.setItem("loggedUser", userData);
};

export const getCurrentUser = () => {
  var user = localStorage.getItem("loggedUser");
  if (user) {
    return JSON.parse(user);
  }
};

export const getCurrentUserRelationship = () => {
  const user = getCurrentUser();
  if (!user || !user.entityList) return;

  var entities = JSON.parse(user.entityList);
  if (!entities) return;

  var storeId = StoreService.getStoreId();
  const entity = entities.find((e) => e.StoreId === storeId);
  if (!entity) return;

  return entity.Relationship;
};

export const isValidUser = (userIdentifier) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(
          "https://testapi-accounts.emaq.ba/api/users/validuser",
          {
            userIdentifier,
          },
          {
            headers: {
              ClientId: "1CAA63AA-45ED-48AE-8572-F7E9BDDAA3EF",
              "Accept-Language": "bs",
            },
          }
        )
        .then((res) => {
          resolve(true);
        })
        .catch((err) => {
          resolve(err.response.data);
        });
    } catch (error) {}
  });
};

export const signIn = (userIdentifier, password) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(
          "https://testapi-accounts.emaq.ba/api/users/signin",
          {
            userIdentifier,
            password,
          },
          {
            headers: {
              ClientId: "1CAA63AA-45ED-48AE-8572-F7E9BDDAA3EF",
              "Accept-Language": "bs",
            },
          }
        )
        .then((res) => {
          var response = res as any;
          resolve(response.data);
        })
        .catch((err) => {
          resolve(err);
        });
    } catch (error) {}
  });
};

export const signInOtpCode = (userIdentifier, otpCode, emailConfirmCode) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(
          "https://testapi-accounts.emaq.ba/api/users/signinotpcode",
          {
            userIdentifier,
            otpCode,
            emailConfirmCode,
          },
          {
            headers: {
              ClientId: "1CAA63AA-45ED-48AE-8572-F7E9BDDAA3EF",
              "Accept-Language": "bs",
            },
          }
        )
        .then((res) => {
          var response = res as any;
          resolve(response.data);
        })
        .catch((err) => {
          resolve(err);
        });
    } catch (error) {}
  });
};

export const sendOtpCode = (userIdentifier, emailConfirmCode = null) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(
          "https://testapi-accounts.emaq.ba/api/users/sendotpcode",
          {
            userIdentifier,
            otpCodeLength: 4,
            emailConfirmCode,
          },
          {
            headers: {
              ClientId: "1CAA63AA-45ED-48AE-8572-F7E9BDDAA3EF",
              "Accept-Language": "bs",
            },
          }
        )
        .then((res) => {
          var response = res as any;
          resolve(response.data);
        })
        .catch((err) => {
          resolve(err.response.data);
        });
    } catch (error) {}
  });
};

export const check = (idMeCode) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(
          "https://testapi-accounts.emaq.ba/api/users/check",
          {
            idMeCode,
          },
          {
            headers: {
              ClientId: "1CAA63AA-45ED-48AE-8572-F7E9BDDAA3EF",
              "Accept-Language": "bs",
            },
          }
        )
        .then((res) => {
          var response = res as any;
          resolve(response.data);
        })
        .catch((err) => {
          resolve(err.response.data);
        });
    } catch (error) {}
  });
};

export const signUp = (signUpData) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(
          "https://testapi-accounts.emaq.ba/api/users/signup",
          { ...signUpData },
          {
            headers: {
              ClientId: "1CAA63AA-45ED-48AE-8572-F7E9BDDAA3EF",
              "Accept-Language": "bs",
              //"Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          var response = res as any;
          resolve(response.data);
        })
        .catch((err) => {
          resolve(err.response.data);
        });
    } catch (error) {}
  });
};

export const sendForgotPasswordInstructions = (userIdentifier) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(
          "https://testapi-accounts.emaq.ba/api/userrequests/useridentifier",
          { userIdentifier, sendAccessViaEmail: true, sendAccessViaSms: true },
          {
            headers: {
              ClientId: "1CAA63AA-45ED-48AE-8572-F7E9BDDAA3EF",
              "Accept-Language": "bs",
            },
          }
        )
        .then((res) => {
          var response = res as any;
          resolve(response.data);
        })
        .catch((err) => {
          resolve(err.response.data);
        });
    } catch (error) {}
  });
};

export const checkForgotPasswordResetCode = (userIdentifier, resetCode) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(
          "https://testapi-accounts.emaq.ba/api/userrequests/code",
          { userIdentifier, resetCode },
          {
            headers: {
              ClientId: "1CAA63AA-45ED-48AE-8572-F7E9BDDAA3EF",
              "Accept-Language": "bs",
            },
          }
        )
        .then((res) => {
          var response = res as any;
          resolve(response.data);
        })
        .catch((err) => {
          resolve(false);
        });
    } catch (error) {}
  });
};

export const updatePassword = (userIdentifier, resetCode, password, passwordConfirmation) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(
          "https://testapi-accounts.emaq.ba/api/userrequests/password",
          { userIdentifier, resetCode, password, passwordConfirmation },
          {
            headers: {
              ClientId: "1CAA63AA-45ED-48AE-8572-F7E9BDDAA3EF",
              "Accept-Language": "bs",
            },
          }
        )
        .then((res) => {
          var response = res as any;
          resolve(response.data);
        })
        .catch((err) => {
          resolve(false);
        });
    } catch (error) {}
  });
};

export const getCurrentUserInterests = () => {
  const currentUser = getCurrentUser();
  return new Promise((resolve, reject) => {
    try {
      axios
        .get("https://testapi-commerce.emaq.ba/api/UserInterests", { params: { UserId: currentUser.userId, RetrieveAll: true } })
        .then((res) => {
          var response = res as any;
          resolve(response.data.resultList);
        })
        .catch((err) => {
          resolve(false);
        });
    } catch (error) {}
  });
};

export const addUserInterests = (calibers) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post("https://testapi-commerce.emaq.ba/api/UserInterests/bulk", calibers)
        .then((res) => {
          var response = res as any;
          resolve(response.data);
        })
        .catch((err) => {
          resolve(false);
        });
    } catch (error) {}
  });
};

export const removeUserInterests = (ids) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .delete(`https://testapi-commerce.emaq.ba/api/UserInterests/bulk/${ids}`)
        .then((res) => {
          var response = res as any;
          resolve(response.data);
        })
        .catch((err) => {
          resolve(false);
        });
    } catch (error) {}
  });
};

const UserService = {
  getUsersFromDummyEndpoint,
  getAllUsers,
  getDemoUser,
  setCurrentUser,
  getCurrentUser,
  isValidUser,
  signIn,
  signInOtpCode,
  sendOtpCode,
  check,
  signUp,
  sendForgotPasswordInstructions,
  checkForgotPasswordResetCode,
  updatePassword,
  getCurrentUserRelationship,
  getCurrentUserInterests,
  addUserInterests,
  removeUserInterests,
};

export default UserService;
