import lottie from "lottie-web";

export default function initLottie() {
  const el = document.getElementById("lottie");
  const animation = document.getElementById("lottie-animation");
  const spinner = document.getElementById("lottie-spinner");

  if (el) {
    const anim = lottie.loadAnimation({
      container: animation,
      renderer: "svg",
      loop: false,
      autoplay: false,
      path: animation.dataset.file,
    });

    anim.addEventListener("data_ready", function () {
      if (!animation.classList.contains("show")) {
        animation.classList.add("show");
        spinner.classList.remove("show");

        setTimeout(() => anim.play(), 150);
      }
    });
  }
}
