import React from "react";
import { observer } from "mobx-react";
import Highlighter from "react-highlight-words";
import { useStore } from "../../../stores/StoreContext";

export const DocumentItemComponent = observer((props) => {
  const { searchStore } = useStore();

  return (
    <>
      <div className="col-12">
        <div
          id={`card-${props.index}`}
          data-index={props.index}
          className={`card card-xs card-md-sm card-lift card-lift-expand mb-0 h-100 rounded-1 shadow-none ${props.selected ? "selected" : ""
            } ${!props.document.isSeen ? "not-seen" : ""}`}
        >
          <a
            id={`show-details-btn-${props.index}`}
            className="card-body"
            href="#!"
            onClick={() => props.onSelectedItem(props.document.documentNumber)}
            data-toggle="flyout"
            title={props.document.documentNumber}
          >
            <span className="row g-2 align-items-stretch">
              <span className="col-auto">
                <span className="fs-7">{props.document.date}</span>
              </span>
              <span className="col-auto d-none d-md-block">
                <span
                  className="vr h-100 bg-primary"
                  style={{ "--bs-bg-opacity": "0.25" }}
                ></span>
              </span>
              <span className="col-6 col-md-auto order-1 order-md-0 mt-0 mt-md-auto">
                <span
                  className="badge text-uppercase text-primary bg-primary rounded-pill"
                  style={{ "--bs-bg-opacity": "0.1" }}
                >
                  <Highlighter
                    searchWords={[searchStore.searchTerm]}
                    autoEscape={true}
                    textToHighlight={props.document.type}
                  />
                </span>
              </span>
              <span className="col-auto">
                <span
                  className="vr h-100 bg-primary"
                  style={{ "--bs-bg-opacity": "0.25" }}
                ></span>
              </span>
              <span className="col-auto">
                <span className="fs-7">
                  <Highlighter
                    searchWords={[searchStore.searchTerm]}
                    autoEscape={true}
                    textToHighlight={props.document.documentNumber}
                  />
                </span>
              </span>
              <span className="col-12 d-md-none"></span>
              <span
                className={`col-6 col-md-auto order-2 order-md-4 mt-0 mt-md-auto ms-md-auto text-end ${!props.document.isSigned ? "d-none" : ""
                  }`}
              >
                <span className="fe fe-check-circle text-success"></span>
              </span>
            </span>
          </a>
        </div>
      </div>
    </>
  );
});

export default DocumentItemComponent;
