import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import UserService from "../../../services/UserService";
import { useSearchParams } from "react-router-dom";
import { IndexOutOfBoundsError } from "pdf-lib";

const SignInOTPCode = (props) => {
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });
  const [searchParams, setSearchParams] = useSearchParams();
  const [otpCodeErrors, setOtpCodeErrors] = useState();
  useEffect(() => {
    setFocus("pin1");
  }, [setFocus]);
  const [resendAvailableIn, setResendAvailableIn] = useState();
  const [intervalId, setIntervalId] = useState();
  const [resentCounter, setResentCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const sendOtpCode = async () => {
      const emailConfirmCode = searchParams.get("emailConfirmCode");
      setIsLoading(true);
      const data = await UserService.sendOtpCode(props.email, emailConfirmCode);
      setIsLoading(false);
      if (data.resendAvailableIn) {
        setResendAvailableIn(data.resendAvailableIn);
        setResendAvailableInTimer();
        setOtpCodeErrors([]);
      } else {
        setOtpCodeErrors(data.resultList);
      }
    };
    sendOtpCode();
    return () => clearInterval(intervalId);
  }, [resentCounter]);

  useEffect(() => {
    if (resendAvailableIn) {
      if (resendAvailableIn <= 1) {
        localStorage.removeItem("otpResendAvailableIn", resendAvailableIn);
        setResendAvailableIn(null);
      } else {
        localStorage.setItem("otpResendAvailableIn", resendAvailableIn);
      }
    }
  }, [resendAvailableIn]);

  const onKeyUp = (e) => {
    props.clearCustomErrors();
    setOtpCodeErrors([]);

    const prevPinFieldName = e.target.dataset.prevPinField;
    const nextPinFieldName = e.target.dataset.nextPinField;

    if ((e.key === "Delete" || e.key === "Backspace") && prevPinFieldName) {
      setFocus(prevPinFieldName);
      return;
    }

    if (!isFinite(e.key)) {
      e.target.value = "";
      return;
    }

    if (nextPinFieldName) {
      setFocus(nextPinFieldName);
      return;
    }

    if (isValid) {

      const submitButton = document.querySelector("button[type='submit']");
      if (submitButton) {
        submitButton.click();
      }
    }
  };

  const resendOtpCode = async () => {
    setResentCounter((prevValue) => prevValue + 1);
  };

  const setResendAvailableInTimer = () => {
    clearInterval(intervalId);
    var intervalId = setInterval(() => {
      setResendAvailableIn((prevValue) => prevValue - 1);
      if (resendAvailableIn <= 1) {
        clearInterval(intervalId);
      }
    }, 1000);
    setIntervalId(intervalId);
  };

  const onFocus = (e) => e.target.select();

  return (
    <React.Fragment>
      <form id="sign-in-otp-form" onSubmit={handleSubmit(props.onSubmitStep2)}>
        <p className="mb-4 text-muted">Please enter code that we sent to your phone number. Code is valid for two minutes.</p>
        <div className="my-auto">
          <div className={`${errors.pin1 || errors.pin2 || errors.pin3 || errors.pin4 || (props.errors && props.errors.invalidPin) ? "" : "mb-3"}`}>
            <ul className="list-inline text-center">
              <li className="list-inline-item">
                <input
                  className={`form-control form-control-lg pin ${errors.pin1 ? "border-danger" : ""}`}
                  onFocus={onFocus}
                  type="text"
                  {...register("pin1", { required: true })}
                  onKeyUp={onKeyUp}
                  data-next-pin-field="pin2"
                  maxLength={1}
                />
              </li>
              <li className="list-inline-item">
                <input
                  className={`form-control form-control-lg pin ${errors.pin2 ? "border-danger" : ""}`}
                  onFocus={onFocus}
                  type="text"
                  {...register("pin2", { required: true })}
                  onKeyUp={onKeyUp}
                  data-prev-pin-field="pin1"
                  data-next-pin-field="pin3"
                  maxLength={1}
                />
              </li>
              <li className="list-inline-item">
                <input
                  className={`form-control form-control-lg pin ${errors.pin3 ? "border-danger" : ""}`}
                  onFocus={onFocus}
                  type="text"
                  {...register("pin3", { required: true })}
                  onKeyUp={onKeyUp}
                  data-prev-pin-field="pin2"
                  data-next-pin-field="pin4"
                  maxLength={1}
                />
              </li>
              <li className="list-inline-item">
                <input
                  className={`form-control form-control-lg pin ${errors.pin4 ? "border-danger" : ""}`}
                  onFocus={onFocus}
                  type="text"
                  {...register("pin4", { required: true })}
                  onKeyUp={onKeyUp}
                  data-prev-pin-field="pin3"
                  maxLength={1}
                />
              </li>
            </ul>
          </div>
          <div className="mb-0 text-center mb-3">
            {(errors.pin1 || errors.pin2 || errors.pin3 || errors.pin4) && <small className="text-danger d-block">Pin is required.</small>}
            {props.errors && props.errors.invalidPin && <small className="text-danger d-block">Invalid pin.</small>}
            {otpCodeErrors &&
              otpCodeErrors.map((e) => (
                <small key={e.key} className="text-danger d-block">
                  {e.description}
                </small>
              ))}
          </div>
          <div className="mb-0 text-center">
            <p className="mb-0 fs-7 text-muted">
              {isLoading && <>Sending OTP...</>}
              {!isLoading && (
                <>
                  Didn't receive the OTP? {resendAvailableIn && <span>Resend code in {resendAvailableIn} seconds...</span>}
                  {!resendAvailableIn && (
                    <a href="#!" onClick={resendOtpCode}>
                      Resend code
                    </a>
                  )}
                </>
              )}
            </p>
          </div>
        </div>
        <div className="mt-4">
          <div className="mb-3">
            <button className="btn btn-lg btn-primary w-100" type="submit" disabled={props.isLoading}>
              {props.isLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
              &nbsp; Confirm
            </button>
          </div>
          <div className="mb-3 text-center">
            <p className="or mb-0 text-muted">OR</p>
          </div>
          <div className="mb-0">
            <button className="btn btn-lg btn-white w-100" onClick={() => props.onLoginTypeChange("password")} type="button">
              Back to Sign In
            </button>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default SignInOTPCode;
