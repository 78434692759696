import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { handleValidatePhoneNumber, handleValidateEmail } from "../utils/regex";
import debounce from "debounce";
import SmartyService from "../services/SmartyService";
import OrdersService from "../services/OrdersService";
import UserService from "../services/UserService";

export default function BillingShippingInfoForm(props) {
  const [isShippingInfoSame, setIsShippingInfoSame] = useState(true);
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [isCurrentUserIndividual, setIsCurrentUserIndividual] = useState();

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors: errors, isValid: isValid },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    const relationship = UserService.getCurrentUserRelationship();
    const isCurrentUserIndividual = relationship === "Self";
    setIsCurrentUserIndividual(isCurrentUserIndividual);

    const fetchAndSetLatestBillingAndShippingInfo = async () => {
      var data = await OrdersService.getLatestBillAndShipOrderDetails();
      const currentUser = await UserService.getCurrentUser();

      if (data && !data.lastBillingShippingInfo) {
        const firstName = currentUser.username.split(" ").length > 0 && currentUser.username.split(" ")[0];
        const lastName = currentUser.username.split(" ").length > 1 && currentUser.username.split(" ")[1];

        data.lastBillingShippingInfo = {
          billFirstName: firstName,
          billLastName: lastName,
          shipFirstName: firstName,
          shipLastName: lastName,
          billEmailAddress: currentUser.email,
          shipEmailAddress: currentUser.email,
          billPhoneNumber: currentUser.phone,
          shipPhoneNumber: currentUser.phone,
        };
      }

      if (data && data.lastBillingShippingInfo) {
        setValue("billingFirstName", data.lastBillingShippingInfo.billFirstName);
        setValue("billingLastName", data.lastBillingShippingInfo.billLastName);
        if (!isCurrentUserIndividual) {
          setValue("billingCompanyName", data.lastBillingShippingInfo.billCompanyName);
        }
        setValue("billingEmail", data.lastBillingShippingInfo.billEmailAddress);
        setValue("billingPhoneNumber", data.lastBillingShippingInfo.billPhoneNumber);
        setValue("billingAddress", data.lastBillingShippingInfo.billAddress);
        setValue("billingCity", data.lastBillingShippingInfo.billCityName);
        setValue("billingZip", data.lastBillingShippingInfo.billPostalCode);
        setValue("billingState", data.lastBillingShippingInfo.billRegionName, { shouldValidate: true });

        setValue("shippingFirstName", data.lastBillingShippingInfo.shipFirstName);
        setValue("shippingLastName", data.lastBillingShippingInfo.shipLastName);
        if (!isCurrentUserIndividual) {
          setValue("shippingCompanyName", data.lastBillingShippingInfo.shipCompanyName);
        }
        setValue("shippingEmail", data.lastBillingShippingInfo.shipEmailAddress);
        setValue("shippingPhoneNumber", data.lastBillingShippingInfo.shipPhoneNumber);
        setValue("shippingAddress", data.lastBillingShippingInfo.shipAddress);
        setValue("shippingCity", data.lastBillingShippingInfo.shipCityName);
        setValue("shippingZip", data.lastBillingShippingInfo.shipPostalCode);
        setValue("shippingState", data.lastBillingShippingInfo.shipRegionName, { shouldValidate: true });
      }
    };
    fetchAndSetLatestBillingAndShippingInfo();
  }, []);

  useEffect(() => {
    var isFormValid =
      isValid ||
      (isShippingInfoSame && errors && Object.entries(errors).every(([key, value]) => key.startsWith("shipping"))) ||
      (!isShippingInfoSame && errors && Object.entries(errors).every(([key, value]) => !key.startsWith("shipping")));

    if (props.onValidChange) {
      props.onValidChange(isFormValid);
    }
  }, [isValid, isShippingInfoSame]);

  const handleCheckbox = () => {
    setIsShippingInfoSame(!isShippingInfoSame);
  };

  const onAddressChange = async (e, prefix) => {
    setSelectedAddress({ streetLine: "", city: "", state: "", zipcode: "" }, false, prefix);
    if (!e.target.value || e.target.value === "") {
      setAddressSuggestions([]);
      hideAddressAutocomplete(prefix);
      return;
    }
    const suggestions = await SmartyService.usAddressLookup(e.target.value);

    var suggestionsIndex = addressSuggestions.findIndex((as) => as.id === prefix);
    if (suggestionsIndex !== -1) {
      addressSuggestions[suggestionsIndex].suggestions = suggestions;
    } else {
      addressSuggestions.push({ id: prefix, suggestions: suggestions });
    }
    setAddressSuggestions(() => [...addressSuggestions]);
    if (suggestions.length > 0) {
      showAddressAutocomplete(prefix);
    } else {
      hideAddressAutocomplete(prefix);
    }
  };

  const getSuggestionsByPrefix = (prefix) => {
    const suggestionsIndex = addressSuggestions.findIndex((as) => as.id === prefix);
    return suggestionsIndex !== -1 ? addressSuggestions[suggestionsIndex].suggestions : [];
  };

  const setSelectedAddress = (item, updateAddressField = true, prefix) => {
    if (updateAddressField) {
      setValue(`${prefix}Address`, item.streetLine, { shouldValidate: true });
    }
    setValue(`${prefix}City`, item.city, { shouldValidate: true });
    setValue(`${prefix}State`, item.state, { shouldValidate: true });
    setValue(`${prefix}Zip`, item.zipcode, { shouldValidate: true });
    hideAddressAutocomplete(prefix);
  };

  const hideAddressAutocomplete = (prefix) => {
    const addressLookupDiv = document.getElementById(`${prefix}-address-lookup`);
    if (addressLookupDiv && addressLookupDiv.classList.contains("show")) {
      addressLookupDiv.classList.remove("show");
    }
  };

  const showAddressAutocomplete = (prefix) => {
    const addressLookupDiv = document.getElementById(`${prefix}-address-lookup`);
    if (addressLookupDiv && !addressLookupDiv.classList.contains("show")) {
      addressLookupDiv.classList.add("show");
      const cardBody = addressLookupDiv.querySelector(".card-body");
      if (cardBody) {
        cardBody.scrollTop = 0;
      }
    }
  };

  function getSubform(prefix) {
    return (
      <div className="row g-2 m-0" onMouseUp={() => hideAddressAutocomplete(prefix)}>
        <div className="col-12 col-md-6 mt-1">
          <div className="input-group input-group-merge input-group-reverse">
            <input
              id={`${prefix}-first-name`}
              placeholder="First Name"
              type="text"
              aria-label="First Name"
              aria-describedby={`${prefix}-first-name-icon`}
              className={`form-control ${errors[`${prefix}FirstName`] ? "border-danger" : ""}`}
              {...register(`${prefix}FirstName`, {
                required: "First Name is required.",
                validate: (value) => value.trim() === '' ? "First Name is required." : true
              })}
            />
            <div id={`${prefix}-first-name-icon`} className={`input-group-text ${errors[`${prefix}FirstName`] ? "border-danger text-danger" : ""}`}>
              <i className="fe fe-user"></i>
            </div>
          </div>
          {errors[`${prefix}FirstName`] && <small className="text-danger">{errors[`${prefix}FirstName`].message}</small>}
        </div>
        <div className="col-12 col-md-6 mt-1">
          <div className="input-group input-group-merge input-group-reverse">
            <input
              id={`${prefix}-last-name`}
              placeholder="Last Name"
              type="text"
              aria-label="Last Name"
              aria-describedby={`${prefix}-last-name-icon`}
              className={`form-control ${errors[`${prefix}LastName`] ? "border-danger" : ""}`}
              {...register(`${prefix}LastName`, {
                required: "Last Name is required.",
                validate: (value) => value.trim() === '' ? "Last Name is required." : true
              })}
            />
            <div id={`${prefix}-last-name-icon`} className={`input-group-text ${errors[`${prefix}LastName`] ? "border-danger text-danger" : ""}`}>
              <i className="fe fe-user"></i>
            </div>
          </div>
          {errors[`${prefix}LastName`] && <small className="text-danger">{errors[`${prefix}LastName`].message}</small>}
        </div>
        {!isCurrentUserIndividual && <div className="col-12">
          <div className="input-group input-group-merge input-group-reverse">
            <input
              id={`${prefix}-company-name`}
              placeholder="Company Name"
              type="text"
              aria-label="Company Name"
              aria-describedby={`${prefix}-company-name-icon`}
              className={`form-control ${errors[`${prefix}CompanyName`] ? "border-danger" : ""}`}
              {...register(`${prefix}CompanyName`, {
                required: "Company Name is required.",
                validate: (value) => value.trim() === '' ? "Company Name is required." : true
              })}
            />
            <div id={`${prefix}-company-name-icon`} className={`input-group-text ${errors[`${prefix}CompanyName`] ? "border-danger text-danger" : ""}`}>
              <i className="fe fe-user"></i>
            </div>
          </div>
          {errors[`${prefix}CompanyName`] && <small className="text-danger">{errors[`${prefix}CompanyName`].message}</small>}
        </div>}
        <div className="col-12 col-md-6">
          <div className="input-group input-group-merge input-group-reverse">
            <input
              id={`${prefix}-email`}
              type="email"
              aria-label="Email Address"
              placeholder="Email Address"
              aria-describedby={`${prefix}-email-icon`}
              className={`form-control ${errors[`${prefix}Email`] ? "border-danger" : ""}`}
              {...register(`${prefix}Email`, {
                required: "Email Address is required.",
                validate: (value) => (handleValidateEmail(value && value.trim()) ? true : "Email Address is not in valid format."),
              })}
            />
            <div id={`${prefix}-email-icon`} className={`input-group-text ${errors[`${prefix}Email`] ? "border-danger text-danger" : ""}`}>
              <i className="fe fe-at-sign"></i>
            </div>
          </div>
          {errors[`${prefix}Email`] && <small className="text-danger mb-3">{errors[`${prefix}Email`].message}</small>}
        </div>
        <div className="col-12 col-md-6">
          <div className="input-group input-group-merge input-group-reverse">
            <input
              id={`${prefix}-phone-number`}
              type="phone"
              placeholder="Phone Number"
              aria-label="Phone Number"
              aria-describedby={`${prefix}-phone-number-icon`}
              className={`form-control ${errors[`${prefix}PhoneNumber`] ? "border-danger" : ""}`}
              {...register(`${prefix}PhoneNumber`, {
                required: "Phone Number is required.",
                validate: (value) => (handleValidatePhoneNumber(value && value.trim()) ? true : "Phone Number is not in valid format."),
              })}
            />
            <div id={`${prefix}-phone-number-icon`} className={`input-group-text ${errors[`${prefix}PhoneNumber`] ? "border-danger text-danger" : ""}`}>
              <i className="fe fe-phone"></i>
            </div>
          </div>
          {errors[`${prefix}PhoneNumber`] && <small className="text-danger mb-3">{errors[`${prefix}PhoneNumber`].message}</small>}
        </div>
        <div className="col-12">
          <div className="input-group input-group-merge input-group-reverse">
            <input
              id={`${prefix}-address`}
              type="text"
              placeholder="Address"
              aria-label="Address"
              aria-describedby={`${prefix}-address-icon`}
              className={`form-control ${errors[`${prefix}Address`] ? "border-danger" : ""}`}
              {...register(`${prefix}Address`, {
                required: "Address is required."
              })}
              onChange={debounce(async (e) => {
                onAddressChange(e, prefix);
              }, 400)}
              onClick={() => {
                const suggestionsIndex = addressSuggestions.findIndex((as) => as.id === prefix);
                if (suggestionsIndex !== -1 && addressSuggestions[suggestionsIndex].suggestions.length > 0) {
                  showAddressAutocomplete(prefix);
                }
              }}
            />
            <div id={`${prefix}-address-icon`} className={`input-group-text ${errors[`${prefix}Address`] ? "border-danger text-danger" : ""}`}>
              <i className="fe fe-search"></i>
            </div>
          </div>
          <div
            id={`${prefix}-address-lookup`}
            className={`dropdown-menu p-0`}
            style={{
              minWidth: "96%",
              maxHeight: "170px",
              overflowX: "hidden",
              overflowY: "scroll",
              left: "0.75rem",
              right: "0.75rem"
            }}>
            <div className="card-body p-0">
              <div className="list-group list-group-flush">
                {getSuggestionsByPrefix(prefix) &&
                  getSuggestionsByPrefix(prefix).map((item, i) => (
                    <a key={i} className="list-group-item p-2" href="#!" onClick={() => setSelectedAddress(item, true, prefix)}>
                      {item.streetLine} {item.city}, {item.state} {item.zipcode}
                    </a>
                  ))}
              </div>
            </div>
          </div>
          {errors[`${prefix}Address`] && <small className="text-danger mb-3">{errors[`${prefix}Address`].message}</small>}
        </div>
        <div className="col-12 col-md-4 mb-1">
          <div className="input-group input-group-merge input-group-reverse">
            <input
              id={`${prefix}-city`}
              type="text"
              placeholder="City"
              aria-label="City"
              aria-describedby={`${prefix}-city-icon`}
              className="form-control"
              {...register(`${prefix}City`, {
                required: "City is required."
              })}
            />
            <div id={`${prefix}-city-icon`} className="input-group-text">
              <i className="fe fe-map-pin"></i>
            </div>
          </div>
          {errors[`${prefix}City`] && <small className="text-danger mb-3">{errors[`${prefix}City`].message}</small>}
        </div>
        <div className="col-12 col-md-4 mb-1">
          <div className="input-group input-group-merge input-group-reverse">
            <input
              id={`${prefix}-zip`}
              type="text"
              placeholder="Zip"
              aria-label="Zip"
              aria-describedby={`${prefix}-zip-icon`}
              className="form-control"
              {...register(`${prefix}Zip`, {
                required: "Zip is required."
              })}
            />
            <div id={`${prefix}-zip-icon`} className="input-group-text">
              <i className="fe fe-map-pin"></i>
            </div>
          </div>
          {errors[`${prefix}Zip`] && <small className="text-danger mb-3">{errors[`${prefix}Zip`].message}</small>}
        </div>
        <div className="col-12 col-md-4 mb-1">
          <div className="input-group input-group-merge input-group-reverse">
            <input
              id={`${prefix}-state`}
              type="text"
              placeholder="State"
              aria-label="State"
              aria-describedby={`${prefix}-state-icon`}
              className="form-control"
              {...register(`${prefix}State`, {
                required: "State is required."
              })}
            />
            <div id={`${prefix}-state-icon`} className="input-group-text">
              <i className="fe fe-map-pin"></i>
            </div>
          </div>
          {errors[`${prefix}State`] && <small className="text-danger mb-3">{errors[`${prefix}State`].message}</small>}
        </div>
      </div>
    );
  }

  async function validateAddresses(data) {
    const validate = async (prefix) => {
      let response = await SmartyService.usAddressValidation(data[`${prefix}Address`], data[`${prefix}City`], data[`${prefix}State`], data[`${prefix}Zip`]);
      if (!response || response.length == 0) {
        setError(`${prefix}Address`, { type: 'custom', message: 'Address is not valid' });
        return false;
      }

      data[`${prefix}Address`] = response[0].delivery_line_1 ?? response[0].deliveryLine1;
      setValue(`${prefix}Address`, data[`${prefix}Address`]);

      data[`${prefix}State`] = response[0].components.state_abbreviation ?? response[0].components.state;
      setValue(`${prefix}State`, data[`${prefix}State`]);

      data[`${prefix}City`] = response[0].components.city_name ?? response[0].components.cityName;
      setValue(`${prefix}City`, data[`${prefix}City`]);

      data[`${prefix}Zip`] = response[0].components.zipcode ?? response[0].components.zipCode;
      setValue(`${prefix}Zip`, data[`${prefix}Zip`]);

      clearErrors(`${prefix}Address`);
      return true;
    };

    const billingAddressValid = await validate('billing');
    if (!billingAddressValid) {
      return false;
    }

    if (!isShippingInfoSame) {
      const shippingAddressValid = await validate('shipping');
      if (!shippingAddressValid) {
        return false;
      }
    }

    return true;
  }

  const onSubmit = async (data) => {
    if (isShippingInfoSame) {
      data.shippingFirstName = data.billingFirstName;
      data.shippingLastName = data.billingLastName;
      data.shippingCompanyName = data.billingCompanyName;
      data.shippingPhoneNumber = data.billingPhoneNumber;
      data.shippingEmail = data.billingEmail;
      data.shippingState = data.billingState;
      data.shippingCity = data.billingCity;
      data.shippingZip = data.billingZip;
      data.shippingAddress = data.billingAddress;
    }

    const addressesValid = await validateAddresses(data);
    if (!addressesValid)
      return false;

    return props.onSubmit(data);
  }

  return (
    <form ref={props.formRef} onSubmit={handleSubmit(onSubmit)}>
      <div className="card rounded-1 mb-0">
        <div className="card-header px-2 py-0">
          <h6 className="card-header-title">Billing info</h6>
        </div>
        <div className="card-body p-1">{getSubform("billing")}</div>
      </div>

      <div className={`form-check ${isShippingInfoSame ? "mt-3" : "my-3"}`}>
        <input className="form-check-input rounded" id="same-address" type="checkbox" onClick={handleCheckbox} checked={isShippingInfoSame} {...register("IsShippingInfoSame")} />
        <label className="form-check-label" htmlFor="same-address">
          My billing and shipping address are the same
        </label>
      </div>

      {!isShippingInfoSame && (
        <div className="card rounded-1 mb-0">
          <div className="card-header px-2 py-0">
            <h6 className="card-header-title">Shipping info</h6>
          </div>
          <div className="card-body p-1">{getSubform("shipping")}</div>
        </div>
      )}
    </form>
  );
}
