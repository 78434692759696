const PHONE_REGEX = new RegExp(
  // eslint-disable-next-line
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
);

const EMAIL_REGEX = new RegExp(
  // eslint-disable-next-line
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const CARD_EXPIRY_DATE_REGEX = new RegExp(
  // eslint-disable-next-line
  /^(?:0?[1-9]|1[0-2]) *\/ *[1-9][0-9]$/
);

const handleValidatePhoneNumber = (phoneNumber) => {
  return PHONE_REGEX.test(phoneNumber);
};

const handleValidateEmail = (email) => {
  return String(email).toLowerCase().match(EMAIL_REGEX);
};

const handleValidateCardExpiryDate = (cardExpiryDate) => {
  return String(cardExpiryDate).toLowerCase().match(CARD_EXPIRY_DATE_REGEX);
};

export { handleValidatePhoneNumber, handleValidateEmail, handleValidateCardExpiryDate };
