export default function initLoader() {
  const loader = document.getElementById("loader");

  if (loader) {
    const duration = 200;
    setTimeout(() => {
      loader.classList.add("d-none");
    }, duration);
  }
}
