import React, { useRef } from "react";
import Modal from "../pages/_Layout/Modal/Modal";
import ModalBody from "../pages/_Layout/Modal/components/ModalBody";
import ModalHeader from "../pages/_Layout/Modal/components/ModalHeader";
import ModalFooter from "../pages/_Layout/Modal/components/ModalFooter";
import { useForm } from "react-hook-form";
import OrdersService from "../services/OrdersService";
import { toggleCart } from "../utils/cart";
import { useStore } from "../stores/StoreContext";
import { formatPrice } from "../utils/formatters";
import { usePaymentInputs } from "react-payment-inputs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useState, useEffect } from "react";
import images from "react-payment-inputs/images";
import { handleValidateCardExpiryDate } from "../utils/regex";
import { convertToBase64 } from "../utils/base64";
import HelloSign from "hellosign-embedded";
import { HELLO_SIGN_CLIENT_ID } from "../config/CONSTANTS";
import Dropzone from "react-dropzone";
import BillingShippingInfoForm from "../forms/BillingShippingInfoForm";
import { toast } from "react-toastify";
import PasswordInput from "../components/PasswordInput";

export default function PurchaseOrderModal(props) {
  const { ordersStore, cartStore } = useStore();
  const [purchaseOrderStep, setPurchaseOrderStep] = useState(1);
  const [isAgreedToSignExemptionCertificate, setIsAgreedToSignExemptionCertificate] = useState();
  const [isLoadingPurchaseOrder, setIsLoadingPurchaseOrder] = useState(false);
  const [activePurchaseOrder, setActivePurchaseOrder] = useState();
  const [isBillingShippingInfoValid, setIsBillingShippingInfoValid] = useState(false);

  const [billingShippingInfo, setBillingShippingInfo] = useState();

  const billingShippingInfoFormRef = useRef();
  const [files, setFiles] = useState([]);

  const helloSignClient = new HelloSign({
    clientId: HELLO_SIGN_CLIENT_ID,
    skipDomainVerification: true,
  })
    .on("sign", async (data) => {
      if (!activePurchaseOrder) return;

      setIsLoadingPurchaseOrder(true);
      await OrdersService.signDocuments(activePurchaseOrder.id);
      setIsLoadingPurchaseOrder(false);

      props.close();
      //toggleCart();
      //cartStore.clearCart();
      setActivePurchaseOrder(null);

      toast.success("Purchase order is successfully created!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      ordersStore.notify();
    });

  const [paymentType, setPaymentType] = useState(3);

  const { register: registerPurchaseOrder, getValues: getValuesPurchaseOrder, setError: setPurchaseOrderError, watch: watchPurchaseOrder, reset: resetPurchaseOrder } = useForm({ mode: "all" });

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    setError,
    formState: { errors: errors, isValid: isValid },
  } = useForm({ mode: "all" });
  const watchIsPurchaseOrderRequired = watchPurchaseOrder("isPurchaseOrderRequired");
  const watchPaymentTerm = watchPurchaseOrder("paymentTerm");

  const {
    getCardImageProps: getCardImageProps,
    getCardNumberProps: getCardNumberProps,
    getExpiryDateProps: getExpiryDateProps,
    getCVCProps: getCVCProps,
  } = usePaymentInputs();

  useEffect(() => {
    if (paymentType === 3) {
      register("cardNumber", {
        required: "Card number is required.",
        validate: (value) => {
          if (value) {
            const cleanedValue = value.replace(/\s/g, "").trim();
            if (cleanedValue.length < 13) {
              return "Min. length of card number is 13";
            }
            if (cleanedValue.length > 16) {
              return "Max. length of card number is 16";
            }
          }
        },
      });
      register("expiryDate", {
        required: "Expiry date is required.",
        validate: (value) => {
          if (value) {
            if (handleValidateCardExpiryDate(value)) {
              const month = +value.split("/")[0];
              const year = +value.split("/")[1];
              const currentYear = +new Date().getFullYear().toString().substring(2);
              const currentMonth = new Date().getMonth() + 1;
              if (year < currentYear || (year === currentYear && month < currentMonth)) {
                return "Expiry date is invalid.";
              }
            } else {
              return "Expiry date must be in MM/YY format.";
            }
          }
        },
      });
      register("CVV", {
        required: "CVV/CVC is required.",
        minLength: {
          value: 3,
          message: "Min. length of CVV/CVC is 3.",
        },
        maxLength: {
          value: 4,
          message: "Max. length of CVV/CVC is 4.",
        },
      });
    } else if (paymentType === 5) {
      unregister("cardNumber");
      unregister("expiryDate");
      unregister("CVV");
    }
  }, [paymentType]);

  const handleBillingShippingInfoSubmit = () => {
    billingShippingInfoFormRef.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  };

  const onPurchaseOrderNextStep = () => {
    if (purchaseOrderStep === 1) {
      handleBillingShippingInfoSubmit();
    } else if (purchaseOrderStep === 2) {
      setPurchaseOrderStep(3);
    } else if (purchaseOrderStep === 3) {
      if (watchIsPurchaseOrderRequired === "false") {
        handleSubmit(onPurchaseOrder)();
      } else {
        onPurchaseOrder();
      }
    } else if (purchaseOrderStep === 4) {
      onPurchaseOrderSignature();
    }
  };

  const onBillingShippingInfo = async (data) => {
    setBillingShippingInfo(data);

    setPurchaseOrderStep(2);
  };

  const onPurchaseOrder = async (paymentInfoData) => {
    setIsLoadingPurchaseOrder(true);

    const response = await OrdersService.purchaseQuote(
      props.orderId,
      {
        billFirstName: billingShippingInfo.billingFirstName,
        billLastName: billingShippingInfo.billingLastName,
        billCompanyName: billingShippingInfo.billingCompanyName,
        billPhoneNumber: billingShippingInfo.billingPhoneNumber,
        billEmailAddress: billingShippingInfo.billingEmail,
        billRegionName: billingShippingInfo.billingState,
        billRegionCode: billingShippingInfo.shippingState,
        billCityName: billingShippingInfo.billingCity,
        billPostalCode: billingShippingInfo.billingZip,
        billAddress: billingShippingInfo.billingAddress,
        billCountryName: "United States of America",
        billCountryCode: "USA",

        shipFirstName: billingShippingInfo.shippingFirstName,
        shipLastName: billingShippingInfo.shippingLastName,
        shipCompanyName: billingShippingInfo.shippingCompanyName,
        shipPhoneNumber: billingShippingInfo.shippingPhoneNumber,
        shipEmailAddress: billingShippingInfo.shippingEmail,
        shipRegionName: billingShippingInfo.shippingState,
        shipRegionCode: billingShippingInfo.shippingState,
        shipCityName: billingShippingInfo.shippingCity,
        shipPostalCode: billingShippingInfo.shippingZip,
        shipAddress: billingShippingInfo.shippingAddress,
        shipCountryName: "United States of America",
        shipCountryCode: "USA",
      },
      files && files.length > 0
        ? {
          documentBase64: await convertToBase64(files[0]),
          templateName: "PURCHASE_ORDER",
        }
        : null,
      paymentInfoData ? paymentType : null,
      paymentInfoData && paymentType === 3 ? {
        cardNumber: paymentInfoData.cardNumber.replaceAll(" ", ""),
        cardCode: paymentInfoData.CVV,
        expirationYear: 2000 + (+paymentInfoData.expiryDate.split("/")[1]),
        expirationMonth: +paymentInfoData.expiryDate.split("/")[0],
      } : null,
      paymentInfoData && paymentType === 5 ? {
        routingNumber: paymentInfoData.routingNumber.replaceAll(" ", "").replaceAll("-", ""),
        accountNumber: paymentInfoData.accountNumber,
        nameOnAccount: paymentInfoData.bankAccountName,
        bankName: paymentInfoData.bankName,
        checkNumber: paymentInfoData.checkNumber
      } : null
    );
    setActivePurchaseOrder(response);
    setIsLoadingPurchaseOrder(false);

    if (response.id) {
      setPurchaseOrderStep(4);
    } else if (response.resultList && response.resultList.length > 0) {
      if (paymentType === 3) {
        const cardNumberError = response.resultList.find((rl) => rl.key === "PaymentRequest.CreditCardInfo.CardNumber");
        const expirationYearError = response.resultList.find((rl) => rl.key === "PaymentRequest.CreditCardInfo.ExpirationYear");
        const expirationMonthError = response.resultList.find((rl) => rl.key === "PaymentRequest.CreditCardInfo.ExpirationMonth");
        const cardCodeError = response.resultList.find((rl) => rl.key === "PaymentRequest.CreditCardInfo.CardCode");

        if (cardNumberError) {
          setError("cardNumber", { message: cardNumberError.description });
        }

        if (expirationYearError || expirationMonthError) {
          setError("expiryDate", {
            message: expirationYearError ? expirationYearError.description : expirationMonthError.description,
          });
        }

        if (cardCodeError) {
          setError("CVV", { message: cardCodeError.description });
        }
      } else if (paymentType === 5) {
        const routingNumberError = response.resultList.find((rl) => rl.key === "PaymentRequest.BankCheckInfo.RoutingNumber");
        const accountNumberError = response.resultList.find((rl) => rl.key === "PaymentRequest.BankCheckInfo.AccountNumber");
        const bankNameError = response.resultList.find((rl) => rl.key === "PaymentRequest.BankCheckInfo.BankName");
        const bankAccountNameError = response.resultList.find((rl) => rl.key === "PaymentRequest.BankCheckInfo.BankAccountName");

        if (routingNumberError) {
          setError("routingNumber", { message: routingNumberError.description });
        }

        if (accountNumberError) {
          setError("accountNumber", { message: accountNumberError.description });
        }

        if (bankNameError) {
          setError("bankName", { message: bankNameError.description });
        }

        if (bankAccountNameError) {
          setError("bankAccountName", { message: bankAccountNameError.description });
        }
      }
    }
  };

  const onPurchaseOrderSignature = async () => {
    helloSignClient.open(activePurchaseOrder.signingUrl, { allowCancel: false });
  };

  const onPurchaseOrderCardNumberChange = (e) => {
    e.target.value = e.target.value
      .replace(/\s/g, "")
      .replace(/(\d{4})/g, "$1 ")
      .trim();
    setValue("cardNumber", e.target.value, { shouldValidate: true });
  };

  const onPurchaseOrderExpiryDateChange = (e) => {
    setValue("expiryDate", e.target.value, { shouldValidate: true });
  };

  const onPurchaseOrderCVVChange = (e) => {
    setValue("CVV", e.target.value, { shouldValidate: true });
  };

  const canGoBack = () => {
    return !isLoadingPurchaseOrder && [2, 3].includes(purchaseOrderStep);
  }

  const handleBack = () => {
    setPurchaseOrderStep(purchaseOrderStep - 1);
  }

  return (
    <Modal size="modal-lg">
      <ModalHeader className="pb-3 border-bottom">
        <h5 className="modal-title lh-1">Create a Purchase Order</h5>
        <button type="button" className="btn-close" onClick={props.close} aria-label="Close"></button>
      </ModalHeader>
      <ModalBody className="py-0 px-0">
        <div className="card mb-0 border-0 shadow-none">
          <div className="card-body p-0">
            <div className="mb-0">
              <ul className="stepper stepper-vertical">
                <li className={`stepper-step p-0 ${purchaseOrderStep === 1 ? "opened marked" : ""} ${purchaseOrderStep > 1 ? "completed" : ""}`}>
                  <div className="stepper-head border-bottom bg-light px-3 py-2">
                    <span className="stepper-head-icon">1</span>
                    <span className="stepper-head-text">
                      <span className="d-block">
                        <span className="d-block">Location</span>
                        <span className="d-block fw-normal text-muted fs-7">
                          {!billingShippingInfo && "Please enter your billing and shipping info"}
                          {billingShippingInfo && `${billingShippingInfo.billingFirstName} ${billingShippingInfo.billingLastName}, ${billingShippingInfo.billingAddress}, ${billingShippingInfo.billingState}, ${billingShippingInfo.billingCity}, ${billingShippingInfo.shippingZip}`}
                        </span>
                      </span>
                    </span>
                  </div>
                  <div className={`stepper-content ${purchaseOrderStep === 1 ? "p-3" : "p-0"}`}>
                    <BillingShippingInfoForm
                      onValidChange={(isValid) => {
                        if (isBillingShippingInfoValid != isValid) {
                          setIsBillingShippingInfoValid(isValid);
                        }
                      }}
                      onSubmit={onBillingShippingInfo}
                      formRef={billingShippingInfoFormRef}
                    />
                  </div>
                </li>

                <li className={`stepper-step p-0 ${purchaseOrderStep === 2 ? "opened marked" : ""} ${purchaseOrderStep > 2 ? "completed" : ""}`}>
                  <div className="stepper-head border-bottom bg-light px-3 py-2" tabIndex="-1">
                    <span className={`stepper-head-icon ${purchaseOrderStep < 2 ? "border border-primary" : ""}`}>2</span>
                    <span className="stepper-head-text">
                      <span className="d-block">
                        <span className="d-block">Document</span>
                        <span className="d-block fw-normal text-muted fs-7">
                          {!watchIsPurchaseOrderRequired && "Please select an option"}
                          {watchIsPurchaseOrderRequired === "true" && "I will upload purchase order document"}
                          {watchIsPurchaseOrderRequired === "false" && "I have the authority to approve this purchase"}
                        </span>
                      </span>
                    </span>
                  </div>
                  <div className={`stepper-content ${purchaseOrderStep === 2 ? "p-3" : "p-0"}`}>
                    <p className="mb-3">Is purchase order required? </p>
                    <div className="row row-cols-1 g-2">
                      <div className="col">
                        <label className="form-check-label w-100" htmlFor="purchase-order-document-required">
                          <div className="card card-sm mb-0">
                            <div className="card-body">
                              <div className="form-check">
                                <div className="row g-0 align-items-center">
                                  <div className="col-auto">
                                    <input
                                      className="form-check-input"
                                      id="purchase-order-document-required"
                                      type="radio"
                                      name="purchase-order-document"
                                      defaultValue={true}
                                      {...registerPurchaseOrder("isPurchaseOrderRequired")}
                                    />
                                  </div>
                                  <div className="col ms-1">
                                    <span className="d-block h6 mb-0">Yes</span>
                                    <span className="d-block mt-1 fs-7 lh-1 text-muted">I will upload purchase order document</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="col">
                        <label className="form-check-label w-100" htmlFor="purchase-order-document-not-required">
                          <div className="card card-sm mb-0">
                            <div className="card-body">
                              <div className="form-check">
                                <div className="row g-0 align-items-center">
                                  <div className="col-auto">
                                    <input
                                      className="form-check-input"
                                      id="purchase-order-document-not-required"
                                      type="radio"
                                      name="purchase-order-document"
                                      defaultValue={false}
                                      {...registerPurchaseOrder("isPurchaseOrderRequired")}
                                    />
                                  </div>
                                  <div className="col ms-1">
                                    <span className="d-block h6 mb-0">No</span>
                                    <span className="d-block mt-1 fs-7 lh-1 text-muted">I have the authority to approve this purchase</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </li>

                <li className={`stepper-step p-0 ${purchaseOrderStep === 3 ? "opened marked" : ""} ${purchaseOrderStep > 3 ? "completed" : ""}`}>
                  <div className="stepper-head border-bottom bg-light px-3 py-2">
                    <span className={`stepper-head-icon ${purchaseOrderStep < 3 ? "border border-primary" : ""}`}>3</span>
                    <span className="stepper-head-text">
                      <span className="d-block">
                        <span className="d-block">{!watchIsPurchaseOrderRequired ? "Upload / Payment" : watchIsPurchaseOrderRequired === "false" ? "Payment" : "Upload"}</span>
                        <span className="d-block fw-normal text-muted fs-7">{!watchIsPurchaseOrderRequired ? "Please select an option" : watchIsPurchaseOrderRequired === "false" ? "Please enter your payment info" : "Please upload your purchase order document"}</span>
                      </span>
                    </span>
                  </div>
                  <div className={`stepper-content ${purchaseOrderStep === 3 ? "p-3" : "p-0"}`}>
                    {watchIsPurchaseOrderRequired === "false" && (
                      <>
                        <p className="mb-3">Please select payment term below</p>

                        <form>
                          <div className="card mb-0 border-0 shadow-none">
                            <div className="card-body p-0">
                              <div className="accordion accordion-flush" id="purchase-order-payment-method">
                                <div className="accordion-item text-body border-0" onClick={() => setPaymentType(3)}>
                                  <label
                                    className="accordion-button accordion-button-radio px-0 border-0 shadow-none"
                                    htmlFor="purchase-order-payment-credit-card-method"
                                    id="purchase-order-payment-method-credit-card"
                                  >
                                    <span className="col">
                                      <img className="col-1" src="./assets/img/payment/credit-card.svg" alt="" height="14" />
                                      <span>Credit card</span>
                                    </span>
                                    <span className="col-auto">
                                      <input
                                        className="form-check-input"
                                        id="purchase-order-payment-credit-card-method"
                                        type="radio"
                                        name="purchase-order-payment-method"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#purchase-order-payment-credit-card"
                                        {...registerPurchaseOrder("paymentTerm")}
                                        disabled={isLoadingPurchaseOrder}
                                      />
                                    </span>
                                  </label>
                                  <div
                                    id="purchase-order-payment-credit-card"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="purchase-order-payment-method-credit-card"
                                    data-bs-parent="#purchase-order-payment-method"
                                  >
                                    <div className="accordion-body p-2 pe-0">
                                      <div className="card card-sm mb-0">
                                        <div className="card-body">
                                          <div className="row">
                                            <div className="col-12 mb-3">
                                              <label className="form-label" htmlFor="purchase-order-card-number">
                                                Credit Card Number
                                              </label>
                                              <div className="input-group input-group-merge input-group-reverse">
                                                <input
                                                  {...(paymentType === 3 && getCardNumberProps({ onChange: onPurchaseOrderCardNumberChange }))}
                                                  id="purchase-order-card-number"
                                                  className={`form-control form-control-lg ${errors.cardNumber ? "border-danger" : ""}`}
                                                  placeholder="1234 5678 9101 1121"
                                                  type="text"
                                                  aria-describedby="purchase-order-card-number-credit"
                                                  minLength={13}
                                                  maxLength={19}
                                                  disabled={isLoadingPurchaseOrder}
                                                />
                                                <div id="purchase-order-card-number-credit" className={`input-group-text ${errors.cardNumber ? "border-danger" : ""}`}>
                                                  <svg {...getCardImageProps({ images })} />
                                                </div>
                                              </div>

                                              {errors.cardNumber && <small className="text-danger">{errors.cardNumber.message}</small>}
                                            </div>
                                            <div className="col-6">
                                              <label className="form-label" htmlFor="purchase-order-card-expiry">
                                                Expiry Date
                                              </label>
                                              <div className="input-group input-group-merge input-group-reverse">
                                                <input
                                                  {...getExpiryDateProps({ onChange: onPurchaseOrderExpiryDateChange })}
                                                  className={`form-control form-control-lg ${errors.expiryDate ? "border-danger" : ""}`}
                                                  id="purchase-order-card-expiry"
                                                  placeholder="MM/YY"
                                                  type="text"
                                                  aria-describedby="purchase-order-card-expiry-calendar"
                                                  maxLength={7}
                                                  disabled={isLoadingPurchaseOrder}
                                                />
                                                <div id="purchase-order-card-expiry-calendar" className={`input-group-text ${errors.expiryDate ? "border-danger" : ""}`}>
                                                  <i className="fe fe-calendar"></i>
                                                </div>
                                              </div>
                                              {errors.expiryDate && <small className="text-danger">{errors.expiryDate.message}</small>}
                                            </div>
                                            <div className="col-6">
                                              <label className="form-label" htmlFor="purchase-order-card-code">
                                                Security Code
                                              </label>
                                              <PasswordInput
                                                id="purchase-order-card-code"
                                                placeholder="CVV"
                                                minLength={3}
                                                maxLength={4}
                                                disabled={isLoadingPurchaseOrder}
                                                inputGroupClassName={errors.CVV ? "" : "mb-3"}
                                                iconClassName={errors.CVV && "border-danger text-danger"}
                                                inputClassName={errors.CVV && "border-danger"}
                                                formOptions={getCVCProps({ onChange: onPurchaseOrderCVVChange, type: null })}
                                              />
                                              {errors.CVV && <small className="text-danger">{errors.CVV.message}</small>}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="accordion-item text-body border-0" onClick={() => setPaymentType(5)}>
                                  <label
                                    className="accordion-button accordion-button-radio px-0 border-0 shadow-none"
                                    htmlFor="purchase-order-payment-check-method"
                                    id="purchase-order-payment-method-check"
                                  >
                                    <span className="col">
                                      <img className="col-1" src="./assets/img/payment/check.svg" alt="" height="14" />
                                      <span>Check</span>
                                    </span>
                                    <span className="col-auto">
                                      <input
                                        className="form-check-input"
                                        id="purchase-order-payment-check-method"
                                        type="radio"
                                        name="purchase-order-payment-method"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#purchase-order-payment-check"
                                        {...registerPurchaseOrder("paymentTerm")}
                                        disabled={isLoadingPurchaseOrder}
                                      />
                                    </span>
                                  </label>
                                  <div
                                    id="purchase-order-payment-check"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="purchase-order-payment-method-check"
                                    data-bs-parent="#purchase-order-payment-method"
                                  >
                                    <div className="accordion-body p-2 pe-0">
                                      <div className="card card-sm mb-0">
                                        <div className="card-body">
                                          <div className="row">
                                            <div className="col-6 mb-3">
                                              <label className="form-label w-100" htmlFor="purchase-order-routing-number">
                                                Routing Number <span className="text-danger">*</span>
                                                <OverlayTrigger
                                                  placement="top"
                                                  trigger="click"
                                                  overlay={
                                                    <Tooltip>
                                                      The ABA Routing Number is a nine-digit number that identifies the financial institution associated with a customer’s bank account. To obtain a
                                                      routing number, look at the bottom left corner of a current check or deposit slip from the bank.
                                                    </Tooltip>
                                                  }
                                                  rootClose={true}
                                                >
                                                  <a className="float-end text-info" href="#!">
                                                    <span className="fe fe-info"></span>
                                                  </a>
                                                </OverlayTrigger>
                                              </label>
                                              <div className="input-group input-group-merge input-group-reverse">
                                                <input
                                                  className={`form-control form-control-lg ${errors.routingNumber ? "border-danger" : ""}`}
                                                  id="purchase-order-routing-number"
                                                  placeholder=""
                                                  type="text"
                                                  aria-describedby="routing-number-check"
                                                  {...(paymentType === 5 && register("routingNumber", {
                                                    required: true,
                                                    maxLength: 9
                                                  }))}
                                                  disabled={isLoadingPurchaseOrder}
                                                />
                                                <div id="purchase-order-routing-number-check" className={`input-group-text ${errors.routingNumber ? "border-danger" : ""}`}>
                                                  <i className="fe fe-hash"></i>
                                                </div>
                                              </div>
                                              {errors.routingNumber && errors.routingNumber.type === "required" && (
                                                <small className="text-danger">Routing number is required.</small>
                                              )}
                                              {errors.routingNumber && errors.routingNumber.type !== "required" && (
                                                <small className="text-danger">Routing number not in valid format.</small>
                                              )}
                                            </div>
                                            <div className="col-6 mb-3">
                                              <label className="form-label w-100" htmlFor="purchase-order-account-number">
                                                Account Number <span className="text-danger">*</span>
                                                <OverlayTrigger
                                                  placement="top"
                                                  trigger="click"
                                                  overlay={
                                                    <Tooltip>
                                                      The account number associated with a savings or checking account at a financial institution. The checking account number is located at the bottom
                                                      of a check.
                                                    </Tooltip>
                                                  }
                                                  rootClose={true}
                                                >
                                                  <a className="float-end text-info" href="#!">
                                                    <span className="fe fe-info"></span>
                                                  </a>
                                                </OverlayTrigger>
                                              </label>
                                              <div className="input-group input-group-merge input-group-reverse">
                                                <input
                                                  className={`form-control form-control-lg ${errors.accountNumber ? "border-danger" : ""}`}
                                                  id="purchase-order-account-number"
                                                  placeholder=""
                                                  type="text"
                                                  aria-describedby="account-number-check"
                                                  {...(paymentType === 5 && register("accountNumber", {
                                                    required: true,
                                                    maxLength: 17
                                                  }))}
                                                  disabled={isLoadingPurchaseOrder}
                                                />
                                                <div id="purchase-order-account-number-check" className={`input-group-text ${errors.accountNumber ? "border-danger" : ""}`}>
                                                  <i className="fe fe-hash"></i>
                                                </div>
                                              </div>
                                              {errors.accountNumber && errors.accountNumber.type === "required" && (
                                                <small className="text-danger">Account Number is required.</small>
                                              )}
                                              {errors.accountNumber && errors.accountNumber.type !== "required" && (
                                                <small className="text-danger">Account Number not in valid format.</small>
                                              )}
                                            </div>
                                            <div className="col-12 mb-3">
                                              <label className="form-label" htmlFor="purchase-order-bank-name">
                                                Bank Name
                                              </label>
                                              <div className="input-group input-group-merge input-group-reverse">
                                                <input
                                                  className={`form-control form-control-lg ${errors.bankName ? "border-danger" : ""}`}
                                                  id="purchase-order-bank-name"
                                                  placeholder=""
                                                  type="text"
                                                  aria-describedby="bank-name-check"
                                                  {...(paymentType === 5 && register("bankName", {
                                                    required: false,
                                                    maxLength: 22
                                                  }))}
                                                  disabled={isLoadingPurchaseOrder}
                                                />
                                                <div id="purchase-order-bank-name-check" className={`input-group-text ${errors.bankName ? "border-danger" : ""}`}>
                                                  <i className="fe fe-home"></i>
                                                </div>
                                              </div>
                                              {errors.bankName && errors.bankName.type === "required" && (
                                                <small className="text-danger">Bank Name is required.</small>
                                              )}
                                              {errors.bankName && errors.bankName.type !== "required" && (
                                                <small className="text-danger">Bank Name not in valid format.</small>
                                              )}
                                            </div>
                                            <div className="col-12 mb-3">
                                              <label className="form-label" htmlFor="purchase-order-bank-account-name">
                                                Bank Account Name <span className="text-danger">*</span>
                                              </label>
                                              <div className="input-group input-group-merge input-group-reverse">
                                                <input
                                                  className={`form-control form-control-lg ${errors.bankAccountName ? "border-danger" : ""}`}
                                                  id="purchase-order-bank-account-name"
                                                  placeholder=""
                                                  type="text"
                                                  aria-describedby="purchase-order-bank-account-name-check"
                                                  {...(paymentType === 5 && register("bankAccountName", {
                                                    required: true,
                                                    maxLength: 22
                                                  }))}
                                                  disabled={isLoadingPurchaseOrder}
                                                />
                                                <div id="purchase-order-bank-account-name-check" className={`input-group-text ${errors.bankAccountName ? "border-danger" : ""}`}>
                                                  <i className="fe fe-user"></i>
                                                </div>
                                              </div>
                                              {errors.bankAccountName && errors.bankAccountName.type === "required" && (
                                                <small className="text-danger">Bank Account Name is required.</small>
                                              )}
                                              {errors.bankAccountName && errors.bankAccountName.type !== "required" && (
                                                <small className="text-danger">Bank Account Name not in valid format.</small>
                                              )}
                                            </div>
                                            <div className="col-12">
                                              <label className="form-label" htmlFor="purchase-order-bank-account-type">
                                                Bank Account Type <span className="text-danger">*</span>
                                              </label>
                                              <select
                                                name="purchase-order-bank-account-type"
                                                className="form-control form-control-lg"
                                                {...(paymentType === 5 && register("bankAccountType", {
                                                  required: true
                                                }))}
                                                disabled={isLoadingPurchaseOrder}
                                              >
                                                <option value="business-checking">Business Checking</option>
                                                <option value="checking">Checking</option>
                                                <option value="savings">Savings</option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="accordion-item text-body border-0">
                                  <label
                                    className="accordion-button accordion-button-radio px-0 border-0 shadow-none"
                                    htmlFor="purchase-order-payment-bank-wire-method"
                                    id="purchase-order-payment-method-bank-wire"
                                  >
                                    <span className="col">
                                      <img className="col-1" src="./assets/img/payment/bank-wire.svg" alt="" height="14" />
                                      <span>Bank Transfer</span>
                                    </span>
                                    <span className="col-auto">
                                      <input
                                        className="form-check-input"
                                        id="purchase-order-payment-bank-wire-method"
                                        type="radio"
                                        name="purchase-order-payment-method"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#purchase-order-payment-bank-wire"
                                        {...registerPurchaseOrder("paymentTerm")}
                                        disabled={isLoadingPurchaseOrder}
                                      />
                                    </span>
                                  </label>
                                  <div
                                    id="purchase-order-payment-bank-wire"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="purchase-order-payment-method-bank-wire"
                                    data-bs-parent="#purchase-order-payment-method"
                                  >
                                    <div className="accordion-body p-2 pe-0">
                                      <div className="card card-sm mb-0">
                                        <div className="card-body">Currently not available.</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                        {/* <div className="row row-cols-1 g-2">
                                <div className="col">
                                  <label className="form-check-label w-100" htmlFor="purchase-order-payment-credit-card">
                                    <div className="card card-sm mb-0">
                                      <div className="card-body">
                                        <div className="form-check">
                                          <div className="row g-0 align-items-center">
                                            <div className="col-auto">
                                              <input
                                                className="form-check-input"
                                                id="purchase-order-payment-credit-card"
                                                type="radio"
                                                name="purchase-order-payment"
                                                value="card"
                                                {...registerPurchaseOrder("paymentTerm")}
                                              />
                                            </div>
                                            <div className="col ms-1">
                                              <span className="d-block h6 pt-1 mb-0">Credit Card</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                                <div className="col">
                                  <label className="form-check-label w-100" htmlFor="purchase-order-payment-check">
                                    <div className="card card-sm mb-0">
                                      <div className="card-body">
                                        <div className="form-check">
                                          <div className="row g-0 align-items-center">
                                            <div className="col-auto">
                                              <input
                                                className="form-check-input"
                                                id="purchase-order-payment-check"
                                                type="radio"
                                                name="purchase-order-payment"
                                                value="check"
                                                {...registerPurchaseOrder("paymentTerm")}
                                              />
                                            </div>
                                            <div className="col ms-1">
                                              <span className="d-block h6 pt-1 mb-0">Check</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                                <div className="col">
                                  <label className="form-check-label w-100" htmlFor="purchase-order-payment-line-of-credit">
                                    <div className="card card-sm mb-0">
                                      <div className="card-body">
                                        <div className="form-check">
                                          <div className="row g-0 align-items-center">
                                            <div className="col-auto">
                                              <input
                                                className="form-check-input"
                                                id="purchase-order-payment-line-of-credit"
                                                type="radio"
                                                name="purchase-order-payment"
                                                value="lineOfCredit"
                                                {...registerPurchaseOrder("paymentTerm")}
                                              />
                                            </div>
                                            <div className="col ms-1">
                                              <span className="d-block h6 pt-1 mb-0">Line of Credit</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </div> */}
                      </>
                    )}

                    {watchIsPurchaseOrderRequired === "true" && (
                      <>
                        <p className="mb-3">Please upload your purchase order document below (.pdf)</p>
                        <Dropzone
                          accept={{
                            "application/pdf": [".pdf"],
                          }}
                          multiple={false}
                          noDragEventsBubbling={true}
                          maxFiles={1}
                          onDrop={(files) => {
                            if (files && files.length > 0 && !files[0].name.endsWith(".pdf")) {
                              return;
                            }
                            setFiles(files);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <React.Fragment>
                              <div
                                {...getRootProps({
                                  className: "dropzone dropzone-single dz-clickable",
                                })}
                              >
                                <input {...getInputProps()} />
                                <div className="dz-default dz-message">
                                  <button className="dz-button" type="button">
                                    {files && files.length === 0 && <>Drop files here to upload</>}
                                    {files &&
                                      files.length > 0 &&
                                      files.map((item, i) => (
                                        <React.Fragment key={i}>
                                          {item.name}
                                          <br></br>
                                        </React.Fragment>
                                      ))}
                                  </button>
                                </div>
                              </div>
                            </React.Fragment>
                          )}
                        </Dropzone>
                      </>
                    )}
                  </div>
                </li>

                <li className={`stepper-step p-0 ${purchaseOrderStep === 4 ? "opened marked" : ""}`}>
                  <div className="stepper-head bg-light px-3 py-2">
                    <span className={`stepper-head-icon ${purchaseOrderStep < 4 ? "border border-primary" : ""}`}>4</span>
                    <span className="stepper-head-text">
                      <span className="d-block">
                        <span className="d-block">Signature</span>
                        <span className="d-block fw-normal text-muted fs-7">Please review and sign all required documents</span>
                      </span>
                    </span>
                  </div>

                  {purchaseOrderStep === 4 && (
                    <div className={`stepper-content ${purchaseOrderStep === 4 ? "p-3" : "p-0"}`}>
                      <p className="mb-3">Please review and sign the documents below.</p>
                      <div className="row row-cols-1 g-2 mb-3">
                        {activePurchaseOrder.documents.map((d, i) => (
                          <div className="col" key={i}>
                            <div className="card card-sm mb-0">
                              <div className="card-body">
                                <div className="row g-2 align-items-center">
                                  <div className="col-auto">
                                    <img className="me-1" src="./assets/img/docs/pdf.svg" alt="" height="40" />
                                  </div>
                                  <div className="col">
                                    <h6 className="mb-0">{d.documentName}</h6>
                                    {
                                      <p className="mb-0 fs-7 text-muted">
                                        <a href={d.documentUrl} target="_blank" rel="noreferrer">
                                          Click to view
                                        </a>
                                      </p>
                                    }
                                  </div>
                                  <div className="col-auto ms-auto me-n2">
                                    <div className="form-check mb-0">
                                      <input className="form-check-input" type="checkbox" name="purchase-order-signature" defaultChecked={true} {...registerPurchaseOrder("purchaseOrderDocument")} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      {/* <div className="mb-3">
                              <div className="row">
                                <div className="col">
                                  <a id="purchase-order-doc" className="btn btn-white d-none" href={purchaseOrderDocUrl} download>
                                  &nbsp;
                                </a>
                                <a id="exemption-certificate-doc" className="btn btn-white d-none" href={exemptionDocUrl} download>
                                  &nbsp;
                                </a>
                                  <div className="btn-group w-100">
                                    <button className="btn btn-white" onClick={onDownload} type="button">
                                      <span className="fe fe-download disabled"></span>
                                      <span> Download</span>
                                    </button>
                                    <button className="btn btn-white" onClick={onPrint} type="button">
                                      <span className="fe fe-printer"></span>
                                      <span> Print</span>
                                    </button>
                                    <button className="btn btn-white" type="button">
                                      <span className="fe fe-share-2"></span>
                                      <span> Share</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                      <div className="mb-0">
                        <div className="form-check">
                          <input
                            className="form-check-input rounded"
                            id="order-agreement"
                            type="checkbox"
                            disabled={isLoadingPurchaseOrder}
                            defaultChecked={isAgreedToSignExemptionCertificate}
                            onClick={() => setIsAgreedToSignExemptionCertificate(!isAgreedToSignExemptionCertificate)}
                          />
                          <label className="form-check-label" htmlFor="order-agreement">
                            I acknowledge that I have read and understand exemption certificate
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="d-block px-3 py-2">
        <div className="row g-0 align-items-center justify-content-center">
          <div className="col">
            <span className="d-block fs-7">Total</span>
            <span className="d-block fw-bold text-primary fs-5">{formatPrice(props.totalPrice)}</span>
          </div>
          <div className="col-auto">
            {canGoBack() && <button className="btn btn-light me-2" onClick={handleBack}>
              <span className="fe fe-chevron-left"></span>&nbsp;Back
            </button>}
            <button
              className="btn btn-primary px-4"
              type="button"
              onClick={onPurchaseOrderNextStep}
              disabled={
                isLoadingPurchaseOrder ||
                (purchaseOrderStep === 1 && !isBillingShippingInfoValid) ||
                (purchaseOrderStep === 2 && !watchIsPurchaseOrderRequired) ||
                (purchaseOrderStep === 3 && !isValid && !watchPaymentTerm && files && files.length === 0) ||
                (purchaseOrderStep === 4 && !isAgreedToSignExemptionCertificate)
              }
            >
              {isLoadingPurchaseOrder && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
              &nbsp;
              <span>
                {purchaseOrderStep === 1 && "Next"}
                {purchaseOrderStep === 2 && "Next"}
                {purchaseOrderStep === 3 && "Create order"}
                {purchaseOrderStep === 4 && "Sign with e-Signature"}
              </span>
              {[1, 2].includes(purchaseOrderStep) && <>&nbsp;<span className="fe fe-chevron-right"></span></>}
              {[3].includes(purchaseOrderStep) && <>&nbsp;<span className="fe fe-check"></span></>}
            </button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
}
