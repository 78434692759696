import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { handleValidatePhoneNumber } from "../../../utils/regex";
import OrganizationService from "../../../services/OrganizationService";
import debounce from "debounce";
import SmartyService from "../../../services/SmartyService";

const CreateOrganizationModal = ({ onCreate }) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });
  const [businessEntityTypes, setBusinessEntityTypes] = useState([]);
  const [addressSuggestions, setAddressSuggestions] = useState([]);

  useEffect(() => {
    register("businessEntityTypeId", { required: true });
    const fetchBusinessEntityTypes = async () => {
      const data = await OrganizationService.getBusinessEntityTypes();
      setBusinessEntityTypes(data);
    };
    fetchBusinessEntityTypes();
  }, []);

  const onBusinessEntityTypeChange = (selectedItem) => {
    setValue("businessEntityTypeId", selectedItem?.value);
  };

  const onAddressChange = async (e) => {
    setSelectedAddress({ streetLine: "", city: "", state: "", zipcode: "" }, false);
    if (!e.target.value || e.target.value === "") {
      setAddressSuggestions([]);
      hideAddressAutocomplete();
      return;
    }
    const suggestions = await SmartyService.usAddressLookup(e.target.value);
    setAddressSuggestions(suggestions);
    if (suggestions.length > 0) {
      showAddressAutocomplete();
    } else {
      hideAddressAutocomplete();
    }
  };

  const setSelectedAddress = (item, updateAddressField = true) => {
    if (updateAddressField) {
      setValue("address", item.streetLine, { shouldValidate: true });
    }
    setValue("city", item.city, { shouldValidate: true });
    setValue("state", item.state, { shouldValidate: true });
    setValue("zipCode", item.zipcode, { shouldValidate: true });
    hideAddressAutocomplete();
  };

  const hideAddressAutocomplete = () => {
    const addressLookupDiv = document.getElementById("address-lookup");
    if (addressLookupDiv && addressLookupDiv.classList.contains("show")) {
      addressLookupDiv.classList.remove("show");
    }
  };

  const showAddressAutocomplete = () => {
    const addressLookupDiv = document.getElementById("address-lookup");
    if (addressLookupDiv && !addressLookupDiv.classList.contains("show")) {
      addressLookupDiv.classList.add("show");
      const cardBody = addressLookupDiv.querySelector(".card-body");
      if (cardBody) {
        cardBody.scrollTop = 0;
      }
    }
  };

  return ReactDOM.createPortal(
    <div className="modal modal-lg fade" id="add-organization" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div className="modal-content border-0">
          <div className="modal-header pb-0 border-0">
            <h5 className="modal-title lh-1">Create Organization</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="card mb-0 border-0 shadow-none">
              <div className="card-body p-0">
                <form onSubmit={handleSubmit(onCreate)}>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="name">
                          Name <span className="text-danger">*</span>
                        </label>
                        <div className={`input-group input-group-merge input-group-reverse ${errors.name ? "" : "mb-2"}`}>
                          <input
                            id="name"
                            className={`form-control form-control-lg ${errors.name ? "border-danger" : ""}`}
                            {...register("name", { required: true })}
                            placeholder="Enter name"
                            type="text"
                            aria-label="Enter name"
                            aria-describedby="name-users"
                          />
                          <div className={`input-group-text ${errors.name ? "border-danger" : ""}`} id="name-users">
                            <i className="fe fe-users"></i>
                          </div>
                        </div>
                        {errors.name && <small className="text-danger mb-3">Name is required.</small>}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="type">
                          Type <span className="text-danger">*</span>
                        </label>
                        <div className={`${errors.businessEntityTypeId ? "border-danger" : ""}`}>
                          <Select
                            options={businessEntityTypes && businessEntityTypes.map((item) => ({ value: item.id, label: item.name }))}
                            classNames={{ control: () => 'form-control form-control-select' }}
                            onChange={onBusinessEntityTypeChange}
                          />
                        </div>

                        {errors.businessEntityTypeId && <small className="text-danger mb-3">Type is required.</small>}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="address">
                          Address <span className="text-danger">*</span>
                        </label>
                        <div className={`input-group input-group-merge input-group-reverse h-100 ${errors.address ? "" : "mb-2"}`}>
                          <input
                            id="address"
                            className={`form-control form-control-lg ${errors.address ? "border-danger" : ""}`}
                            {...register("address", { required: true })}
                            placeholder="Enter address"
                            type="text"
                            aria-label="Enter address"
                            aria-describedby="address"
                            onChange={debounce(async (e) => {
                              onAddressChange(e);
                            }, 400)}
                            onClick={() => {
                              if (addressSuggestions.length > 0) {
                                showAddressAutocomplete();
                              }
                            }}
                          />
                          <div className={`input-group-text ${errors.address ? "border-danger" : ""}`} id="address">
                            <i className="fe fe-map-pin"></i>
                          </div>
                        </div>
                        <div id="address-lookup" className={`dropdown-menu dropdown-menu-card`} onMouseLeave={hideAddressAutocomplete} style={{ minWidth: "60%" }}>
                          <div className="card-body">
                            <div className="list-group list-group-flush">
                              {addressSuggestions &&
                                addressSuggestions.map((item, i) => (
                                  <a key={i} className="list-group-item" href="#!" onClick={() => setSelectedAddress(item)}>
                                    <span className="row g-0 align-items-center">
                                      <span className="col-auto">
                                        <span className="fs-7">
                                          {item.streetLine} {item.city}, {item.state} {item.zipcode}
                                        </span>
                                      </span>
                                    </span>
                                  </a>
                                ))}
                            </div>
                          </div>
                        </div>
                        {errors.address && <small className="text-danger mb-3">Address is required.</small>}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="city">
                          City
                        </label>
                        <div className={`input-group input-group-merge input-group-reverse ${errors.city ? "" : "mb-2"}`}>
                          <input
                            className={`form-control form-control-lg ${errors.city ? "border-danger" : ""}`}
                            {...register("city")}
                            id="text"
                            placeholder="Enter address to autofill"
                            type="text"
                            aria-label="Enter address to autofill"
                            aria-describedby="email"
                            disabled={true}
                          />
                          <div className={`input-group-text ${errors.city ? "border-danger" : ""}`} id="city">
                            <i className="fe fe-map-pin"></i>
                          </div>
                        </div>
                        {errors.city && <small className="text-danger mb-3">{errors.city?.message}</small>}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="state">
                          State
                        </label>
                        <div className={`input-group input-group-merge input-group-reverse ${errors.state ? "" : "mb-2"}`}>
                          <input
                            className={`form-control form-control-lg ${errors.state ? "border-danger" : ""}`}
                            {...register("state")}
                            id="text"
                            placeholder="Enter address to autofill"
                            type="text"
                            aria-label="Enter address to autofill"
                            aria-describedby="state"
                            disabled={true}
                          />
                          <div className={`input-group-text ${errors.state ? "border-danger" : ""}`} id="city">
                            <i className="fe fe-map-pin"></i>
                          </div>
                        </div>
                        {errors.state && <small className="text-danger mb-3">{errors.state?.message}</small>}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="zipCode">
                          Zip Code
                        </label>
                        <div className={`input-group input-group-merge input-group-reverse ${errors.zipCode ? "" : "mb-2"}`}>
                          <input
                            className={`form-control form-control-lg ${errors.zipCode ? "border-danger" : ""}`}
                            {...register("zipCode")}
                            id="text"
                            placeholder="Enter address to autofill"
                            type="text"
                            aria-label="Enter address to autofill"
                            aria-describedby="state"
                            disabled={true}
                          />
                          <div className={`input-group-text ${errors.zipCode ? "border-danger" : ""}`} id="city">
                            <i className="fe fe-map-pin"></i>
                          </div>
                        </div>
                        {errors.zipCode && <small className="text-danger mb-3">{errors.zipCode?.message}</small>}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="phone">
                          Phone
                        </label>
                        <div className={`input-group input-group-merge input-group-reverse ${errors.phone ? "" : "mb-2"}`}>
                          <input
                            className={`form-control form-control-lg ${errors.phone ? "border-danger" : ""}`}
                            {...register("phone", {
                              validate: (value) => (value ? (handleValidatePhoneNumber(value) ? true : "Phone is not in valid format") : true),
                            })}
                            id="phone"
                            placeholder="(860) 426-9886"
                            type="text"
                            aria-label="(860) 426-9886"
                            aria-describedby="phone"
                          />
                          <div className={`input-group-text ${errors.phone ? "border-danger" : ""}`} id="phone">
                            <i className="fe fe-phone"></i>
                          </div>
                        </div>
                        {errors.phone && <small className="text-danger mb-3">{errors.phone.message}</small>}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="country">
                          E-mail
                        </label>
                        <div className={`input-group input-group-merge input-group-reverse ${errors.email ? "" : "mb-2"}`}>
                          <input
                            className={`form-control form-control-lg ${errors.email ? "border-danger" : ""}`}
                            {...register("email")}
                            id="email"
                            placeholder="john.smith@example.com"
                            type="text"
                            aria-label="john.smith@example.com"
                            aria-describedby="email"
                          />
                          <div className={`input-group-text ${errors.email ? "border-danger" : ""}`} id="email">
                            <i className="fe fe-at-sign"></i>
                          </div>
                        </div>
                        {errors.email && <small className="text-danger mb-3">{errors.email?.message}</small>}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="website">
                          Website
                        </label>
                        <div className={`input-group input-group-merge input-group-reverse ${errors.webUrl ? "" : "mb-2"}`}>
                          <input
                            className={`form-control form-control-lg ${errors.webUrl ? "border-danger" : ""}`}
                            {...register("webUrl")}
                            id="website"
                            placeholder="Enter website URL"
                            type="text"
                            aria-label="Enter website"
                            aria-describedby="website"
                          />
                          <div className={`input-group-text ${errors.webUrl ? "border-danger" : ""}`} id="website">
                            <i className="fe fe-link"></i>
                          </div>
                        </div>
                        {errors.webUrl && <small className="text-danger mb-3">{errors.webUrl?.message}</small>}
                      </div>
                    </div>
                  </div>
                  <div className="hstack gap-3">
                    <button className="btn btn-lg btn-light w-100" type="button" data-bs-dismiss="modal">
                      Close
                    </button>
                    <button className="btn btn-lg btn-primary w-100" type="submit">
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("create-organization-modal")
  );
};

export { CreateOrganizationModal };
