function onMinusPressed(event) {
  event.preventDefault();
  const el = event.currentTarget.button;
  const input = el.nextElementSibling;
  const value = Number(input.value) || 0;
  if (value) input.value = value - 1;
}

function onPlusPressed(event) {
  event.preventDefault();
  const el = event.currentTarget.button;
  const input = el.previousElementSibling;
  const value = Number(input.value) || 0;
  if (value) input.value = value + 1;
}
export default function initNumberBtn() {
  const minus = document.querySelectorAll(".btn-minus");
  const plus = document.querySelectorAll(".btn-plus");

  minus.forEach((el) => {
    el.button = el;
    el.removeEventListener("click", onMinusPressed);
    el.addEventListener("click", onMinusPressed);
  });

  plus.forEach((el) => {
    el.button = el;
    el.removeEventListener("click", onPlusPressed);
    el.addEventListener("click", onPlusPressed);
  });
}
