const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      const base64String = fileReader.result.replace("data:", "").replace(/^.+,/, "");
      resolve(base64String);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export { convertToBase64 };
