import axios from "axios";
import { GET_ALL_ORDER_STATUSES } from "./CONSTANTS";
import IOrderStatus from "../types/OrderStatus";

export const getAllOrderStatuses = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get("https://testapi-commerce.emaq.ba/api/OrderStatusTranslations", {
          headers: {
            ClientId: "1CAA63AA-45ED-48AE-8572-F7E9BDDAA3EF",
            "Accept-Language": "en",
          },
          params: {
            MajorMilestone: true,
            OrderBy: "OrderStatus.Stage ASC",
            "AdditionalData.IncludeList": "OrderStatus"
          }
        })
        .then((res) => {
          var response = res as any;
          resolve(response.data.resultList);
        })
        .catch(() => { });
    } catch (error) { }
  });
};

export const getOrderStatusesByActionType = (actionType) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get<Array<IOrderStatus>>(GET_ALL_ORDER_STATUSES())
        .then((res) => {
          if (actionType === "purchase") {
            res.data = res.data.filter((d) => d.id > 1);
          }
          resolve(res.data);
        })
        .catch(() => { });
    } catch (error) { }
  });
};

const OrderStatusesService = {
  getAllOrderStatuses,
  getOrderStatusesByActionType,
};

export default OrderStatusesService;
