import { createContext, useContext } from "react";

import cartStore from "./CartStore";
import searchStore from "./SearchStore";
import ordersStore from "./OrdersStore";
import sortStore from "./SortStore";
import filtersStore from "./FiltersStore";
import productsStore from "./ProductsStore";

const store = {
  cartStore: cartStore,
  searchStore: searchStore,
  ordersStore: ordersStore,
  sortStore: sortStore,
  filtersStore: filtersStore,
  productsStore: productsStore,
};

export const StoreContext = createContext(store);

export const useStore = () => {
  return useContext(StoreContext);
};

export default store;
