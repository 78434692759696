import React, { useEffect, useState } from "react";
import ProductService from "../../services/ProductService";
import { useStore } from "../../stores/StoreContext";
import { observer } from "mobx-react";
import { ProductPreview } from "./components/ProductPreview";
import showFlyoutCard from "../../utils/flyout";
import SubcategoryService from "../../services/SubcategoryService";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { ProductItem } from "./components/ProductItem";
import initChoices from "../../utils/choices";
import UserService from "../../services/UserService";
import BuyNowModal from "../../modals/BuyNowModal";
import ModalService from "../../modals/BuyNowModal";
import debounce from "debounce";
import { last } from "pdf-lib";

export const HomeView = observer(() => {
  const { cartStore, searchStore, sortStore, filtersStore, productsStore } = useStore();
  const [products, setProducts] = useState();
  const [allProducts, setAllProducts] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [loadedSubcategories, setLoadedSubcategories] = useState([]);
  const [isAllLoaded, setIsAllLoaded] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [activeSubcategoryId, setActiveSubcategoryId] = useState();
  const [subcategoriesPagingParams, setSubcategoriesPagingParams] = useState([]);
  const [lastScrollTop, setLastScrollTop] = useState();
  const [isCurrentUserIndividual, setIsCurrentUserIndividual] = useState();

  const initArrowKeys = (e) => {
    e = e || window.event;
    const key = { LEFT: 37, UP: 38, RIGHT: 39, DOWN: 40 };
    var selectedSubcategoryAccordion = document.querySelector(".show");
    var selectedSubcategory = selectedSubcategoryAccordion ? selectedSubcategoryAccordion.dataset.type : null;
    var selectedEls = document.getElementsByClassName("selected");
    if (!selectedEls || selectedEls.length === 0) return;

    var selectedElIndex = selectedSubcategory ? [...selectedEls].findIndex((e) => e.dataset.type === selectedSubcategory) : 0;
    if (selectedElIndex === -1) return;

    if (e.keyCode === key.LEFT || e.keyCode === key.UP || e.keyCode === key.RIGHT || e.keyCode === key.DOWN) {
      var currentIndex = parseInt(selectedEls[selectedElIndex].dataset.index);
      const direction = e.keyCode === key.LEFT || e.keyCode === key.UP ? -1 : 1;
      const skip = e.keyCode === key.UP || e.keyCode === key.DOWN ? (productsStore.layout === "card" ? (window.matchMedia("(min-width: 1600px)").matches ? 3 : 2) : 1) : 1;
      var newIndex = skip * direction + currentIndex;

      var newElement = document.getElementById("card-" + selectedEls[selectedElIndex].dataset.type + "-" + newIndex);
      if (!newElement) return;

      selectedEls[selectedElIndex].classList.remove("selected");
      newElement.classList.add("selected");

      const showDetailsBtn = document.getElementById("show-details-btn-" + newElement.dataset.type + "-" + newIndex);
      if (showDetailsBtn) {
        showDetailsBtn.click();
        showDetailsBtn.focus();
      }
    }
  };
  useEffect(() => {
    const relationship = UserService.getCurrentUserRelationship();
    setIsCurrentUserIndividual(relationship === "Self");

    document.body.classList.add("sticky");
    document.addEventListener("keydown", initArrowKeys);
    var allSubcategoryTab = document.getElementById("dashboard-all-tab");
    if (allSubcategoryTab) {
      allSubcategoryTab.click();
    }
    return () => {
      document.removeEventListener("keydown", initArrowKeys);
      document.body.classList.remove("sticky");
    };
  }, []);

  useEffect(() => {
    const fetchSubcategoriesData = async () => {
      var subcategories = await SubcategoryService.getAllSubcategoriesWithFilters();
      filtersStore.setInitialFilters(subcategories);
      const pagingParams = subcategories.map((s) => ({ id: s.id, page: 0, pageSize: 10, hasMore: true }));
      pagingParams.unshift({ id: "all", page: 0, pageSize: 10, hasMore: true });
      setSubcategoriesPagingParams(pagingParams);
      setActiveSubcategoryId("all");
    };
    fetchSubcategoriesData();
    initChoices();
  }, []);

  useEffect(() => {
    searchStore.clearSearch();
  }, [searchStore]);

  useEffect(() => {
    setIsLoading(true);
    const fetchProductsData = async () => {
      const isSearch = searchStore.searchTerm && searchStore.searchTerm.length > 0;
      if (isSearch) {
        setProducts([]);
      }
      const isAllCategory = activeSubcategoryId === "all" && !isSearch;
      const filtersParams = activeSubcategoryId && !isSearch ? filtersStore.getFiltersParams(activeSubcategoryId) : null;
      const data = await ProductService.getByParameters({
        subcategoryId: !isAllCategory && !isSearch ? activeSubcategoryId : null,
        attributeFilters: filtersParams ? filtersParams.attributeFilters : {},
        manufacturerIdList: filtersParams ? filtersParams.manufacturerIdList : [],
        brandLineIdList: filtersParams ? filtersParams.brandLineIdList : [],
        searchParam: isSearch ? searchStore.searchTerm.join(" ") : null,
        orderBy: sortStore.productsSortBy,
        page: 0,
        pageSize: 10,
        retrieveAll: isSearch,
      });
      if (isSearch || !isAllCategory) {
        setProducts(data.resultList);
      } else if (isAllCategory) {
        setAllProducts(data.resultList);
      }
      setIsLoading(false);
      if (subcategoriesPagingParams && subcategoriesPagingParams.length > 0) {
        subcategoriesPagingParams.forEach((spp) => {
          spp.page = 0;
          spp.pageSize = 10;
          spp.hasMore = true;
        });
        setSubcategoriesPagingParams(subcategoriesPagingParams);
      }

      setLoadedSubcategories([]);
    };
    fetchProductsData();
  }, [searchStore.searchTerm, sortStore.productsSortBy, filtersStore.changed]);

  const getProductsBySubcategoryId = (subcategoryId) => {
    var changed = filtersStore.changed;
    if (changed < 0) return;

    var productsByType = products ? products.filter((p) => p.subcategoryId === subcategoryId) : [];

    return productsByType;
  };

  const loadProducts = async (subcategoryId) => {
    setActiveSubcategoryId(subcategoryId);
    setIsLoading(true);

    if (subcategoryId !== "all") {
      setLoadedSubcategories((previousLoadedSubcategories) => [...previousLoadedSubcategories, subcategoryId]);
    }

    const filtersParams = filtersStore.getFiltersParams(subcategoryId);
    const data = await ProductService.getByParameters({
      subcategoryId: subcategoryId !== "all" ? subcategoryId : null,
      attributeFilters: filtersParams.attributeFilters,
      manufacturerIdList: filtersParams.manufacturerIdList,
      brandLineIdList: filtersParams.brandLineIdList,
      orderBy: sortStore.productsSortBy,
      page: 0,
      pageSize: 10,
    });
    if (subcategoryId !== "all") {
      const refreshedProducts = products ? products.filter((p) => p.subcategoryId !== subcategoryId) : [];
      refreshedProducts.push(...data.resultList);
      setProducts(refreshedProducts);
      setIsLoading(false);
      setActiveSubcategoryId(subcategoryId);
    } else {
      setAllProducts(data.resultList);
      setIsLoading(false);
      setIsAllLoaded(true);
    }

    const subcategoryPagingParamsIndex = subcategoriesPagingParams.findIndex((f) => f.id === subcategoryId);
    if (subcategoryPagingParamsIndex !== -1) {
      subcategoriesPagingParams[subcategoryPagingParamsIndex].page = 0;
      subcategoriesPagingParams[subcategoryPagingParamsIndex].pageSize = 10;
      subcategoriesPagingParams[subcategoryPagingParamsIndex].hasMore = data.hasMore;
      setSubcategoriesPagingParams(subcategoriesPagingParams);
    }
  };

  const addToCart = (id, quantity = 1, overwrite = false, showSuccessMsg = false) => {
    if (isNaN(quantity)) return;

    const indexProducts = products ? products.findIndex((p) => p.id === id) : -1;
    const indexAllProducts = allProducts ? allProducts.findIndex((p) => p.id === id) : -1;

    if (indexProducts !== -1 || indexAllProducts !== -1) {
      const product = indexProducts !== -1 ? products[indexProducts] : allProducts[indexAllProducts];

      if (quantity === 0) {
        cartStore.removeProduct(product.id, product.package.id, quantity);
      } else {
        cartStore.addProduct(
          product,
          {
            ...product.package,
            unitPrice: product.unitPrice,
            price: product.price,
          },
          !isNaN(quantity) && quantity > 0 ? quantity : 0,
          overwrite
        );

        if (showSuccessMsg) {
          toast.success(`You have successfuly added ${product.primaryAttributes[0].value} · ${product.primaryAttributes[1].value} · ${product.primaryAttributes[2].value} to cart.`, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
  };

  const removeFromCart = (id, packageId, quantity = 1) => {
    cartStore.removeProduct(id, packageId, quantity);
  };

  const itemCartQuantity = (id, packageId) => {
    return cartStore.getQuantity(id, packageId);
  };

  const isBudgetReached = (price) => {
    return cartStore.isEnteredBudgetLimit && cartStore.budget && cartStore.totalPrice + price >= cartStore.budget;
  };

  const onSelectedItem = (id, addToSearchHistory = false) => {
    const indexProducts = products ? products.findIndex((p) => p.id === id) : -1;
    const indexAllProducts = allProducts ? allProducts.findIndex((p) => p.id === id) : -1;
    const indexSearchHistoryProducts = searchStore.searchHistoryProducts ? searchStore.searchHistoryProducts.findIndex((p) => p.id === id) : -1;

    if (indexProducts !== -1 || indexAllProducts !== -1) {
      var selectedEl = document.getElementsByClassName("selected");
      if (selectedEl && selectedEl.length > 0 && parseInt(selectedEl[0].dataset.index) !== (indexProducts !== -1 ? indexProducts : indexAllProducts)) {
        selectedEl[0].classList.remove("selected");
      }
      const selectedProduct = indexProducts !== -1 ? products[indexProducts] : allProducts[indexAllProducts];
      setSelectedItem(selectedProduct);
      showFlyoutCard();

      if (addToSearchHistory) {
        searchStore.addToSearchHistory(selectedProduct);
      }
    }

    if (indexSearchHistoryProducts !== -1) {
      const selectedProduct = searchStore.searchHistoryProducts[indexSearchHistoryProducts];
      setSelectedItem(selectedProduct);
      showFlyoutCard();
    }
  };

  useEffect(() => {
    onSelectedItem(searchStore.selectedProductId);
  }, [searchStore.selectedProductId]);

  const onScroll = async (e, subcategoryId) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop > lastScrollTop) {
      document.body.classList.remove("scroll-up");
      document.body.classList.add("scroll-down");
    } else {
      document.body.classList.remove("scroll-down");
      document.body.classList.add("scroll-up");
    }
    setLastScrollTop(scrollTop);
    if (scrollHeight - (scrollTop + clientHeight) < 200) {
      const index = subcategoriesPagingParams.findIndex((f) => f.id === subcategoryId);
      if (index !== -1 && subcategoriesPagingParams[index].hasMore) {
        const newPage = subcategoriesPagingParams[index].page + 1;
        const isAllCategory = subcategoriesPagingParams[index].id === "all";
        const filtersParams = filtersStore.getFiltersParams(subcategoriesPagingParams[index].id);
        const data = await ProductService.getByParameters({
          subcategoryId: !isAllCategory ? subcategoriesPagingParams[index].id : null,
          attributeFilters: filtersParams ? filtersParams.attributeFilters : {},
          manufacturerIdList: filtersParams ? filtersParams.manufacturerIdList : [],
          brandLineIdList: filtersParams ? filtersParams.brandLineIdList : [],
          searchParam: searchStore.searchTerm && searchStore.searchTerm.length > 0 ? searchStore.searchTerm.join(" ") : null,
          orderBy: sortStore.productsSortBy,
          page: newPage,
          pageSize: subcategoriesPagingParams[index].pageSize,
        });

        subcategoriesPagingParams[index].hasMore = data.hasMore;
        setSubcategoriesPagingParams(subcategoriesPagingParams);

        if (data.resultList.length === 0) return;

        subcategoriesPagingParams[index].page = newPage;
        setSubcategoriesPagingParams(subcategoriesPagingParams);

        if (isAllCategory) {
          setAllProducts([...allProducts, ...data.resultList]);
          setIsAllLoaded(true);
        } else {
          setProducts([...products, ...data.resultList]);
          const subcategoryLoadedSubcategoriesIndex = loadedSubcategories ? loadedSubcategories.findIndex((ls) => ls === subcategoryId) : -1;
          if (subcategoryLoadedSubcategoriesIndex === -1) {
            setLoadedSubcategories((previousLoadedSubcategories) => [...previousLoadedSubcategories, subcategoryId]);
          }
        }
      }
    }
    setIsLoadingMore(false);
  };

  const onScrollCapture = () => {
    const index = subcategoriesPagingParams.findIndex((f) => f.id === activeSubcategoryId);
    setIsLoadingMore(index !== -1 && subcategoriesPagingParams[index].hasMore);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="col d-xl-flex flex-column border-end overflow-hidden">
        <div className={`${!searchStore.searchTerm || searchStore.searchTerm.length === 0 ? "nav-sticky bg-white border-bottom" : "content"}`}>
          {searchStore.searchTerm && searchStore.searchTerm.length > 0 && (
            <div className="py-xl-3">
              <div className="pb-3">
                <div className="container-fluid mt-5 mt-md-1">
                  <h1 className="mb-3 fs-4">{!isLoading && products && products.length ? `Search results (${products.length}):` : ""}</h1>
                  {isLoading && (
                    <div className="position-absolute top-50 start-50 translate-middle">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                        style={{
                          "--bs-spinner-border-width": "0.15em",
                        }}
                      ></div>
                    </div>
                  )}
                  {!isLoading && products && products.length === 0 && (
                    <div className="position-absolute top-50 start-50 translate-middle">
                      <h5 className="fw-normal text-muted mb-0">No products to show.</h5>
                    </div>
                  )}
                  <div className={productsStore.layout === "card" ? "row row-cols-2 row-cols-xxl-3 g-0 g-lg-2" : "row g-lg-2"}>
                    {!isLoading &&
                      products &&
                      products.map((item, i) => (
                        <ProductItem
                          key={i}
                          item={item}
                          index={i}
                          layout={productsStore.layout}
                          subcategory={"search"}
                          selectedItem={selectedItem}
                          searchTerm={searchStore.searchTerm}
                          itemCartQuantity={itemCartQuantity}
                          addToCart={addToCart}
                          removeFromCart={removeFromCart}
                          isBudgetReached={isBudgetReached}
                          isIndividualUser={isCurrentUserIndividual}
                          onSelectedItem={onSelectedItem}
                        ></ProductItem>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="container pe-0">
            {(!searchStore.searchTerm || searchStore.searchTerm.length === 0) && (
              <ul className="nav nav-overflow mt-1">
                <li className="nav-item">
                  <a
                    className={`nav-link p-0 me-1 ${activeSubcategoryId === "all" ? "active" : ""}`}
                    id="dashboard-all-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#dashboard-all-tab-pane"
                    role="tab"
                    aria-controls="dashboard-all-tab-pane"
                    aria-selected="true"
                    href="#"
                    onClick={() => loadProducts("all")}
                  >
                    <span className="badge py-1 px-2 fs-8 text-body bg-white border border-nobel rounded-pill">All</span>
                  </a>
                </li>

                {(!searchStore.searchTerm || searchStore.searchTerm.length === 0) &&
                  filtersStore.filters &&
                  filtersStore.filters
                    .filter((s) => s.anyFilterSelected)
                    .map((subcategory, i) => (
                      <li className="nav-item" key={i}>
                        <a
                          className={`nav-link p-0 me-1 ${activeSubcategoryId === subcategory.id ? "active" : ""}`}
                          id={`#dashboard-${subcategory.name}-${subcategory.id}-tab`}
                          data-bs-toggle="tab"
                          data-bs-target={`#dashboard-${subcategory.name}-${subcategory.id}-tab-pane`}
                          role="tab"
                          aria-controls={`#dashboard-${subcategory.name}-${subcategory.id}-tab-pane`}
                          aria-selected="false"
                          href="#"
                          onClick={() => loadProducts(subcategory.id)}
                        >
                          <span className="badge py-1 px-2 fs-8 text-body bg-white border border-nobel rounded-pill">{subcategory.name}</span>
                        </a>
                      </li>
                    ))}
              </ul>
            )}
          </div>
        </div>
        <div className="tab-content tab-content-sticky" id="dashboard-tab-content" style={{ display: !searchStore.searchTerm || searchStore.searchTerm.length === 0 ? "block" : "none" }}>
          <div className={`tab-pane ${activeSubcategoryId === "all" ? "show active" : ""}`} id="dashboard-all-tab-pane" role="tabpanel" aria-labelledby="dashboard-all-tab" tabIndex="0">
            <div
              className="content py-xl-3"
              onScrollCapture={onScrollCapture}
              onScroll={debounce((e) => {
                onScroll(e, "all");
              }, 500)}
            >
              <div className="container-fluid">
                {isLoading && (
                  <div className="position-absolute top-50 start-50 translate-middle">
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      style={{
                        "--bs-spinner-border-width": "0.15em",
                      }}
                    ></div>
                  </div>
                )}

                <div className={productsStore.layout === "card" ? "row row-cols-2 row-cols-xxl-3 g-0 g-lg-2" : "row g-lg-2"}>
                  {!isLoading &&
                    allProducts &&
                    allProducts.map((item, i) => (
                      <ProductItem
                        key={i}
                        item={item}
                        index={i}
                        layout={productsStore.layout}
                        subcategory={"all-products"}
                        selectedItem={selectedItem}
                        searchTerm={searchStore.searchTerm}
                        itemCartQuantity={itemCartQuantity}
                        addToCart={addToCart}
                        removeFromCart={removeFromCart}
                        isBudgetReached={isBudgetReached}
                        isIndividualUser={isCurrentUserIndividual}
                        onSelectedItem={onSelectedItem}
                      ></ProductItem>
                    ))}
                </div>
                {isLoadingMore && (
                  <div className="text-center pt-4 pb-4">
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      style={{
                        "--bs-spinner-border-width": "0.15em",
                      }}
                    ></div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {filtersStore.filters &&
            filtersStore.filters.map((subcategory, i) => (
              <div
                key={i}
                className={`tab-pane ${activeSubcategoryId === subcategory.id ? "show active" : ""}`}
                id={`#dashboard-${subcategory.name}-${subcategory.id}-tab-pane`}
                role="tabpanel"
                aria-labelledby={`#dashboard-${subcategory.name}-${subcategory.id}-tab`}
                tabIndex={i + 1}
              >
                <div
                  className="content py-xl-3"
                  onScrollCapture={onScrollCapture}
                  onScroll={debounce((e) => {
                    onScroll(e, subcategory.id);
                  }, 500)}
                >
                  <div className="container-fluid">
                    {isLoading && (
                      <div className="position-absolute top-50 start-50 translate-middle">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                          style={{
                            "--bs-spinner-border-width": "0.15em",
                          }}
                        ></div>
                      </div>
                    )}
                    {!isLoading && getProductsBySubcategoryId(subcategory.id).length === 0 && (
                      <div className="d-flex align-items-center  w-100 h-100">
                        <div className="container-fluid">
                          <h5 className="fw-normal text-muted mb-0">No products to show.</h5>
                        </div>
                      </div>
                    )}

                    <div className={productsStore.layout === "card" ? "row row-cols-2 row-cols-xxl-3 g-0 g-lg-2" : "row g-1 g-lg-2"}>
                      {!isLoading &&
                        getProductsBySubcategoryId(subcategory.id).map((item, i) => (
                          <ProductItem
                            key={i}
                            item={item}
                            index={i}
                            layout={productsStore.layout}
                            subcategory={subcategory.name}
                            selectedItem={selectedItem}
                            searchTerm={searchStore.searchTerm}
                            itemCartQuantity={itemCartQuantity}
                            addToCart={addToCart}
                            removeFromCart={removeFromCart}
                            isBudgetReached={isBudgetReached}
                            isIndividualUser={isCurrentUserIndividual}
                            onSelectedItem={onSelectedItem}
                          ></ProductItem>
                        ))}
                    </div>
                    {isLoadingMore && (
                      <div className="text-center pt-4 pb-4">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                          style={{
                            "--bs-spinner-border-width": "0.15em",
                          }}
                        ></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="col d-flex overflow-hidden flyout" id="flyout">
        {!selectedItem && (
          <div className="content">
            <div className="d-flex align-items-center text-center w-100 h-100">
              <div className="container-fluid">
                <h5 className="mb-1 text-muted">Please choose a product</h5>
                <h5 className="mb-0 text-muted">you would like to add to cart.</h5>
              </div>
            </div>
          </div>
        )}

        {selectedItem && <ProductPreview
          key={selectedItem.id}
          id={selectedItem.id}
          purpose={selectedItem.purpose}
          packageImageUrl={selectedItem.package.imageUrl}
        ></ProductPreview>}
      </div>
    </React.Fragment>
  );
});

export default HomeView;
