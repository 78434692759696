import React from "react";
import "./utils/dropConsole";
import { BrowserRouter } from "react-router-dom";
import { RouterConfig } from "./navigation/RouterConfig";
import "./App.css";
import { ProvideAuth } from "./navigation/Auth/ProvideAuth";
import ModalRoot from "./pages/_Layout/Modal/ModalRoot";

function App() {
  return (
    <>
      <div>
        <ProvideAuth>
          <BrowserRouter>
            <ModalRoot />
            <RouterConfig />
          </BrowserRouter>
        </ProvideAuth>
      </div>
    </>
  );
}

export default App;
