import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { handleValidatePhoneNumber, handleValidateEmail } from "../../../utils/regex";

const SignInMain = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const location = useLocation();

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(props.onSubmitStep1)}>
        {location.state && location.state.firstName && location.state.lastName && (
          <p className="alert mb-3 alert-success">
            <h6 className="alert-heading">You have successfully signed up!</h6>
            <p className="mb-0 fs-7">You can sign in after you can confirm your account by following instructions in the email we just sent you.</p>
          </p>
        )}

        {location.state && location.state.unauthorized && (
          <p className="alert mb-3 alert-danger">
            {!location.state.errorMessage && (
              <>
                <h6 className="alert-heading">You are not authorized to access the platform.</h6>
                <p className="mb-0 fs-7">Only verified military or first responders are authorized for platform access.</p>
              </>
            )}
            {location.state.errorMessage && <p className="mb-0 fs-7">{location.state.errorMessage}</p>}
          </p>
        )}
        <div className="my-auto">
          <div className="mb-0">
            <label className="form-label fw-normal" htmlFor="email">
              Sign in with <span className="fw-bold">User ID</span>
            </label>
            <div className={`input-group input-group-merge input-group-reverse ${errors.email || (props.errors && props.errors.isValidUserServerMessage) ? "" : "mb-3"}`}>
              <input
                className={`form-control form-control-lg ${errors.email ? "border-danger" : ""}`}
                id="email"
                placeholder="Your email address or phone number"
                aria-label="Your email address or phone number"
                aria-describedby="email-at"
                {...register("email", {
                  required: "Email address or phone number is required.",
                  validate: (value) => {
                    const isPhoneNumber = handleValidatePhoneNumber(value && value.trim());
                    const isEmail = handleValidateEmail(value && value.trim());
                    if (!isPhoneNumber && !isEmail) {
                      return "You must enter either an email or a phone number";
                    }
                  },
                })}
                onKeyUp={() => props.clearCustomErrors()}
              />
              <div id="email-at" className={`input-group-text ${errors.email ? "border-danger" : ""}`}>
                <i className="fe fe-at-sign"></i>
              </div>
            </div>
            {errors.email && <small className="text-danger mb-3">{errors.email.message}</small>}
            {props.errors && props.errors.isValidUserServerMessage && !errors.email && <small className="text-danger mb-3">{props.errors.isValidUserServerMessage}</small>}
          </div>
        </div>
        <div className="mt-4">
          <div className="mb-3">
            <button className="btn btn-lg btn-primary w-100" type="submit" disabled={props.isLoading}>
              {props.isLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
              &nbsp; Next
            </button>
          </div>
          <div className="mb-3 text-center">
            <p className="or mb-0 text-muted">OR</p>
          </div>
          <div className="mb-2">
            <a
              className="btn btn-lg btn-light w-100"
              href="https://groups.id.me/?&scopes=employee,military,responder&client_id=8db943d46abac36c750b89d332696805&redirect_uri=https://ndss.emaq.ba/sign-up&response_type=code&type=button&logo=https://ndss.emaq.ba/assets/img/brand/logo-sm.png&hero=https://ndss.emaq.ba/assets/img/brand/logo.png"
            >
              Create an account
            </a>
          </div>
          {/* <p className="fs-8 mb-0 text-black-50 text-center">
            Verification by ID.me •{" "}
            <a href="https://www.id.me/about" target="_blank">
              What is ID.me?
            </a>
          </p> */}
        </div>
      </form>
    </React.Fragment>
  );
};

export default SignInMain;
