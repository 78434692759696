import { makeAutoObservable } from "mobx";
import UserService from "../services/UserService";

export class ProductsStore {
  layout: string = "row";

  constructor() {
    this.setInitialValue();
    makeAutoObservable(this);
  }

  toggleLayout() {
    this.layout = this.layout ? (this.layout === "card" ? "row" : "card") : "row";
    localStorage.setItem("layout", this.layout);
  }

  setInitialValue() {
    var layout = localStorage.getItem("layout");
    if (layout) {
      this.layout = layout;
    } else {
      var relationship = UserService.getCurrentUserRelationship();
      if (relationship) {
        this.layout = relationship === "Self" ? "card" : "row";
      }
    }
  }
}

export default new ProductsStore();
