export function initCart() {
  const cart = document.getElementById("cart-collapse");
  const backdrop = document.getElementById("cart-backdrop");

  cart.addEventListener("show.bs.collapse", () => {
    backdrop.classList.add("show");
  });

  cart.addEventListener("hide.bs.collapse", () => {
    backdrop.classList.remove("show");
  });
}

export function toggleCart() {
  const cart = document.getElementById("cart");

  if (cart) {
    cart.click();
  }
}
