import PropTypes from "prop-types";
import ReactDOM from "react-dom";
const Modal = ({ id, children, size = "" }) => {
  return ReactDOM.createPortal(
    <div
      className={`modal fade ${size}`}
      id={id}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-card card mb-0">{children}</div>
        </div>
      </div>
    </div>,
    document.getElementById("modal")
  );
};

const closeActiveModal = () => {
  const closeButtons = document.querySelectorAll("[data-bs-dismiss='modal']");
  if (closeButtons) {
    closeButtons.forEach((cb) => cb.click());
  }
};

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
};

export { Modal, closeActiveModal };
