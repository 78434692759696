import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { handleValidateEmail } from "../../utils/regex";
import UserService from "../../services/UserService";
import PasswordInput from "../../components/PasswordInput";

export function ForgotPasswordView(props) {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm({ reValidateMode: "onChange" });
  const navigate = useNavigate();
  const location = useLocation();
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (location.state && location.state.email) {
      setValue("email", location.state.email);
    }
    const email = searchParams.get("email");
    const code = searchParams.get("code");
    if (email && code) {
      const validateEmailAndCode = async () => {
        const isValid = await UserService.checkForgotPasswordResetCode(email, code);
        if (isValid) {
          setValue("email", email);
          setValue("code", code);
          setStep(3);
        } else {
          navigate("/sign-in", { state: { updatedPassword: false, message: "Code is invalid or have been expired!", email: email } });
        }
      };
      validateEmailAndCode();
    }
  }, [searchParams]);

  const onSubmit = async (data) => {
    if (step === 1) {
      setIsLoading(true);
      const userIdentifierData = await UserService.sendForgotPasswordInstructions(data.email);
      setIsLoading(false);
      if (userIdentifierData.resultList) {
        setError("email", { message: userIdentifierData.resultList[0].description });
        return;
      }
      setStep(2);
    } else if (step === 2) {
      setStep(3);
    } else if (step === 3) {
      setIsLoading(true);
      const updated = await UserService.updatePassword(data.email, data.code, data.password, data.passwordConfirmation);
      setIsLoading(false);
      if (updated) {
        navigate("/sign-in", { state: { updatedPassword: true, message: "Your password has been changed successfully.", email: data.email } });
      }
    }
  };

  return (
    <div className="d-flex align-items-center min-vh-100 bg-pattern">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-7 col-xxl-9">
            <div className="card card-lg">
              <div className="card-body p-0">
                <div className="row g-0">
                  <div className="col-12 col-md-6 border-end border-light">
                    <div className="d-flex flex-column h-100 px-4 py-5">
                      <div className="text-center mb-4">
                        <img className="d-inline-block logo" src="./assets/img/brand/logo.png" alt="" />
                      </div>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        {step === 1 && (
                          <>
                            <div className="mb-4">
                              <h1 className="mb-1 fs-2">Forgot Password</h1>
                              <p className="mb-0 text-muted">Enter your email address and we'll send you an email with instructions to reset your password.</p>
                            </div>
                            <div className="my-auto">
                              <div className="mb-0">
                                <label className="form-label" htmlFor="email">
                                  Email address
                                </label>
                                <div className={`input-group input-group-merge input-group-reverse ${errors.email ? "" : "mb-3"}`}>
                                  <input
                                    className={`form-control form-control-lg ${errors.email ? "border-danger" : ""}`}
                                    id="email"
                                    placeholder="Your email address"
                                    aria-label="Your email address"
                                    aria-describedby="email-at"
                                    {...register("email", {
                                      required: "Email address is required.",
                                      validate: (value) => {
                                        const isEmail = handleValidateEmail(value && value.trim());
                                        if (!isEmail) {
                                          return "You must enter an email.";
                                        }
                                      },
                                    })}
                                  />
                                  <div className={`input-group-text ${errors.email ? "border-danger" : ""}`} id="email-at">
                                    <i className="fe fe-at-sign"></i>
                                  </div>
                                </div>
                              </div>
                              {errors.email && <small className="text-danger mb-3">{errors.email.message}</small>}
                            </div>
                          </>
                        )}
                        {step === 2 && (
                          <>
                            <div className="mb-4">
                              <h1 className="mb-1 fs-2">Forgot Password</h1>
                              <p className="mb-0 text-muted">Enter code we've sent you to your e-mail address / phone number.</p>
                            </div>
                            <div className="my-auto">
                              <div className="mb-0">
                                <label className="form-label" htmlFor="code">
                                  Code
                                </label>
                                <div className={`input-group input-group-merge input-group-reverse ${errors.code ? "" : "mb-3"}`}>
                                  <input
                                    className={`form-control form-control-lg ${errors.code ? "border-danger" : ""}`}
                                    id="code"
                                    placeholder="Code"
                                    aria-label="Code"
                                    aria-describedby="email-at"
                                    {...register("code", {
                                      required: "Code is required.",
                                      validate: async (value) => {
                                        setIsLoading(true);
                                        const isValid = await UserService.checkForgotPasswordResetCode(getValues("email"), value);
                                        setIsLoading(false);
                                        if (!isValid) {
                                          return "Code is invalid or have been expired!";
                                        }
                                      },
                                    })}
                                  />
                                  <div className={`input-group-text ${errors.code ? "border-danger" : ""}`} id="email-at">
                                    <i className="fe fe-lock"></i>
                                  </div>
                                </div>
                              </div>
                              {errors.code && <small className="text-danger mb-3">{errors.code.message}</small>}
                            </div>
                          </>
                        )}
                        {step === 3 && (
                          <>
                            <div className="mb-4">
                              <h1 className="mb-1 fs-2">Forgot Password</h1>
                              <p className="mb-0 text-muted">Please enter a new password for your account.</p>
                            </div>
                            <div className="my-auto">
                              <div className="mb-2">
                                <label className="form-label" htmlFor="password">
                                  New password
                                </label>
                                <PasswordInput
                                  id="password"
                                  name="password"
                                  autoComplete="off"
                                  placeholder="Your new password"
                                  inputGroupClassName={errors.password ? "" : "mb-3"}
                                  iconClassName={errors.password && "border-danger text-danger"}
                                  inputClassName={errors.password && "border-danger"}
                                  formOptions={register("password", {
                                    required: "New password is required.",
                                    minLength: {
                                      value: 8,
                                      message: "New password should be at least 8 characters.",
                                    },
                                  })}
                                />
                                {errors.password && <small className="text-danger mb-3">{errors.password.message}</small>}
                              </div>
                              {errors.code && <small className="text-danger mb-3">{errors.code.message}</small>}
                            </div>
                            <div className="my-auto">
                              <div className="mb-0">
                                <label className="form-label" htmlFor="passwordConfirmation">
                                  New password confirmation
                                </label>
                                <PasswordInput
                                  id="passwordConfirmation"
                                  name="passwordConfirmation"
                                  autoComplete="off"
                                  placeholder="Confirm new password"
                                  inputGroupClassName={errors.passwordConfirmation ? "" : "mb-3"}
                                  iconClassName={errors.passwordConfirmation && "border-danger text-danger"}
                                  inputClassName={errors.passwordConfirmation && "border-danger"}
                                  formOptions={register("passwordConfirmation", {
                                    required: "New password confirmation is required.",
                                    validate: (value) => {
                                      if (watch("password") !== value) {
                                        return "Your passwords do not match.";
                                      }
                                    },
                                  })}
                                />
                                {errors.passwordConfirmation && <small className="text-danger mb-3">{errors.passwordConfirmation.message}</small>}
                              </div>
                            </div>
                          </>
                        )}
                        <div className="mt-4">
                          <div className="mb-3">
                            <button className="btn btn-lg btn-primary w-100" type="submit" disabled={isLoading}>
                              {isLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                              &nbsp; {step !== 3 ? <>Next</> : <>Complete</>}
                            </button>
                          </div>
                          <div className="mb-3 text-center">
                            <p className="or mb-0 text-muted">OR</p>
                          </div>
                          <div className="mb-0">
                            <button className="btn btn-lg btn-white w-100" onClick={() => navigate("/sign-in")} type="button">
                              Back to Sign In
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 d-none d-md-flex">
                    <div id="onboarding-carousel" className="carousel slide carousel-fade w-100 h-100" data-bs-ride="carousel" data-bs-interval="5000">
                      <div className="carousel-indicators">
                        <button className="active" type="button" data-bs-target="#onboarding-carousel" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#onboarding-carousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#onboarding-carousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                      </div>
                      <div className="carousel-inner w-100 h-100">
                        <div
                          className="carousel-item active w-100 h-100 bg-cover"
                          style={{
                            backgroundImage: "url(./assets/img/carousel/sidebar.png)",
                          }}
                          alt=""
                        >
                          <div className="carousel-caption">
                            <h5>Closed platform</h5>
                            <p className="mb-0">
                              Access is granted only to federal, state, and local law enforcement agencies, individual officers, reserve officers and affiliated government agencies.
                            </p>
                            <OverlayTrigger
                              trigger="click"
                              placement="top"
                              overlay={
                                <Tooltip>
                                  All those whom has access are US military, individual military personnel, retired military with retired id, retired law enforcement, DHS/TSA employees, licensed
                                  security officers, affiliated training facilities, FFL dealers, firefighters, EMS, corrections, prosecutors, and district attorneys.
                                </Tooltip>
                              }
                            >
                              <a className="text-info" href="#!">
                                Learn more
                              </a>
                            </OverlayTrigger>
                          </div>
                        </div>
                        <div
                          className="carousel-item w-100 h-100 bg-cover"
                          style={{
                            backgroundImage: "url(./assets/img/carousel/sidebar.png)",
                          }}
                          alt=""
                        >
                          <div className="carousel-caption">
                            <h5>Best selection of ammo</h5>
                            <p className="mb-0">
                              {" "}
                              Variety of all popular calibers of Training and Duty ammo for handgun, rifle, shotgun and rimfire.Over 400 most popular products with manufacturer’s pricing, shipped
                              immediately.
                            </p>
                          </div>
                        </div>
                        <div
                          className="carousel-item w-100 h-100 bg-cover"
                          style={{
                            backgroundImage: "url(./assets/img/carousel/sidebar.png)",
                          }}
                          alt=""
                        >
                          <div className="carousel-caption">
                            <h5>America's first National Defence platform </h5>
                            <p className="mb-0"> Secured supply service for law enforcement and military.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
