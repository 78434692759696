import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
} from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

const ReportsView = observer(() => {
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title
  );

  const [data, setData] = useState();

  useEffect(() => {
    setData({
      "orders": [
        {
          "count": 31,
          "label": "Processing"
        },
        {
          "count": 61,
          "label": "Approved"
        },
        {
          "count": 82,
          "label": "Quoted"
        },
        {
          "count": 12,
          "label": "Shipped"
        },
        {
          "count": 88,
          "label": "New"
        }
      ],
      "subcategories": [
        {
          "count": 5151,
          "label": "Handgun"
        },
        {
          "count": 3242,
          "label": "Rifle"
        },
        {
          "count": 1212,
          "label": "Shotgun"
        }
      ],
      "products": [
        {
          "count": 89,
          "label": "9mm Luger • 117 • HP"
        },
        {
          "count": 75,
          "label": "9mm Luger • 115 • FMJ"
        },
        {
          "count": 67,
          "label": "9mm Luger • 124 • FMJ"
        },
        {
          "count": 54,
          "label": "9mm Luger • 147 • FMJ"
        },
        {
          "count": 49,
          "label": "223 Rem • 55 • GDSP"
        },
        {
          "count": 41,
          "label": "308 Win • 168 • TBT"
        }
      ],
      "calibers": [
        {
          "count": 236,
          "label": "9MM LUGER"
        },
        {
          "count": 164,
          "label": "223 REM"
        },
        {
          "count": 112,
          "label": "257 MAGNUM"
        },
        {
          "count": 77,
          "label": "45 ACP AUTO"
        },
        {
          "count": 52,
          "label": "40 S&W"
        },
        {
          "count": 44,
          "label": "10MM AUTO"
        },
        {
          "count": 32,
          "label": "5.56 NATO"
        },
        {
          "count": 11,
          "label": "7.62 NATO"
        }
      ],
      "totalOrders": 214,
      "totalRounds": 5621
    });

    document.body.classList.remove("sticky");
    return () => {
      document.body.classList.add("sticky");
    };
  }, []);

  const pieOptions = {
    plugins: {
      legend: {
        display: false
      }
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  const ordersPieBarColors = data?.orders ? generateColors(data.orders) : [];
  const ordersPieData = {
    labels: data?.orders?.map(o => o.label) ?? [],
    datasets: [
      {
        label: 'Count',
        data: data?.orders?.map(o => o.count) ?? [],
        backgroundColor: ordersPieBarColors,
        borderWidth: 0,
      },
    ],
  };

  const subcategoriesPieBarColors = data?.subcategories ? generateColors(data.subcategories) : [];
  const subcategoriesPieData = {
    labels: data?.subcategories?.map(o => o.label) ?? [],
    datasets: [
      {
        label: 'Count',
        data: data?.subcategories?.map(o => o.count) ?? [],
        backgroundColor: subcategoriesPieBarColors,
        borderWidth: 0,
      },
    ],
  };

  const barOptions = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    plugins: {
      legend: { display: false },
      title: { display: false },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  const calibersBarColors = data?.calibers ? generateColors(data.calibers) : [];
  const calibersBarData = {
    labels: data?.calibers?.map(o => o.label) ?? [],
    datasets: [{
      label: 'Count',
      data: data?.calibers?.map(o => o.count) ?? [],
      backgroundColor: calibersBarColors,
      borderWidth: 0,
    }],
  };

  const productsBarColors = data?.products ? generateColors(data.products) : [];
  const productsBarData = {
    labels: data?.products?.map(o => o.label) ?? [],
    datasets: [{
      label: 'Count',
      data: data?.products?.map(o => o.count) ?? [],
      backgroundColor: productsBarColors,
      borderWidth: 0,
    }],
  };

  return (
    <React.Fragment>
      <div className="container mt-xl-3">
        <div className="row">
          <div className="col-md-3">
            <div className="row">
              <div className="col-12">
                <div className="card bg-primary text-white">
                  <div className="card-body">
                    <div class="row align-items-center">
                      <div className="col">
                        <span className="d-block fs-4">Total orders</span>
                        <span className="d-block text-white-50 fs-8">Total number of orders created</span>
                      </div>
                      {<div className="col-auto">
                        <span className="box bg-white bg-opacity-25 rounded-circle text-center text-white fw-bold pt-2 fs-4" style={{ width: "4rem", height: "4rem" }}>
                          {data?.totalOrders ?? 0}
                        </span>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title fs-4">Orders</h5>

                    <div style={{ height: "250px" }}>
                      <Pie data={ordersPieData} options={pieOptions} />
                    </div>

                    {data?.orders && <div className="mt-3">
                      {data.orders.map((o, i) => {
                        return <div className={`card ${i == data.orders.length - 1 ? "mb-0" : "mb-1"}`}>
                          <div className="card-body py-1 px-2">
                            <div className="row align-items-center">
                              <div className="col-auto">
                                <div style={{
                                  width: "20px",
                                  height: "20px",
                                  background: ordersPieBarColors[i],
                                  borderRadius: "50%"
                                }}>
                                </div>
                              </div>
                              <div className="col">{o.label}</div>
                              <div className="col-auto">
                                <strong>{o.count}</strong>
                              </div>
                            </div>
                          </div>
                        </div>;
                      })}
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title fs-4">Calibers</h5>
                    <div style={{ height: "350px" }}>
                      <Bar data={calibersBarData} options={barOptions} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title fs-4">Products</h5>
                    <div style={{ height: "350px" }}>
                      <Bar data={productsBarData} options={barOptions} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title fs-4">Subcategories</h5>

                    <div style={{ height: "250px" }}>
                      <Pie data={subcategoriesPieData} options={pieOptions} />
                    </div>

                    {data?.subcategories && <div className="mt-3">
                      {data.subcategories.map((o, i) => {
                        return <div className={`card ${i == data.orders.length - 1 ? "mb-0" : "mb-1"}`}>
                          <div className="card-body py-1 px-2">
                            <div className="row align-items-center">
                              <div className="col-auto">
                                <div style={{
                                  width: "20px",
                                  height: "20px",
                                  background: subcategoriesPieBarColors[i],
                                  borderRadius: "50%"
                                }}>
                                </div>
                              </div>
                              <div className="col">{o.label}</div>
                              <div className="col-auto">
                                <strong>{o.count}</strong> rnds
                              </div>
                            </div>
                          </div>
                        </div>;
                      })}
                    </div>}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card bg-white">
                  <div className="card-body">
                    <div class="row align-items-center">
                      <div className="col">
                        <span className="d-block fs-4">Total rounds</span>
                        <span className="d-block text-muted fs-6">Total number of rounds purchased</span>
                      </div>
                      {<div className="col-auto">
                        <span className="fs-4 fw-bold">
                          {data?.totalRounds ?? 0}
                        </span>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
});


function generateColors(data, opacity = 1) {
  const gradient = {
    0: [255, 255, 255, opacity],
    20: [203, 224, 177, opacity],
    45: [66, 179, 213, opacity],
    65: [26, 39, 62, opacity],
    100: [0, 0, 0, opacity]
  };

  var gradientKeys = Object.keys(gradient);
  gradientKeys.sort(function (a, b) {
    return +a - +b;
  });

  var setsCount = data.length;

  //Calculate colors
  var chartColors = [];
  for (var i = 0; i < setsCount; i++) {
    var gradientIndex = (i + 1) * (100 / (setsCount + 1)); //Find where to get a color from the gradient
    for (var j = 0; j < gradientKeys.length; j++) {
      var gradientKey = gradientKeys[j];
      if (gradientIndex === +gradientKey) { //Exact match with a gradient key - just get that color
        chartColors[i] = 'rgba(' + gradient[gradientKey].toString() + ')';
        break;
      } else if (gradientIndex < +gradientKey) { //It's somewhere between this gradient key and the previous
        var prevKey = gradientKeys[j - 1];
        var gradientPartIndex = (gradientIndex - prevKey) / (gradientKey - prevKey); //Calculate where
        var color = [];
        for (var k = 0; k < 4; k++) { //Loop through Red, Green, Blue and Alpha and calculate the correct color and opacity
          color[k] = gradient[prevKey][k] - ((gradient[prevKey][k] - gradient[gradientKey][k]) * gradientPartIndex);
          if (k < 3) color[k] = Math.round(color[k]);
        }
        chartColors[i] = 'rgba(' + color.toString() + ')';
        break;
      }
    }
  }

  return chartColors;
}

export default ReportsView;
