import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useStore } from "../../../stores/StoreContext";
import { hideFlyoutCard } from "../../../utils/flyout";
import { formatPrice } from "../../../utils/formatters";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import print from "print-js";
import OrdersService from "../../../services/OrdersService";
import moment from "moment";
import ModalService from "../../_Layout/Modal/ModalService";
import BuyNowModal from "../../../modals/BuyNowModal";
import PurchaseOrderModal from "../../../modals/PurchaseOrderModal";
import { toDownloadUrl, toFileNameFromUrl } from "../../../utils/url";
import UserService from "../../../services/UserService";

export const OrderDetailComponent = observer((props) => {
  const { cartStore, ordersStore } = useStore();
  const [order, setOrder] = useState();
  const [selectedDocument, setSelectedDocument] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isCurrentUserIndividual, setIsCurrentUserIndividual] = useState();

  useEffect(() => {
    const relationship = UserService.getCurrentUserRelationship();
    setIsCurrentUserIndividual(relationship === "Self");
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      var order = await OrdersService.getById(props.id);
      setOrder(order);
      setIsLoading(false);
    };
    fetchData();
  }, [props.id, ordersStore.notifier]);

  const onPrint = async (url = null) => {
    if (url) {
      print({
        printable: url,
        type: "pdf",
      });
    }
  };

  const onCopyToCart = () => {
    if (order && order.orderItems && order.orderItems.length > 0) {
      cartStore.copyToCart(order.orderItems);
      toast.success("Products copied to cart successfully!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const onClose = () => {
    hideFlyoutCard();
  };

  const showDocument = (document) => {
    setSelectedDocument(document);
  };

  const openBuyNowModal = () => {
    ModalService.open(BuyNowModal, { orderId: props.id });
  };

  const openPurchaseOrderModal = () => {
    ModalService.open(PurchaseOrderModal, { orderId: props.id, totalPrice: order.totalPrice });
  };

  return (
    <>
      <ToastContainer />
      <div className="content">
        <div className="container-fluid py-lg-3">
          {isLoading && (
            <div className="position-absolute top-50 start-50 translate-middle">
              <div
                className="spinner-border text-primary"
                role="status"
                style={{
                  "--bs-spinner-border-width": "0.15em",
                }}
              ></div>
            </div>
          )}
          {order && (
            <>
              <button className="btn-close position-absolute top-0 end-0 m-2 fs-4 d-xl-none" id="flyout-close" aria-label="Close" onClick={onClose}></button>

              <div className="order-tabel mb-5">
                <div className="row align-items-center mt-3 mb-3">
                  <div className="col mb-2 mb-md-0">
                    <div className="d-flex align-items-center mb-1 gap-2">
                      <h4 className="mb-0 fw-normal">{order.orderNumber}</h4>
                      <span className="badge badge-sm fw-semibold text-nobel border border-nobel rounded-pill">{order.orderStatus.toUpperCase()}</span>
                    </div>
                    <div className="d-inline-flex align-items-center">
                      <p className="mb-0">
                        <span className="text-muted">Created: </span>
                        <span className="me-2 fs-6">{moment(new Date(order.orderDate)).format("DD-MM-YYYY hh:mm A")}</span>
                      </p>
                    </div>
                  </div>
                  {order.orderStatus === "Quoted" && !isCurrentUserIndividual && (
                    <div className="col-auto">
                      <div className="d-flex gap-2">
                        <button className="btn btn-success text-white" onClick={openPurchaseOrderModal}>
                          Submit PO
                        </button>
                        {/* <button className="btn btn-warning text-dark" onClick={openBuyNowModal}>Buy now</button> */}
                      </div>
                    </div>
                  )}
                </div>
                <div className="mb-3 border-bottom border-nobel">
                  <div className="row gx-2 align-items-center">
                    <div className="col-2"></div>
                    <div className="col-7">
                      <p className="mb-0 fs-7 text-muted">Item</p>
                    </div>
                    <div className="col-1">
                      <p className="mb-0 fs-7 text-muted">QTY</p>
                    </div>
                    <div className="col-2 text-end">
                      <p className="mb-0 fs-7 text-muted">Total</p>
                    </div>
                  </div>
                </div>
                <ul className="list-group list-group-flush">
                  {order.orderItems &&
                    order.orderItems.map((item, i) => (
                      <li key={i} className="list-group-item" href="dashboard-product.html">
                        <div className="row gx-2">
                          <div className="col-2">
                            <div className="ratio ratio-16x9">
                              <img src={item.mainImageUrl} alt="" />
                            </div>
                          </div>
                          <div className="col-7">
                            <p className="mb-0 fs-8 text-muted">
                              {item.sku} {item.package.name.toUpperCase()}/{item.package.unitQuantity} rnds
                            </p>
                            <h6 className="mb-0 fs-7">
                              {item.brandLine} {item.primaryAttributes && item.primaryAttributes.length > 0 ? item.primaryAttributes[0].value : ""}{" "}
                              {item.primaryAttributes && item.primaryAttributes.length > 1 ? item.primaryAttributes[1].value : ""}{" "}
                              {item.primaryAttributes && item.primaryAttributes.length > 2 ? item.primaryAttributes[2].value : ""}
                            </h6>
                          </div>
                          <div className="col-1">
                            <p className="mb-0 fs-6 text-muted">x{item.quantity}</p>
                          </div>
                          <div className="col-2 text-end">
                            <p className="mb-0 fs-8 text-muted">{item.package.unitQuantity * item.quantity} rnds</p>
                            <p className="mb-0 fs-7 fw-bold">{formatPrice(item.totalPrice)}</p>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
                <div className="row align-items-center justify-content-end my-3 pt-3 border-top border-nobel">
                  <div className="col-9 col-md-5 text-end">
                    <div className="row">
                      <div className="col-6">
                        <p className="mb-1 text-muted">Handling fee</p>
                      </div>
                      <div className="col-6">
                        <p className="mb-1 text-muted">{formatPrice(order.handlingFee ?? 0)}</p>
                      </div>
                      <div className="col-6">
                        <p className="mb-1 text-muted">Shipping fee</p>
                      </div>
                      <div className="col-6">
                        <p className="mb-1 text-muted">{formatPrice(order.shippingFee ?? 0)}</p>
                      </div>
                      <div className="col-6">
                        <p className="mb-1 text-dark">Total</p>
                      </div>
                      <div className="col-6">
                        <p className="mb-1 text-dark">{formatPrice(order.totalPrice ?? 0)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="order-tabel mb-5">
                <div className="mb-3 border-bottom border-nobel">
                  <div className="row gx-2 align-items-center">
                    <div className="col-1"></div>
                    <div className="col-5">
                      <p className="mb-0 fs-7 text-muted">Status</p>
                    </div>
                    <div className="col-3">
                      <p className="mb-0 fs-7 text-muted">Documents</p>
                    </div>
                    <div className="col-3 text-end">
                      <p className="mb-0 fs-7 text-muted">Actions</p>
                    </div>
                  </div>
                </div>
                <ul className="list-group list-group-flush list-group-activity pb-2 mb-3 border-bottom border-nobel">
                  {order.orderActivities &&
                    order.orderActivities.map((item, i, orderActivities) => (
                      <li className="list-group-item" key={i}>
                        <div className="row">
                          <div className="col-1">
                            <div className="box box-xs">
                              <div
                                className={`box-title fs-7 rounded-circle ${item.activityDate ? "completed" : !item.activityDate && i > 0 && orderActivities[i - 1].activityDate ? "in-progress" : "waiting"
                                  }`}
                              ></div>
                            </div>
                          </div>
                          <div className="col-5">
                            <h6 className="mb-0">{item.orderStatusName}</h6>
                            <p className="mb-0 fs-8 text-muted">{item.activityDate ? moment(new Date(item.activityDate)).format("DD-MM-YYYY hh:mm A") : ""}</p>
                          </div>
                          <div className="col-3">
                            {item.documents &&
                              item.documents.map((d, i) => (
                                <OverlayTrigger key={i} placement="bottom" trigger={["hover", "focus"]} overlay={<Tooltip>{d.name}</Tooltip>} rootClose={true}>
                                  <a key={i} href="#!" className="me-1" data-bs-toggle="modal" data-bs-target="#show-document" onClick={() => showDocument(d)}>
                                    <img
                                      alt=""
                                      height="40"
                                      className="mb-2"
                                      src={`./assets/img/orders/${d.template.toLowerCase()}${d.isSignatureRequired && !d.signedOnDate ? "-unsigned" : ""}.svg`}
                                    />
                                  </a>
                                </OverlayTrigger>
                              ))}
                          </div>
                          <div className="col-3">
                            {item.documents && item.documents.length > 0 && (
                              <div className="d-flex gap-2 justify-content-end">
                                <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={<Tooltip>Download</Tooltip>} rootClose={true}>
                                  <a className="fs-6" rel="noreferrer" target="_blank" href={toDownloadUrl(item.documents[0].url)} download={toFileNameFromUrl(item.documents[0].url)}>
                                    <span className="fe fe-download"></span>
                                  </a>
                                </OverlayTrigger>

                                <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={<Tooltip>Print</Tooltip>} rootClose={true}>
                                  <a className="fs-6" href="#!" onClick={() => print({
                                    printable: item.documents[0].url,
                                    type: "pdf",
                                  })}>
                                    <span className="fe fe-printer"></span>
                                  </a>
                                </OverlayTrigger>

                                {/* <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={<Tooltip>Share</Tooltip>} rootClose={true}>
                                  <a className="fs-6 opacity-50" data-bs-title="Share" data-bs-toggle="tooltip">
                                    <span className="fe fe-share-2"></span>
                                  </a>
                                </OverlayTrigger> */}
                              </div>
                            )}
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
                {order.orderDocumentsArchiveUrl && (
                  <div className="d-flex justify-content-end">
                    <a href={order.orderDocumentsArchiveUrl} target="_blank" download={`${order.orderNumber}.zip`}>
                      <span className="fe fe-download"></span> <span>Download all</span>
                    </a>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="modal modal-lg fade" id="show-document" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
          <div className="modal-content border-0">
            <div className="modal-header pb-0 border-0">
              <h5 className="modal-title lh-1">{selectedDocument && selectedDocument.name}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="row align-items-center text-center text-md-start mb-3">
                <div className="col mb-2 mb-md-0">
                  <h5 className="mb-0 fw-normal">{selectedDocument && selectedDocument.date && moment(new Date(selectedDocument.date)).format("DD-MM-YYYY")}</h5>
                </div>
                <div className="col-12 col-md-auto">
                  <div className="btn-group btn-group-sm">
                    <a
                      className="btn btn-white px-2"
                      rel="noreferrer"
                      target="_blank"
                      href={selectedDocument && toDownloadUrl(selectedDocument.url)}
                      download={selectedDocument && toFileNameFromUrl(selectedDocument.url)}
                    >
                      <span className="fe fe-download"></span>
                      <span> Download</span>
                    </a>
                    <button className="btn btn-white px-2" onClick={() => onPrint(selectedDocument && selectedDocument.url)}>
                      <span className="fe fe-printer"></span>
                      <span> Print</span>
                    </button>
                    {/* <button className="btn btn-white btn-disabled opacity-50 px-2">
                      <span className="fe fe-share-2"></span>
                      <span> Share</span>
                    </button> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="ratio ratio-1x1 rounded-2 overflow-hidden">
                    <iframe src={selectedDocument && selectedDocument.url ? selectedDocument.url : "#!"} title={selectedDocument && selectedDocument.name}></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default OrderDetailComponent;
