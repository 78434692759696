import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import OrganizationService from "../../services/OrganizationService";
import Select from "react-select";
import { CreateOrganizationModal } from "./components/CreateOrganizationModal";
import { closeActiveModal } from "../../components/Modal";
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import debounce from "debounce-promise";

const ProfileStepView = (props) => {
  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({ mode: "all" });

  const navigate = useNavigate();

  const [organizations, setOrganizations] = useState([]);
  // const [genders, setGenders] = useState([
  //   {
  //     value: "",
  //     label: "Select gender",
  //     placeholder: true,
  //   },
  //   {
  //     value: "M",
  //     label: "Male",
  //   },
  //   {
  //     value: "F",
  //     label: "Female",
  //   },
  // ]);
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [isTaxExemptValidated, setIsTaxExemptValidated] = useState();
  const watchIsProcurementOfficer = watch("isProcurementOfficer");
  const watchIsBusinessEntityTaxExempt = watch("isBusinessEntityTaxExempt");

  useEffect(() => {
    if (props.IDmeData) {
      register("individualEntityTypeIds", { required: true });
      setValue("individualEntityTypeIds", props.IDmeData && props.IDmeData.status.length > 0 ? props.IDmeData.status[0].individualEntityTypes.map((iet) => iet.id) : []);

      register("firstName", { required: true });
      setValue("firstName", props.IDmeData.firstName);

      register("lastName", { required: true });
      setValue("lastName", props.IDmeData.lastName);

      register("fullName", { required: true });
      setValue("fullName", `${props.IDmeData.firstName} ${props.IDmeData.lastName}`);

      // register("dateOfBirth", { required: true });
      // setValue("dateOfBirth", props.IDmeData.birthDate);

      // register("gender", { required: true });

      register("email", { required: true });
      setValue("email", props.IDmeData.email);
    }
  }, [props.IDmeData, register, setValue]);

  // const onDateOfBirthChange = async ([date]) => {
  //   setValue("dateOfBirth", date, { shouldValidate: true });
  // };

  // const onGenderChange = async (selectedItem) => {
  //   setValue("gender", selectedItem.value, {
  //     shouldValidate: true,
  //   });
  // };

  const onOrganizationChange = (selectedItem) => {
    setValue("businessEntityId", selectedItem.value, {
      shouldValidate: true,
    });

    setSelectedOrganization(selectedItem);
    unregister("isProcurementOfficer");
    register("isProcurementOfficer", { required: selectedItem.value });
  };

  const onCreate = (formData) => {
    register("businessEntityInsertRequest");
    setValue("businessEntityInsertRequest", {
      name: formData.name,
      businessEntityTypeId: formData.businessEntityTypeId,
      address: formData.address,
      email: formData.email,
      phone: formData.phone,
      webUrl: formData.webUrl,
      location: {
        country: {
          name: formData.state,
        },
        city: {
          name: formData.city,
          zipCode: formData.zipCode,
        },
        address: formData.address,
      },
    });
    setOrganizations((previousOrganizations) => [...previousOrganizations.filter((po) => po.id !== null), { id: null, name: formData.name }]);
    setSelectedOrganization({ value: null, label: formData.name });
    setValue("businessEntityId", null);
    unregister("isProcurementOfficer");
    register("isProcurementOfficer", { required: true });
    closeActiveModal();
  };

  useEffect(() => {
    unregister("taxExemptionFile");
    register("taxExemptionFile", { required: watchIsBusinessEntityTaxExempt === "true" });
  }, [watchIsBusinessEntityTaxExempt]);

  useEffect(() => {
    const organizationData = organizations.find((o) => o.id === selectedOrganization.value);
    const taxExemptValidated = organizationData && organizationData.isTaxExemptValidated;
    setIsTaxExemptValidated(taxExemptValidated);
    unregister("isBusinessEntityTaxExempt");
    register("isBusinessEntityTaxExempt", { required: watchIsProcurementOfficer === "true" && !taxExemptValidated });
  }, [watchIsProcurementOfficer, selectedOrganization]);

  const OrganizationOption = props => {
    return (<components.Option {...props}>
      <span className="d-block">
        {props.label}
      </span>
      {props.data.address.address && props.data.address.city && props.data.address.state && <span className="d-block text-muted fs-7">
        {props.data.address.address}, {props.data.address.city} {props.data.address.state}
      </span>}
    </components.Option>);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(props.onSubmitStep1)}>
        <div className="row mb-2">
          <div className="col-12 col-md-12">
            <div className="mb-2">
              <label className="form-label" htmlFor="full-name">
                Full name <span className="text-danger">*</span>
              </label>
              <div className={`input-group input-group-merge input-group-reverse ${errors.fullName ? "" : "mb-2"}`}>
                <input
                  id="full-name"
                  placeholder="Your full name"
                  type="text"
                  aria-label="Your full name"
                  aria-describedby="full-name-user"
                  className={`form-control form-control-lg ${errors.fullName ? "border-danger" : ""}`}
                  {...register("fullName", { required: true })}
                  disabled={props.IDmeData && props.IDmeData.firstName && props.IDmeData.lastName}
                />
                <div id="full-name-user" className={`input-group-text ${errors.fullName ? "border-danger" : ""}`}>
                  <i className="fe fe-user"></i>
                </div>
              </div>
              {errors.fullName && <small className="text-danger mb-3">Full name is required.</small>}
            </div>
          </div>
        </div>
        {/* <div className="row mb-2">
          <div className="col-12 col-md-6">
            <div className="mb-2">
              <label className="form-label" htmlFor="birth-date">
                Birth Date <span className="text-danger">*</span>
              </label>
              <div className={`input-group input-group-merge input-group-reverse ${errors.dateOfBirth ? "" : "mb-2"}`}>
                <Flatpickr
                  className={`form-control form-control-lg ${errors.dateOfBirth ? "border-danger" : ""}`}
                  id="birth-date"
                  placeholder="Your birth date"
                  type="text"
                  aria-label="Your birth date"
                  aria-describedby="order-date-from-calendar"
                  value={props.IDmeData ? props.IDmeData.birthDate : ""}
                  onChange={onDateOfBirthChange}
                  disabled={props.IDmeData && props.IDmeData.birthDate}
                />

                <div id="birth-date-icon" className={`input-group-text ${errors.dateOfBirth ? "border-danger" : ""}`}>
                  <i className="fe fe-gift"></i>
                </div>
              </div>
              {errors.dateOfBirth && <small className="text-danger mb-3">Birth Date is required.</small>}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-2">
              <label className="form-label" htmlFor="gender-choices">
                Gender <span className="text-danger">*</span>
              </label>
              <div className={`${errors.gender ? "border-danger" : ""}`}>
                <Select options={genders} className="form-control" classNamePrefix="form-control" onChange={onGenderChange} />
              </div>
              {errors.gender && <small className="text-danger mb-3">Gender is required.</small>}
            </div>
          </div>
        </div> */}
        <div className="row mb-2">
          <div className="col-12 col-md-12">
            <div className="mb-2">
              <label className="form-label" htmlFor="email">
                E-mail address <span className="text-danger">*</span>
              </label>
              <div className={`input-group input-group-merge input-group-reverse ${errors.email ? "" : "mb-2"}`}>
                <input
                  className={`form-control form-control-lg ${errors.email ? "border-danger" : ""}`}
                  id="email"
                  placeholder="john.smith@example.com"
                  type="email"
                  aria-label="john.smith@example.com"
                  aria-describedby="email-at"
                  {...register("email", { required: true })}
                // TODO: WARNING!!! TEMPORARY
                // disabled={props.IDmeData && props.IDmeData.email}
                />
                <div className={`input-group-text ${errors.email ? "border-danger" : ""}`} id="email-at">
                  <i className="fe fe-at-sign"></i>
                </div>
              </div>
              {errors.email && <small className="text-danger mb-3">E-mail address is required.</small>}
            </div>
          </div>
          <div className="col-12 col-md-12">
            <div className="mb-2">
              <label className="form-label" htmlFor="type-choices">
                Member type <span className="text-danger">*</span>
              </label>
              <div className={`${errors.type ? "border-danger" : ""}`}>
                <Select
                  value={
                    props.IDmeData && props.IDmeData.status.length > 0 ? props.IDmeData.status[0].individualEntityTypes.map((item) => ({ value: item.id, label: `${item.name} - ${item.group}` })) : []
                  }
                  options={
                    props.IDmeData && props.IDmeData.status.length > 0 ? props.IDmeData.status[0].individualEntityTypes.map((item) => ({ value: item.id, label: `${item.name} - ${item.group}` })) : []
                  }
                  classNames={{ control: () => 'form-control form-control-select' }}
                  isMulti={true}
                  isDisabled={true}
                />
              </div>
            </div>
          </div>
        </div>

        {props.IDmeData && props.IDmeData.status[0].individualEntityTypes.some((iet) => iet.requiredBusinessEntity) && (
          <>
            <div className="mb-3">
              <label className="form-label" htmlFor="last-name">
                Organization
              </label>
              <div className="input-group input-group-merge input-group-reverse">
                <div className={`w-100 ${errors.organization ? "border-danger" : ""}`}>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    isClearable
                    components={{ Option: OrganizationOption }}
                    value={selectedOrganization}
                    onChange={onOrganizationChange}
                    loadOptions={debounce(OrganizationService.getBusinessEntities, 400)}
                    classNames={{ control: () => 'form-control form-control-select' }} />
                </div>
                <div className="input-group-btn">
                  <a className="btn btn-white" data-bs-toggle="modal" data-bs-target="#add-organization" href="#!">
                    Create
                  </a>
                </div>
              </div>
              {errors.organization && <small className="text-danger mb-3">Organization is required.</small>}
            </div>
            <div className="row mb-2">
              {selectedOrganization && (
                <div className="col-12 col-md-12">
                  <div className="mb-2">
                    <label className="form-label w-100">
                      I'm procurement officer that can represent selected organization <span className="text-danger">*</span>
                      <OverlayTrigger
                        placement="top"
                        trigger="click"
                        overlay={
                          <Tooltip>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                          </Tooltip>
                        }
                        rootClose={true}
                      >
                        <a className="float-end text-info" href="#!">
                          <span className="fe fe-info"></span>
                        </a>
                      </OverlayTrigger>
                    </label>
                    <div className="row">
                      <div className="col-6 col-md-6" style={{ paddingRight: "5px" }}>
                        <label className="form-check-label w-100" htmlFor="procurementOfficer">
                          <div className="card mb-0">
                            <div className="card-body py-2">
                              <div className="form-check">
                                <input className="form-check-input rounded" id="procurementOfficer" type="radio" {...register("isProcurementOfficer")} value={true} />
                                YES
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="col-6 col-md-6" style={{ paddingLeft: "5px" }}>
                        <label className="form-check-label w-100" htmlFor="notProcurementOfficer">
                          <div className="card mb-0">
                            <div className="card-body py-2">
                              <div className="form-check">
                                <input className="form-check-input rounded" id="notProcurementOfficer" type="radio" {...register("isProcurementOfficer")} value={false} />
                                NO
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    {errors.isProcurementOfficer && <small className="text-danger mb-3">Choose one of the available options.</small>}
                  </div>
                </div>
              )}
              {watchIsProcurementOfficer === "true" && !isTaxExemptValidated && (
                <div className="col-12 col-md-12">
                  <div className="mb-2">
                    <label className="form-label w-100">
                      My organization is tax exempt {selectedOrganization && !isTaxExemptValidated && <span className="text-danger">*</span>}
                      <OverlayTrigger
                        placement="top"
                        trigger="click"
                        overlay={
                          <Tooltip>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Tooltip>
                        }
                        rootClose={true}
                      >
                        <a className="float-end text-info" href="#!">
                          <span className="fe fe-info"></span>
                        </a>
                      </OverlayTrigger>
                    </label>
                    <div className="row">
                      <div className="col-6 col-md-6" style={{ paddingRight: "5px" }}>
                        <label className="form-check-label w-100" htmlFor="taxExempt">
                          <div className="card mb-0">
                            <div className="card-body py-2">
                              <div className="form-check">
                                <input className="form-check-input rounded" id="taxExempt" type="radio" {...register("isBusinessEntityTaxExempt")} value={true} />
                                YES
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="col-6 col-md-6" style={{ paddingLeft: "5px" }}>
                        <label className="form-check-label w-100" htmlFor="notTaxExempt">
                          <div className="card mb-0">
                            <div className="card-body py-2">
                              <div className="form-check">
                                <input className="form-check-input rounded" id="notTaxExempt" type="radio" {...register("isBusinessEntityTaxExempt")} value={false} /> NO
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    {errors.isBusinessEntityTaxExempt && <small className="text-danger mb-3">Choose one of the available options.</small>}
                  </div>
                </div>
              )}
            </div>
            {watchIsBusinessEntityTaxExempt === "true" && (
              <div className="mb-2">
                <label className="form-label w-100" htmlFor="tax-exemption">
                  Tax exemption (.pdf) {selectedOrganization && !isTaxExemptValidated && <span className="text-danger">*</span>}
                </label>
                <input className="form-control form-control-lg" id="tax-exemption" type="file" accept="application/pdf" {...register("taxExemptionFile")} />
                {errors.taxExemptionFile && <small className="text-danger mb-3">Choose tax exemption certificate.</small>}
              </div>
            )}
            <div className="row">
              <div className="col-12">
                {/* <p className="text-muted">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed feugiat nisi at nibh fermentum, in ultrices ipsum pellentesque. Sed quis libero sed mi pellentesque tempus sed nec nunc.
                </p> */}
              </div>
            </div>
          </>
        )}
        <div className="nav row align-items-center mt-4">
          <div className="col">
            <button className="btn btn-lg btn-white" onClick={() => navigate("/sign-in")} type="button">
              Back
            </button>
          </div>

          <div className="col-auto">
            <button className="btn btn-lg btn-primary" type="submit">
              Continue
            </button>
          </div>
        </div>
      </form>
      <CreateOrganizationModal onCreate={onCreate}></CreateOrganizationModal>
    </React.Fragment>
  );
};

export default ProfileStepView;
