import React, { useState, useEffect } from "react";
import SignInMain from "./components/SignInMain";
import SignInPassword from "./components/SignInPassword";
import SignInOTPCode from "./components/SignInOTPCode";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import UserService from "../../services/UserService";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export function SignInView() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [loginType, setLoginType] = useState("password");
  const [formData, setFormData] = useState();
  const [errors, setErrors] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [resendAvailableIn, setResendAvailableIn] = useState(0);

  useEffect(() => {
    const confirmCode = searchParams.get("emailConfirmCode");
    if (confirmCode) {
      const email = searchParams.get("email");
      setFormData({ email });
      setLoginType("otp");
      setStep(2);
    }
  }, []);

  useEffect(() => {
    if (location.state && location.state.message) {
      setFormData({ email: location.state.email });
      setStep(2);
      setLoginType(location.state.loginType ? location.state.loginType : "password");
    }
    window.history.replaceState(null, "");
  }, [location.state]);

  const onSubmitStep1 = async (data) => {
    setIsLoading(true);
    setFormData(data);
    const isValidUserData = await UserService.isValidUser(data.email && data.email.trim());
    setIsLoading(false);
    if (typeof isValidUserData !== "boolean") {
      setErrors((previousItem) => ({ previousItem, isValidUserServerMessage: isValidUserData.resultList && isValidUserData.resultList[0].description }));
    } else {
      setStep(step + 1);
    }
  };

  const onSubmitStep2 = async (data) => {
    setIsLoading(true);
    setFormData({ ...formData, ...data });
    if (data && data.password) {
      const responseData = await UserService.signIn(formData.email, data.password);
      setIsLoading(false);
      if (responseData.accessToken) {
        UserService.setCurrentUser(responseData.accessToken);
        navigate("/");
      } else {
        setErrors((previousItem) => ({
          previousItem,
          incorrectPassword: true,
        }));
      }
    } else {
      var otp = `${data.pin1}${data.pin2}${data.pin3}${data.pin4}`;
      const emailConfirmCode = searchParams.get("emailConfirmCode");
      const responseData = await UserService.signInOtpCode(formData.email, otp, emailConfirmCode);
      setIsLoading(false);
      if (responseData.accessToken) {
        UserService.setCurrentUser(responseData.accessToken);
        navigate("/");
      } else {
        setErrors((previousItem) => ({ previousItem, invalidPin: true }));
      }
    }
  };

  const onLoginTypeChange = (type) => {
    if (type) {
      setLoginType(type);
    } else {
      setStep(1);
      setLoginType("password");
    }
  };

  const clearCustomErrors = () => {
    setErrors([]);
  };

  const setResendAvailableInValue = (value) => {
    setResendAvailableIn(value);
    const interval = setInterval(function () {
      setResendAvailableIn((prevValue) => prevValue - 1);
    }, 1000);
  };

  return (
    <div className="d-flex align-items-center min-vh-100 bg-pattern">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-7 col-xxl-9">
            <div className="card card-lg">
              <div className="card-body p-0">
                <div className="row g-0">
                  <div className="col-12 col-md-6 border-end border-light">
                    <div className="d-flex flex-column h-100 px-4 py-5">
                      <div className="text-center mb-4">
                        <img className="d-inline-block logo" src="./assets/img/brand/logo.png" alt="" />
                      </div>
                      {step === 1 && <SignInMain onSubmitStep1={onSubmitStep1} onLoginTypeChange={onLoginTypeChange} errors={errors} clearCustomErrors={clearCustomErrors} isLoading={isLoading} />}
                      {step === 2 && (
                        <>
                          {loginType === "password" && (
                            <SignInPassword onSubmitStep2={onSubmitStep2} onLoginTypeChange={onLoginTypeChange} email={formData.email} errors={errors} isLoading={isLoading} />
                          )}
                          {loginType === "otp" && (
                            <SignInOTPCode
                              onSubmitStep2={onSubmitStep2}
                              onLoginTypeChange={onLoginTypeChange}
                              email={formData.email}
                              errors={errors}
                              clearCustomErrors={clearCustomErrors}
                              resendAvailableIn={resendAvailableIn}
                              setResendAvailableInValue={setResendAvailableInValue}
                              isLoading={isLoading}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 d-none d-md-flex">
                    <div id="onboarding-carousel" className="carousel slide carousel-fade w-100 h-100" data-bs-ride="carousel" data-bs-interval="5000">
                      <div className="carousel-indicators">
                        <button className="active" type="button" data-bs-target="#onboarding-carousel" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#onboarding-carousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#onboarding-carousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                      </div>
                      <div className="carousel-inner w-100 h-100">
                        <div
                          className="carousel-item active w-100 h-100 bg-cover"
                          style={{
                            backgroundImage: "url(./assets/img/carousel/sidebar.png)",
                          }}
                          alt=""
                        >
                          <div className="carousel-caption">
                            <h5>Sigurna platforma za ljekarničke potrebe</h5>
                            <p className="mb-0">
                              Pružamo sigurno i pouzdano tržište za farmaceutske potrebe.
                            </p>
                          </div>
                        </div>
                        <div
                          className="carousel-item w-100 h-100 bg-cover"
                          style={{
                            backgroundImage: "url(./assets/img/carousel/sidebar.png)",
                          }}
                          alt=""
                        >
                          <div className="carousel-caption">
                            <h5>Zatvorena platforma za siguran pristup</h5>
                            <p className="mb-0">
                              Platforma za prodaju lijekova pruža siguran, pouzdan i jednostavan način nabavke medicinskih proizvoda putem interneta.
                            </p>
                          </div>
                        </div>
                        <div
                          className="carousel-item w-100 h-100 bg-cover"
                          style={{
                            backgroundImage: "url(./assets/img/carousel/sidebar.png)",
                          }}
                          alt=""
                        >
                          <div className="carousel-caption">
                            <h5>Širok izbor kvalitetnih lijekova</h5>
                            <p className="mb-0">Otkrijte širok izbor lijekova izravno od uglednih proizvođača, brzo isporučenih kako bi zadovoljili vaše potrebe.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
