import React from "react";

const VerificationStepView = () => {
  return (
    <React.Fragment>
      <div className="mb-4 text-center">
        <h1 className="mb-1 fs-2">Verify your identity</h1>
        <p className="mb-0 text-muted">
          Please complete your identity verification process in order to use our
          services
        </p>
      </div>
      <form>
        <div className="vstack gap-2">
          <button className="btn btn-lg btn-primary" onClick={() => {}}>
            Verify with driver's licence
          </button>
          <button
            className="btn btn-lg btn-success text-white"
            onClick={() => {}}
          >
            Verify with ID.me
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default VerificationStepView;
