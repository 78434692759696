import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useStore } from "../../stores/StoreContext";
import { ProductFiltersModal } from "../../pages/_Layout/components/ProductFiltersModal";
import { OrderFiltersModal } from "./components/OrderFiltersModal";
import { closeActiveModal } from "../../components/Modal";
import moment from "moment";

export const Filter = () => {
  const { filtersStore } = useStore();
  const [attributeFilters, setAttributeFilters] = useState({});
  const [manufacturers, setManufacturers] = useState([]);
  const [brandLines, setBrandLines] = useState([]);
  const [dates, setDates] = useState({});
  const [type, setType] = useState(74);

  const [orderStatusId, setOrderStatusId] = useState();
  const [paymentStatusId, setPaymentStatusId] = useState();
  const [orderTypeId, setOrderTypeId] = useState();
  const [paymentTypeId, setPaymentTypeId] = useState();
  const [shippingStatusId, setShippingStatusId] = useState();

  const location = useLocation();

  useEffect(() => {
    const filterParams = filtersStore.getFiltersParams(type);
    setAttributeFilters(filterParams.attributeFilters);
    setManufacturers(filterParams.manufacturerIdList);
    setBrandLines(filterParams.brandLineIdList);
    setOrderStatusId(filtersStore.orderFilters.orderStatusId);
    setPaymentStatusId(filtersStore.orderFilters.paymentStatusId);
    setOrderTypeId(filtersStore.orderFilters.orderTypeId);
    setPaymentTypeId(filtersStore.orderFilters.paymentTypeId);
    setShippingStatusId(filtersStore.orderFilters.shippingStatusId);
    setDates({ dateFrom: filtersStore.orderFilters.dateFrom, dateTo: filtersStore.orderFilters.dateTo });
  }, [location.pathname, filtersStore]);

  const onManufacturersChange = async (e) => {
    let selected = e.map((m) => +m.value);
    setManufacturers(selected);
  };

  const onBrandLinesChange = async (e) => {
    let selected = e.map((m) => +m.value);
    setBrandLines(selected);
  };

  const onOrderStatusChange = async (e) => {
    setOrderStatusId(e ? e.value : null);
  };

  const onPaymentStatusChange = async (e) => {
    setPaymentStatusId(e ? e.value : null);
  };

  const onOrderTypeChange = async (e) => {
    setOrderTypeId(e ? e.value : null);
  };

  const onPaymentTypeChange = async (e) => {
    setPaymentTypeId(e ? e.value : null);
  };

  const onShippingStatusChange = async (e) => {
    setShippingStatusId(e ? e.value : null);
  };

  const onFilterChange = async (filterId, enumCode, e) => {
    filterId = filterId.toString();

    let selected = enumCode === 4 ? [e.target.value] : e.map((e) => e.value);

    if (attributeFilters[filterId]) {
      switch (enumCode) {
        case 4:
          if (e.target.checked) {
            attributeFilters[filterId] = [...attributeFilters[filterId], e.target.value];
          } else {
            attributeFilters[filterId] = attributeFilters[filterId].filter((cv) => cv !== e.target.value);
            if (!attributeFilters[filterId] || attributeFilters[filterId].length === 0) {
              delete attributeFilters[filterId];
            }
          }
          setAttributeFilters(attributeFilters);
          break;
        default:
          if (selected.length === 0) {
            delete attributeFilters[filterId];
          } else {
            attributeFilters[filterId] = selected;
          }
          setAttributeFilters(attributeFilters);
          break;
      }
    } else {
      attributeFilters[filterId] = selected;
      setAttributeFilters(attributeFilters);
    }
  };

  useEffect(() => {}, [attributeFilters.length]);

  const onDateFromChange = async ([date]) => {
    dates.dateFrom = date;
    setDates(dates);
  };

  const onDateToChange = async ([date]) => {
    dates.dateTo = date;
    setDates(dates);
  };

  const onTypeChange = (type) => {
    setType(type);
    const filterParams = filtersStore.getFiltersParams(type);
    setAttributeFilters(filterParams.attributeFilters);
    setManufacturers(filterParams.manufacturerIdList);
    setBrandLines(filterParams.brandLineIdList);
  };

  const onSet = () => {
    var currentFilters = filtersStore.filters;

    if (location.pathname === "/") {
      var subcategoryFiltersIndex = currentFilters.findIndex((cf) => cf.id === type);

      if (subcategoryFiltersIndex !== -1) {
        currentFilters[subcategoryFiltersIndex].attributes.forEach((attribute) => {
          var values = attributeFilters[attribute.id] ? attributeFilters[attribute.id] : [];
          attribute.attributeFilterValues.forEach((afv) => (afv.isSelected = values.some((af) => af === afv.value)));
        });
      }

      currentFilters[subcategoryFiltersIndex].manufacturerIdList = manufacturers;
      currentFilters[subcategoryFiltersIndex].brandLineIdList = brandLines;
    } else if (location.pathname && location.pathname.toLowerCase().includes("/orders")) {
      filtersStore.setOrderFilters({
        orderStatusId,
        paymentStatusId,
        orderTypeId,
        paymentTypeId,
        shippingStatusId,
        dateFrom: dates.dateFrom && moment(dates.dateFrom).format("YYYY-MM-DD"),
        dateTo: dates.dateTo && moment(dates.dateTo).format("YYYY-MM-DD"),
      });
    }
    filtersStore.setFilters(currentFilters);
    filtersStore.changed++;
    closeActiveModal();
  };

  const onClearAll = () => {
    filtersStore.clearFilters(type);
  };

  const onClearAllOrders = () => {
    setPaymentStatusId(null);
    setOrderTypeId(null);
    setPaymentTypeId(null);
    setShippingStatusId(null);
    setDates({ dateFrom: null, dateTo: null});
    filtersStore.clearOrderFilters();
  };

  return (
    <>
      <OverlayTrigger
        overlay={
          <Tooltip>
            {location.pathname === "/" && "Filter products"}
            {location.pathname === "/orders" && "Filter orders"}
            {location.pathname === "/documents" && "Filter documents"}
          </Tooltip>
        }
        placement="bottom"
        rootClose={true}
      >
        <a className="d-block text-body text-center position-relative" data-bs-toggle="modal" data-bs-target="#set-product-filters" href="#!">
          <span className="d-block fs-5 fe fe-filters"></span>
        </a>
      </OverlayTrigger>

      {location.pathname === "/" && (
        <ProductFiltersModal
          onManufacturersChange={onManufacturersChange}
          onBrandLinesChange={onBrandLinesChange}
          onTypeChange={onTypeChange}
          onFilterChange={onFilterChange}
          onSet={onSet}
          onClearAll={onClearAll}
        ></ProductFiltersModal>
      )}

      {location.pathname === "/orders" && (
        <OrderFiltersModal
          onOrderStatusChange={onOrderStatusChange}
          onPaymentStatusChange={onPaymentStatusChange}
          onOrderTypeChange={onOrderTypeChange}
          onPaymentTypeChange={onPaymentTypeChange}
          onShippingStatusChange={onShippingStatusChange}
          onDateFromChange={onDateFromChange}
          onDateToChange={onDateToChange}
          onSet={onSet}
          onClearAll={onClearAllOrders}
        ></OrderFiltersModal>
      )}
    </>
  );
};
