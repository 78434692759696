import { Navigate } from "react-router-dom";

function ProtectedRoute({ children }) {
  let auth = localStorage.getItem("loggedUser");
  if (!auth) {
    return <Navigate to="/sign-in" />;
  }
  return children;
}

export default ProtectedRoute;
