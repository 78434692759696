import Highlighter from "react-highlight-words";
import { observer } from "mobx-react";
import { formatPrice } from "../../../utils/formatters";
import debounce from "debounce";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export const ProductRowItem = observer((props) => {
  return (
    <>
      <div className="col-12">
        <div
          id={`card-${props.subcategory}-${props.index}`}
          data-index={props.index}
          data-type={props.subcategory}
          className={`card card-xs card-lift card-lift-expand mb-0 h-100 rounded-1 shadow-none ${props.selectedItem && props.selectedItem.id === props.item.id ? "selected" : ""}`}
        >
          <div className="card-action position-absolute bottom-0 end-0">
            {props.itemCartQuantity(props.item.id, props.item.package.id) === 0 && (
              <button className="btn btn-sm btn-white text-muted" onClick={() => props.addToCart(props.item.id)} disabled={props.isBudgetReached(props.item.price)}>
                <span className="fe fe-cart me-1"></span>
                <span>Add</span>
              </button>
            )}
            {props.itemCartQuantity(props.item.id, props.item.package.id) !== 0 && (
              <div className="btn-number d-inline-flex">
                <button className="btn btn-sm btn-link btn-minus px-1" onClick={() => props.removeFromCart(props.item.id, props.item.package.id)}>
                  <span className="fe fe-minus align-middle"></span>
                </button>
                <input
                  key={`home-${props.item.id}-${props.item.package.id}-${props.itemCartQuantity(props.item.id, props.item.package.id)}`}
                  className={`form-control form-control-sm form-control-flush px-0 text-center rounded-0 id-${props.item.id}-${props.item.package.id}`}
                  min={0}
                  type="number"
                  name="quantity"
                  defaultValue={props.itemCartQuantity(props.item.id, props.item.package.id)}
                  disabled={props.isBudgetReached(props.item.price)}
                  onFocus={(e) => e.target.select()}
                  onBlur={(e) => {
                    if (!e.target.value || !e.target.value.length || isNaN(parseInt(e.target.value)))
                      props.removeFromCart(props.item.id, props.item.package.id);
                  }}
                  onChange={debounce(async (e) => {
                    props.addToCart(props.item.id, parseInt(e.target.value), true);
                  }, 500)}
                />
                <button className="btn btn-sm btn-link btn-plus px-1" onClick={() => props.addToCart(props.item.id)} disabled={props.isBudgetReached(props.item.price)}>
                  <span className="fe fe-plus align-middle"></span>
                </button>
              </div>
            )}
          </div>

          <a
            id={`show-details-btn-${props.subcategory}-${props.index}`}
            index={props.index}
            className="card-body lh-1 py-sm-2 py-md-3 px-2"
            href="#!"
            data-toggle="flyout"
            onClick={() => props.onSelectedItem(props.item.id, props.subcategory === "search")}
          >
            <span className="row gx-2 align-items-center">
              <span className="col col-thumb-lg">
                <span className="d-block ratio ratio-16x9">
                  <img src={props.item.package.imageUrl} alt="" />
                </span>
              </span>
              <span className="col">
                <span className="row align-items-end">
                  <span className="col fs-8 text-truncate text-uppercase text-muted">
                    <Highlighter searchWords={props.searchTerm ? props.searchTerm : [""]} autoEscape={true} textToHighlight={props.item.sku} />
                  </span>
                  <span className="col-auto">
                    <img src={props.item.manufacturerImageUrl} alt="" height="15" />
                  </span>
                </span>
                <span className="h6 py-1 py-xl-2 mb-0 d-block">
                  {props.item.primaryAttributes.length > 0 && props.item.primaryAttributes[0].description && (
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      placement="top"
                      overlay={<Tooltip>{props.item.primaryAttributes.length > 0 && props.item.primaryAttributes[0].description}</Tooltip>}
                      rootClose={true}
                    >
                      <span>
                        <Highlighter
                          searchWords={props.searchTerm ? props.searchTerm : [""]}
                          autoEscape={true}
                          textToHighlight={`${props.item.primaryAttributes.length > 0 && props.item.primaryAttributes[0].value}`}
                        />
                      </span>
                    </OverlayTrigger>
                  )}
                  {props.item.primaryAttributes.length > 0 && !props.item.primaryAttributes[0].description && (
                    <Highlighter
                      searchWords={props.searchTerm ? props.searchTerm : [""]}
                      autoEscape={true}
                      textToHighlight={`${props.item.primaryAttributes.length > 0 && props.item.primaryAttributes[0].value}`}
                    />
                  )}
                  &nbsp;&middot;&nbsp;
                  {props.item.primaryAttributes.length > 1 && props.item.primaryAttributes[1].description && (
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      placement="top"
                      overlay={<Tooltip>{props.item.primaryAttributes.length > 1 && props.item.primaryAttributes[1].description}</Tooltip>}
                      rootClose={true}
                    >
                      <span>
                        <Highlighter
                          searchWords={props.searchTerm ? props.searchTerm : [""]}
                          autoEscape={true}
                          textToHighlight={`${props.item.primaryAttributes.length > 1 && props.item.primaryAttributes[1].value}`}
                        />
                      </span>
                    </OverlayTrigger>
                  )}
                  {props.item.primaryAttributes.length > 1 && !props.item.primaryAttributes[1].description && (
                    <Highlighter
                      searchWords={props.searchTerm ? props.searchTerm : [""]}
                      autoEscape={true}
                      textToHighlight={`${props.item.primaryAttributes.length > 1 && props.item.primaryAttributes[1].value}`}
                    />
                  )}
                  &nbsp;&middot;&nbsp;
                  {props.item.primaryAttributes.length > 2 && props.item.primaryAttributes[2].description && (
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      placement="top"
                      overlay={<Tooltip>{props.item.primaryAttributes.length > 2 && props.item.primaryAttributes[2].description}</Tooltip>}
                      rootClose={true}
                    >
                      <span>
                        <Highlighter
                          searchWords={props.searchTerm ? props.searchTerm : [""]}
                          autoEscape={true}
                          textToHighlight={`${props.item.primaryAttributes.length > 2 && props.item.primaryAttributes[2].value}`}
                        />
                      </span>
                    </OverlayTrigger>
                  )}
                  {props.item.primaryAttributes.length > 2 && !props.item.primaryAttributes[2].description && (
                    <Highlighter
                      searchWords={props.searchTerm ? props.searchTerm : [""]}
                      autoEscape={true}
                      textToHighlight={`${props.item.primaryAttributes.length > 2 && props.item.primaryAttributes[2].value}`}
                    />
                  )}
                </span>
                <span className="row align-items-end fs-6">
                  <span className="col-8 pt-1 pt-xl-0">
                    <span>{formatPrice(props.item.unitPrice)}&nbsp;</span>
                    <span className="fs-8 text-muted">/{props.item.measurementUnit}</span>
                    &nbsp;·&nbsp;
                    <span className="fs-7 text-black">{props.item.package.unitQuantity}&nbsp;</span>
                    <span className="fs-8 text-muted">per {props.item.package.name.toLowerCase()}</span>
                  </span>
                </span>
              </span>
            </span>
          </a>
        </div>
      </div>
    </>
  );
});
