import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";

function PasswordInput(props) {
  const [isVisible, setIsVisible] = useState(false);
  const {
    id,
    name,
    placeholder,
    onKeyUp,
    minLength,
    maxLength,
    disabled,
    iconClassName,
    inputGroupClassName,
    inputClassName,
    formOptions
  } = props;

  const toggleVisible = (event) => {
    setIsVisible(!isVisible);
    event.preventDefault();
  }

  return (
    <div className={`input-group input-group-merge input-group-reverse ${inputGroupClassName ?? ""}`}>
      <input className={`form-control form-control-lg ${inputClassName ?? ""}`}
        type={isVisible ? "text" : "password"}
        id={id}
        name={name}
        placeholder={placeholder}
        aria-label={placeholder}
        onKeyUp={onKeyUp}
        maxLength={maxLength}
        minLength={minLength}
        disabled={disabled}
        {...(delete formOptions.type && formOptions)} />
      <div className={`input-group-text ${iconClassName ?? ""}`}>
        <i className="fe fe-lock"></i>
      </div>
      <div className="input-group-btn">
        <button type="button" className="btn btn-link password-toggle" onClick={toggleVisible}>
          <span className={`fe ${isVisible ? "fe-eye-off" : "fe-eye"}`}></span>
        </button>
      </div>
    </div>
  );
}

PasswordInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onKeyUp: PropTypes.func,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  iconClassName: PropTypes.string,
  inputGroupClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  formOptions: PropTypes.object
};

export default PasswordInput;