import axios from "axios";
import IProduct from "../types/Product";
import StoreService from "./StoreService";

export const getAllSubcategories = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get<Array<IProduct>>("https://testapi-commerce.emaq.ba/api/MockedData/UserCategoriesSubcategoriesGroups", { params: { storeId: StoreService.getStoreId() } })
        .then((res) => {
          var response = res as any;
          resolve(response.data.resultList[0].subcategories);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  });
};

export const getFiltersWithValuesBySubcategoryId = (subcategoryId, groupId = null) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get<Array<IProduct>>("https://testapi-commerce.emaq.ba/api/MockedData/GetFiltersWithValues", { params: { storeId: StoreService.getStoreId(), categoryId: 99, subcategoryId, groupId } })
        .then((res) => {
          var response = res as any;
          resolve(response.data.resultList);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  });
};

export const getManufacturersBySubcategoryId = (subcategoryId, groupId = null) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get<Array<IProduct>>("https://testapi-commerce.emaq.ba/api/MockedData/GetUserManufacturers", { params: { storeId: StoreService.getStoreId(), categoryId: 99, subcategoryId, groupId } })
        .then((res) => {
          var response = res as any;
          resolve(response.data.resultList);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  });
};

export const getBrandLinesBySubcategoryId = (subcategoryId, manufacturerIds = null, groupId = null) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get<Array<IProduct>>("https://testapi-commerce.emaq.ba/api/mockedData/GetUserBrandLines", {
          params: {
            storeId: StoreService.getStoreId(),
            categoryId: 99,
            subcategoryId,
            manufacturerIds,
            groupId,
          },
        })
        .then((res) => {
          var response = res as any;
          resolve(response.data.resultList);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  });
};

const FiltersService = {
  getAllSubcategories,
  getFiltersWithValuesBySubcategoryId,
  getManufacturersBySubcategoryId,
  getBrandLinesBySubcategoryId,
};

export default FiltersService;
