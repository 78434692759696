import { useState } from "react";
import UserService from "../../services/UserService";

export function useProvideAuth() {
  const [user, setUser] = useState(null);
  const signUp = (userData) => {
    return new Promise((resolve, reject) => {
      try {
        UserService.addUser(userData)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            setUser([]);
          });
      } catch (error) {}
    });
  };
  const logIn = (email) => {
    return new Promise(async (resolve, reject) => {
      try {
        var userJson = localStorage.getItem("user");
        var demoUser = await UserService.getDemoUser();

        if (userJson || demoUser) {
          var user = demoUser && demoUser.email === email ? JSON.stringify({ ...demoUser, isIndividualUser: false }) : JSON.stringify({ ...JSON.parse(userJson), isIndividualUser: false });
          localStorage.setItem("loggedUser", user);
          var newUser = JSON.parse(user);
          setUser(newUser);
          resolve(newUser);
        } else {
          setUser(null);
        }
      } catch (error) {}
    });
  };
  const logOut = () => {
    return new Promise((resolve, reject) => {
      try {
        localStorage.removeItem("layout");
        localStorage.removeItem("loggedUser");
        localStorage.removeItem("budget");
        localStorage.removeItem("isEnteredBudgetLimit");
        localStorage.removeItem("filters");
        localStorage.removeItem("orderFilters");
        setUser(null);
        resolve(true);
      } catch (error) {}
    });
  };

  return {
    user,
    signUp,
    logIn,
    logOut,
  };
}
