import React, { useEffect, useState } from "react";

import { observer } from "mobx-react";

import { OrderDetailComponent } from "./components/OrderDetailComponent";
import { OrderItemComponent } from "./components/OrderItemComponent";

import showFlyoutCard from "../../utils/flyout";
import { useStore } from "../../stores/StoreContext";
import OrdersService from "../../services/OrdersService";
import OrderStatusesService from "../../services/OrderStatusesService";
import debounce from "debounce";

const OrdersView = observer(() => {
  const { ordersStore, searchStore, filtersStore, sortStore } = useStore();
  const [orders, setOrders] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [orderStatuses, setOrderStatuses] = useState();
  const [activeOrderStatusId, setActiveOrderStatusId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [loadedOrderStatuses, setLoadedOrderStatuses] = useState([]);
  const [orderStatusesPagingParams, setOrderStatusesPagingParams] = useState([]);
  const [areAllLoaded, setAreAllLoaded] = useState();
  const [allOrders, setAllOrders] = useState([]);
  const [lastScrollTop, setLastScrollTop] = useState();

  const initArrowKeys = (e) => {
    e = e || window.event;
    var cursorInput = document.getElementById("cursor");
    var currentIndex = cursorInput && cursorInput.value && parseInt(cursorInput.value);
    var selectedEl = document.getElementsByClassName("selected");
    if (!selectedEl || selectedEl.length === 0) return;

    if (e.keyCode === 38 || e.keyCode === 40) {
      if (!currentIndex) {
        currentIndex = parseInt(selectedEl[0].dataset.index);
      }

      var newIndex = currentIndex + (e.keyCode === 38 ? -1 : 1);

      var currentElement = document.getElementById("card-" + currentIndex);
      var newElement = document.getElementById("card-" + newIndex);
      if (!newElement) return;

      currentElement.classList.remove("selected");
      newElement.classList.add("selected");
      cursorInput.value = newIndex;
    } else if (e.keyCode === 39) {
      currentElement = document.getElementById("show-details-btn-" + currentIndex);
      if (currentElement) {
        currentElement.click();
      }
    }
  };
  useEffect(() => {
    var allTab = document.getElementById("orders-all-tab");
    if (allTab) {
      allTab.click();
    }
    const fetchOrderStatuses = async () => {
      const orderStatusesResponse = await OrderStatusesService.getAllOrderStatuses();
      setOrderStatuses(orderStatusesResponse);
      const pagingParams = orderStatusesResponse.map((os) => ({ id: os.id, page: 0, pageSize: 10, hasMore: true }));
      pagingParams.unshift({ id: "all", page: 0, pageSize: 10, hasMore: true });
      setOrderStatusesPagingParams(pagingParams);
      setActiveOrderStatusId("all");
    };
    fetchOrderStatuses();
    document.addEventListener("keydown", initArrowKeys);
    document.body.classList.add("sticky");
    return () => {
      document.removeEventListener("keydown", initArrowKeys);
      document.body.classList.remove("sticky");
    };
  }, [ordersStore.notifier]);

  useEffect(() => {
    if (activeOrderStatusId) {
      loadOrders(activeOrderStatusId);
    } else {
      loadOrders("all");
    }
  }, [sortStore.ordersSortBy]);

  useEffect(() => {
    searchStore.clearSearch();
  }, [searchStore]);

  useEffect(() => {
    setIsLoading(true);
    const fetchOrdersData = async () => {
      const isSearch = searchStore.searchTerm && searchStore.searchTerm.length > 0;
      if (isSearch) {
        setOrders([]);
      }
      const isAllCategory = activeOrderStatusId === "all" && !isSearch;
      const filtersParams = filtersStore.orderFilters;
      const data = await OrdersService.getByParameters({
        orderStatusId: activeOrderStatusId !== "all" ? activeOrderStatusId : filtersParams.orderStatusId,
        paymentStatusId: filtersParams.paymentStatusId,
        orderTypeId: filtersParams.orderTypeId,
        paymentTypeId: filtersParams.paymentTypeId,
        shippingStatusId: filtersParams.shippingStatusId,
        orderedDateFrom: filtersParams.dateFrom,
        orderedDateTo: filtersParams.dateTo,
        orderBy: sortStore.ordersSortBy,
        searchParam: isSearch ? searchStore.searchTerm.join(" ") : null,
        page: 0,
        pageSize: 10,
      });

      if (isSearch || !isAllCategory) {
        setOrders(data.resultList);
      } else if (isAllCategory) {
        setAllOrders(data.resultList);
      }
      setIsLoading(false);
      if (orderStatusesPagingParams && orderStatusesPagingParams.length > 0) {
        orderStatusesPagingParams.forEach((ospp) => {
          ospp.page = 0;
          ospp.pageSize = 10;
          ospp.hasMore = true;
        });
        setOrderStatusesPagingParams(orderStatusesPagingParams);
      }

      setLoadedOrderStatuses([]);
    };
    fetchOrdersData();
  }, [searchStore.searchTerm, sortStore.ordersSortBy, filtersStore.changed]);

  const onSelectedItem = (id) => {
    const indexOrders = orders ? orders.findIndex((p) => p.id === id) : -1;
    const indexAllOrders = allOrders ? allOrders.findIndex((p) => p.id === id) : -1;

    if (indexOrders !== -1 || indexAllOrders !== -1) {
      var selectedEl = document.getElementsByClassName("selected");
      if (selectedEl && selectedEl.length > 0 && parseInt(selectedEl[0].dataset.index) !== (indexOrders !== -1 ? indexOrders : indexAllOrders)) {
        selectedEl[0].classList.remove("selected");
      }
      const selectedProduct = indexOrders !== -1 ? orders[indexOrders] : allOrders[indexAllOrders];
      setSelectedItem(selectedProduct);
      showFlyoutCard();
    }
  };

  const loadOrders = async (orderStatusId) => {
    setActiveOrderStatusId(orderStatusId);
    setIsLoading(true);

    if (orderStatusId !== "all") {
      setLoadedOrderStatuses((previousLoadedOrderStatuses) => [...previousLoadedOrderStatuses, orderStatusId]);
    }
    const filtersParams = filtersStore.orderFilters;
    const data = await OrdersService.getByParameters({
      orderStatusId: orderStatusId !== "all" ? orderStatusId : filtersParams.orderStatusId,
      paymentStatusId: filtersParams.paymentStatusId,
      orderTypeId: filtersParams.orderTypeId,
      paymentTypeId: filtersParams.paymentTypeId,
      shippingStatusId: filtersParams.shippingStatusId,
      orderedDateFrom: filtersParams.dateFrom,
      orderedDateTo: filtersParams.dateTo,
      orderBy: sortStore.ordersSortBy,
      page: 0,
      pageSize: 10,
    });
    if (orderStatusId !== "all") {
      const refreshedProducts = orders ? orders.filter((p) => p.orderStatusId !== orderStatusId) : [];
      refreshedProducts.push(...data.resultList);
      setOrders(refreshedProducts);
      setIsLoading(false);
      setActiveOrderStatusId(orderStatusId);
    } else {
      setAllOrders(data.resultList);
      setIsLoading(false);
      setAreAllLoaded(true);
    }

    const orderStatusesPagingParamsIndex = orderStatusesPagingParams.findIndex((f) => f.id === orderStatusId);
    if (orderStatusesPagingParamsIndex !== -1) {
      orderStatusesPagingParams[orderStatusesPagingParamsIndex].hasMore = data.hasMore;
      setOrderStatusesPagingParams(orderStatusesPagingParams);
    }
  };

  const onScroll = async (e, orderStatusId) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop > lastScrollTop) {
      document.body.classList.remove("scroll-up");
      document.body.classList.add("scroll-down");
    } else {
      document.body.classList.remove("scroll-down");
      document.body.classList.add("scroll-up");
    }
    setLastScrollTop(scrollTop);
    if (scrollHeight - (scrollTop + clientHeight) < 200) {
      const index = orderStatusesPagingParams.findIndex((f) => f.id === orderStatusId);
      if (index !== -1 && orderStatusesPagingParams[index].hasMore) {
        const newPage = orderStatusesPagingParams[index].page + 1;
        const showAll = orderStatusesPagingParams[index].id === "all";
        const filtersParams = filtersStore.orderFilters;
        const data = await OrdersService.getByParameters({
          orderStatusId: !showAll ? orderStatusesPagingParams[index].id : filtersParams.orderStatusId,
          paymentStatusId: filtersParams.paymentStatusId,
          orderTypeId: filtersParams.orderTypeId,
          paymentTypeId: filtersParams.paymentTypeId,
          shippingStatusId: filtersParams.shippingStatusId,
          orderedDateFrom: filtersParams.dateFrom,
          orderedDateTo: filtersParams.dateTo,
          orderBy: sortStore.ordersSortBy,
          searchParam: searchStore.searchTerm && searchStore.searchTerm.length > 0 ? searchStore.searchTerm.join(" ") : null,
          page: newPage,
          pageSize: orderStatusesPagingParams[index].pageSize,
        });

        orderStatusesPagingParams[index].hasMore = data.hasMore;
        setOrderStatusesPagingParams(orderStatusesPagingParams);

        if (data.resultList.length === 0) return;

        orderStatusesPagingParams[index].page = newPage;
        setOrderStatusesPagingParams(orderStatusesPagingParams);

        if (showAll) {
          setAllOrders([...allOrders, ...data.resultList]);
          setAreAllLoaded(true);
        } else {
          setOrders([...orders, ...data.resultList]);
          const loadedOrderStatusesIndex = loadedOrderStatuses ? loadedOrderStatuses.findIndex((ls) => ls === orderStatusId) : -1;
          if (loadedOrderStatusesIndex === -1) {
            setLoadedOrderStatuses((previousLoadedOrderStatuses) => [...previousLoadedOrderStatuses, orderStatusId]);
          }
        }
      }
    }
    setIsLoadingMore(false);
  };

  const onScrollCapture = () => {
    const index = orderStatusesPagingParams.findIndex((f) => f.id === activeOrderStatusId);
    setIsLoadingMore(index !== -1 && orderStatusesPagingParams[index].hasMore);
  };

  const getOrdersByOrderStatusId = (orderStatusId) => {
    var ordersByOrderStatus = orders ? orders.filter((p) => p.orderStatusId === orderStatusId) : [];
    return ordersByOrderStatus;
  };

  return (
    <React.Fragment>
      <input id="cursor" type="hidden" value=""></input>
      <div className="col d-xl-flex flex-column border-end overflow-hidden">
        <div className={`${!searchStore.searchTerm || searchStore.searchTerm.length === 0 ? "nav-sticky bg-white border-bottom" : "content"}`}>
        {searchStore.searchTerm && searchStore.searchTerm.length > 0 && (
            <div className="py-xl-3">
              <div className="pb-3">
                <div className="container-fluid mt-5 mt-md-1">
                  <h1 className="mb-3 fs-4">{!isLoading && orders && orders.length ? `Search results (${orders.length}):` : ""}</h1>
                  {isLoading && (
                    <div className="position-absolute top-50 start-50 translate-middle">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                        style={{
                          "--bs-spinner-border-width": "0.15em",
                        }}
                      ></div>
                    </div>
                  )}
                  {!isLoading && orders && orders.length === 0 && (
                    <div className="position-absolute top-50 start-50 translate-middle">
                      <h5 className="fw-normal text-muted mb-0">No orders to show.</h5>
                    </div>
                  )}
                   <div className="row g-0 g-lg-2">

                       {!isLoading &&
                    orders &&
                    orders.map((order, i) => (
                      <OrderItemComponent
                        key={i}
                        index={i}
                        order={order}
                        searchTerm={searchStore.searchTerm}
                        onSelectedItem={onSelectedItem}
                        selected={selectedItem && order.orderNumber === selectedItem.orderNumber}
                      ></OrderItemComponent>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="container pe-0">
               {(!searchStore.searchTerm || searchStore.searchTerm.length === 0) && (<ul className="nav nav-overflow mt-1">
              <li className="nav-item">
                <a
                  className={`nav-link p-0 me-1 ${activeOrderStatusId === "all" ? "active" : ""}`}
                  id="orders-all-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#orders-all-tab-pane"
                  role="tab"
                  aria-controls="orders-all-tab-pane"
                  aria-selected="true"
                  href="#"
                  onClick={() => loadOrders("all")}
                >
                  <span className="badge py-1 px-2 fs-8 text-body bg-white border border-nobel rounded-pill">All</span>
                </a>
              </li>
              {orderStatuses &&
                orderStatuses.map((orderStatus, i) => {
                  return (
                    <li key={i} className="nav-item">
                      <a
                        className={`nav-link p-0 me-1 ${activeOrderStatusId === orderStatus.orderStatusId ? "active" : ""}`}
                        id={`#orders-${orderStatus.name}-${orderStatus.orderStatusId}-tab`}
                        data-bs-toggle="tab"
                        data-bs-target={`#orders-${orderStatus.name}-${orderStatus.orderStatusId}-tab-pane`}
                        role="tab"
                        aria-controls={`#orders-${orderStatus.name}-${orderStatus.orderStatusId}-tab-pane`}
                        aria-selected="false"
                        href="#"
                        onClick={() => loadOrders(orderStatus.orderStatusId)}
                      >
                        <span className="badge py-1 px-2 fs-8 text-body bg-white border border-nobel rounded-pill">{orderStatus.name}</span>
                      </a>
                    </li>
                  );
                })}
            </ul>            )}

          </div>
        </div>
        <div className="tab-content tab-content-sticky" id="orders-tab-content" style={{ display: !searchStore.searchTerm || searchStore.searchTerm.length === 0 ? "block" : "none" }}>
          <div className={`tab-pane ${activeOrderStatusId === "all" ? "show active" : ""}`} id="orders-all-tab-pane" role="tabpanel" aria-labelledby="orders-all-tab" tabIndex="0">
            <div
              className="content py-xl-3"
              onScrollCapture={onScrollCapture}
              onScroll={debounce((e) => {
                onScroll(e, "all");
              }, 500)}
            >
              <div className="container-fluid px-2 px-md-4 mt-3 mt-md-1">
                {isLoading && (
                  <div className="position-absolute top-50 start-50 translate-middle">
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      style={{
                        "--bs-spinner-border-width": "0.15em",
                      }}
                    ></div>
                  </div>
                )}

                {!isLoading && allOrders.length === 0 && (
                  <div className="position-absolute top-50 start-50 translate-middle">
                    <h5 className="fw-normal text-muted mb-0">No orders to show.</h5>
                  </div>
                )}

                <div className="row g-0 g-lg-2">
                  {!isLoading &&
                    allOrders &&
                    allOrders.map((order, i) => (
                      <OrderItemComponent
                        key={i}
                        index={i}
                        order={order}
                        searchTerm={searchStore.searchTerm}
                        onSelectedItem={onSelectedItem}
                        selected={selectedItem && order.orderNumber === selectedItem.orderNumber}
                      ></OrderItemComponent>
                    ))}
                </div>
                {isLoadingMore && (
                  <div className="text-center pt-4 pb-4">
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      style={{
                        "--bs-spinner-border-width": "0.15em",
                      }}
                    ></div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {orderStatuses &&
            orderStatuses.map((orderStatus, i) => (
              <div
                key={i}
                className={`tab-pane ${activeOrderStatusId === orderStatus.orderStatusId ? "show active" : ""}`}
                id={`#orders-${orderStatus.name}-${orderStatus.orderStatusId}-tab-pane`}
                role="tabpanel"
                aria-labelledby={`#orders-${orderStatus.name}-${orderStatus.orderStatusId}-tab`}
                tabIndex={i + 1}
              >
                <div
                  className="content py-xl-3"
                  onScrollCapture={onScrollCapture}
                  onScroll={debounce((e) => {
                    onScroll(e, orderStatus.orderStatusId);
                  }, 500)}
                >
                  <div className="container-fluid">
                    {isLoading && (
                      <div className="position-absolute top-50 start-50 translate-middle">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                          style={{
                            "--bs-spinner-border-width": "0.15em",
                          }}
                        ></div>
                      </div>
                    )}
                    {!isLoading && getOrdersByOrderStatusId(orderStatus.orderStatusId).length === 0 && (
                      <div className="position-absolute top-50 start-50 translate-middle">
                        <h5 className="fw-normal text-muted mb-0">No orders to show.</h5>
                      </div>
                    )}
                    <div className="row g-0 g-lg-2">
                      {!isLoading &&
                        getOrdersByOrderStatusId(orderStatus.orderStatusId).map((order, i) => (
                          <OrderItemComponent
                            key={i}
                            index={i}
                            order={order}
                            searchTerm={searchStore.searchTerm}
                            onSelectedItem={onSelectedItem}
                            selected={selectedItem && order.orderNumber === selectedItem.orderNumber}
                          ></OrderItemComponent>
                        ))}
                    </div>
                    {isLoadingMore && (
                      <div className="text-center pt-4 pb-4">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                          style={{
                            "--bs-spinner-border-width": "0.15em",
                          }}
                        ></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="col d-flex overflow-hidden flyout" id="flyout">
        <div className="content">
          {selectedItem && <OrderDetailComponent key={selectedItem.id} id={selectedItem.id}></OrderDetailComponent>}
          {!selectedItem && (
            <div className="d-flex align-items-center text-center w-100 h-100">
              <div className="container-fluid">
                <h5 className="mb-1 text-muted">Select a order you want to preview.</h5>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
});

export default OrdersView;
