import React, { useEffect, useState } from "react";

import { observer } from "mobx-react";

import { DocumentDetailComponent } from "./components/DocumentDetailComponent";
import { DocumentItemComponent } from "./components/DocumentItemComponent";

import showFlyoutCard from "../../utils/flyout";
import { useStore } from "../../stores/StoreContext";

const DocumentsView = observer(() => {
  const { ordersStore, searchStore, filtersStore, sortStore } = useStore();
  const [documents, setDocuments] = useState();
  const [selectedItem, setSelectedItem] = useState();

  const initArrowKeys = (e) => {
    e = e || window.event;
    var cursorInput = document.getElementById("cursor");
    var currentIndex = cursorInput && cursorInput.value && parseInt(cursorInput.value);
    var selectedEl = document.getElementsByClassName("selected");
    if (!selectedEl || selectedEl.length === 0) return;

    if (e.keyCode === 38 || e.keyCode === 40) {
      if (!currentIndex) {
        currentIndex = parseInt(selectedEl[0].dataset.index);
      }

      var newIndex = currentIndex + (e.keyCode === 38 ? -1 : 1);

      var currentElement = document.getElementById("card-" + currentIndex);
      var newElement = document.getElementById("card-" + newIndex);
      if (!newElement) return;

      currentElement.classList.remove("selected");
      newElement.classList.add("selected");
      cursorInput.value = newIndex;
    } else if (e.keyCode === 39) {
      currentElement = document.getElementById("show-details-btn-" + currentIndex);
      if (currentElement) {
        currentElement.click();
      }
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", initArrowKeys);
    return () => {
      document.removeEventListener("keydown", initArrowKeys);
    };
  }, []);

  useEffect(() => {
    searchStore.clearSearch();
  }, [searchStore]);

  useEffect(() => {
    const fetchData = () => {
      setDocuments(() => ordersStore.getDocumentsByParameters(searchStore.searchTerm, filtersStore.filters, sortStore.documentsSortBy));
    };
    fetchData();
  }, [searchStore.searchTerm, ordersStore, ordersStore.documents.length, filtersStore.filters, filtersStore.changed, sortStore.documentsSortBy]);

  const onSelectedItem = (documentNumber) => {
    const index = documents && documents.findIndex((p) => p.documentNumber === documentNumber);
    if (index !== -1) {
      var selectedEl = document.getElementsByClassName("selected");
      if (selectedEl && selectedEl.length > 0 && parseInt(selectedEl[0].dataset.index) !== index) {
        selectedEl[0].classList.remove("selected");
      }
      setSelectedItem(documents[index]);
      showFlyoutCard();
      ordersStore.markDocumenAsSeen(documentNumber);
    }
  };

  return (
    <React.Fragment>
      <input id="cursor" type="hidden" value=""></input>
      <div className="col d-xl-flex border-end overflow-hidden">
        <div className="content">
          <div className="py-xl-3">
            <div className="pb-3">
              {documents && (
                <div className="container-fluid">
                  <div className="row align-items-center mb-3">
                    <div className="col">
                      <h4 className="mb-0">Your documents ({documents?.length ?? 0})</h4>
                    </div>
                    <div className="col-auto">
                      <a className="btn btn-white" href="#!" title="Download NDSS W-9" data-bs-toggle="tooltip">
                        <span className="fe fe-download me-2"></span>
                        <span>NDSS W-9</span>
                      </a>
                    </div>
                  </div>
                </div>
              )}

              <div className="container-fluid">
                <div className="row g-1 g-lg-2">
                  {!documents ||
                    (documents.length === 0 && (
                      <div className="d-flex align-items-center  w-100 h-100">
                        <h5 className="fw-normal text-muted mb-0">No documents to show.</h5>
                      </div>
                    ))}
                  {documents &&
                    documents.map(function (document, index) {
                      return (
                        <DocumentItemComponent
                          key={index}
                          index={index}
                          document={document}
                          onSelectedItem={onSelectedItem}
                          selected={selectedItem && document.documentNumber === selectedItem.documentNumber}
                        ></DocumentItemComponent>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col d-flex overflow-hidden flyout" id="flyout">
        {selectedItem && <DocumentDetailComponent key={selectedItem.id} document={selectedItem}></DocumentDetailComponent>}
        {!selectedItem && documents && (
          <div className="d-flex align-items-center text-center w-100 h-100">
            <div className="container-fluid">
              <h5 className="mb-1 text-muted">Select a document you want to preview.</h5>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
});

export default DocumentsView;
